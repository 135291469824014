import api from '@/utils/api';

export const CompanyAuditLogService = {
  getAuditLog(companyId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/log`,
      ignoreCache,
    });
  },
};
