<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :disabled="disabled"
    rounded="lg"
    offset-y
    offset-overflow
    nudge-bottom="2px"
    min-width="80%"
    max-width="90%"
    eager
  >
    <template #activator="{ on, attrs }">
      <div v-on="on">
        <v-text-field
          ref="textFieldRef"
          v-model="inputValue"
          :disabled="disabled"
          :label="label"
          :error-messages="errorMessages"
          :persistent-hint="!!attrs.hint"
          prepend-inner-icon="mdi-calendar"
          v-bind="{ ...attrs, ...$attrs }"
          class="period-text-input"
          data-cy="period-picker"
          @focus="focused = true"
          @blur="focused = false"
          @keyup.enter="onSubmit"
          @click:clear="onClear"
        >
          <template v-if="$slots['prepend-inner']" #prepend-inner>
            <slot name="prepend-inner" />
          </template>
          <template #append>
            <v-btn
              :disabled="isPrevNextDisabled"
              tabindex="-1"
              width="32px"
              height="32px"
              icon
              @click.stop="prev"
              @mousedown.stop=""
            >
              <v-icon>mdi-arrow-left-thin</v-icon>
            </v-btn>
            <v-btn
              :disabled="isPrevNextDisabled"
              tabindex="-1"
              width="32px"
              height="32px"
              icon
              @click.stop="next"
              @mousedown.stop=""
            >
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </template>

    <v-card data-cy="period-picker-menu">
      <v-card-text>
        <div class="d-flex text-no-wrap">
          <ul class="labels">
            <li v-if="_rows.years">År</li>
            <li v-if="_rows.periods">Termin</li>
            <li v-if="_rows.months">Måned</li>
            <li v-if="_rows.weeksDouble">14 dager</li>
            <li v-if="_rows.weeksSingle">Uke</li>
          </ul>
          <div ref="grids" class="grids-container overflow-x-auto d-flex align-start pb-1">
            <div v-for="grid in grids" :key="grid.year" :data-year="grid.year" class="grid">
              <div
                v-for="row in grid.rows"
                :key="`${grid.year}-${row.type}`"
                class="grid-row"
                :class="`grid-row--${row.type}`"
                :data-cy="`row-${row.type}`"
              >
                <button
                  v-for="(item, itemIndex) in row.items"
                  :key="`${grid.year}-${row.type}-${item}`"
                  class="grid-item"
                  :class="{ 'grid-item--active': selectedGridItem === `${grid.year}-${row.type}-${itemIndex}` }"
                  :disabled="_disabledRows[row.type]"
                  @click="onGridItemClick(grid.year, row.type, itemIndex)"
                  v-text="item"
                />
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-text v-if="!hideDatePickers" class="d-flex overflow-x-auto pt-0">
        <div class="flex-shrink-0" style="width: 80px" />

        <div class="flex-shrink-1 flex-grow-0 d-flex align-center">
          <table class="shortcuts text-no-wrap">
            <tbody>
              <tr v-for="shortcut in shortcuts" :key="shortcut.title" @click="onShortcutClick(shortcut.type)">
                <td v-text="shortcut.title" />
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-flex justify-center flex-grow-1 px-5">
          <div class="mr-5 d-flex flex-column">
            Fra dato:
            <VExtDatePicker v-model="pickerDateFrom" no-title @input="onDateFromInput" />
          </div>
          <div class="d-flex flex-column">
            Til dato:
            <VExtDatePicker v-model="pickerDateTo" no-title @input="onDateToInput" />
          </div>
        </div>

        <div class="d-flex align-end justify-end flex-grow-0 flex-shrink-1">
          <v-btn color="primary" @click="submitDatePickerValues">OK</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import animateScrollTo from 'animated-scroll-to';
import {
  addDays,
  addWeeks,
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  parseISO,
  subDays,
  subWeeks,
} from 'date-fns';
import { debounce } from 'lodash-es';
import VExtDatePicker from '@/components/vuetify-ext/VExtDatePicker.vue';
import { getMonthNames } from '@/utils/datesHelper';
import {
  getIntervalMonth,
  getIntervalPeriod,
  getIntervalSingleWeek,
  getIntervalThisMonth,
  getIntervalThisPeriod,
  getIntervalThisWeek,
  getIntervalThisYear,
  getIntervalToday,
  getIntervalWeeksDouble,
  getIntervalYear,
  getPeriodIndex,
  getWeekIndex,
  isDoubleWeekInterval,
  isMonthInterval,
  isPeriodInterval,
  isSingleWeekInterval,
  isYearInterval,
} from './utils/intervals';
import { isValidDateInterval, isValidIntervalString, isValidIsoDate } from './utils/validators';
import type { DateInterval } from './types';
import {
  parseDateIntervalAlias,
  parseMonthAlias,
  parsePeriodAlias,
  parseSingleWeekAlias,
  parseWeeksDoubleAlias,
  parseYearAlias,
} from './utils/alias-parsers';
import {
  isYearAlias,
  isMonthAlias,
  isWeeksDoubleAlias,
  isSingleWeekAlias,
  isPeriodAlias,
  isDateIntervalAlias,
} from './utils/alias-regexp';
import { intervalToString } from './utils/formatters';

// TODO: handle ISO week 53
// Iliyan: "2020 has 53 weeks, does that mean we would add another extra column to the “double weeks” row? so 49-50, 51-52, 53"
// Robin: "I think we stick to 52 weeks for now. I see next time is 2026"

// How many years to show initially before and after the current year
const YEARS_BEFORE_AFTER = 4;
// How many years to load when the user scrolls to the end of the grid
const YEARS_TO_LOAD = 5;

enum Event {
  UPDATE_FROM = 'update:dateFrom',
  UPDATE_TO = 'update:dateTo',
  // helper event emitted when a new interval is selected in `selectInterval`
  INPUT = 'input',
  CHANGE = 'change',
  CLEAR = 'clear',
}
enum ErrorText {
  INVALID_FORMAT = 'Ugyldig dato (DDMMÅÅ)',
  INVALID_INTERVAL = 'Dato fra kan ikke være før dato til',
}

enum Row {
  YEARS = 'years',
  PERIODS = 'periods',
  MONTHS = 'months',
  WEEKS_DOUBLE = 'weeksDouble',
  WEEKS_SINGLE = 'weeksSingle',
}
enum Shortcut {
  TODAY = 'today',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  THIS_PERIOD = 'thisPeriod',
  THIS_YEAR = 'thisYear',
}

export type Rows = Record<Row, boolean>;
export type RowsConfig = {
  rows?: Partial<Rows>;
  disabledRows?: Partial<Rows>;
};
type Grid = {
  year: number;
  rows: Array<{
    type: Row;
    items: Array<string | number>;
  }>;
};

const PERIODS = Array.from({ length: 6 }, (_, i) => `Termin ${i + 1}`);
const MONTHS = getMonthNames();
const WEEKS_DOUBLE = Array.from({ length: 26 }, (_, i) => `${i * 2 + 1}-${i * 2 + 2}`);
const WEEKS_SINGLE = Array.from({ length: 52 }, (_, i) => `${i + 1}`);

const defaultRows: Rows = Object.freeze({
  years: true,
  periods: true,
  months: true,
  weeksDouble: true,
  weeksSingle: true,
});
const defaultDisabledRows: Rows = Object.freeze({
  years: false,
  periods: false,
  months: false,
  weeksDouble: false,
  weeksSingle: false,
});

export default defineComponent({
  components: {
    VExtDatePicker,
  },
  props: {
    /**
     * A valid ISO date string in the format YYYY-MM-DD or null
     */
    dateFrom: {
      type: String as PropType<string | null>,
      default: null,
      validator: (value: string | null) => {
        return value === null || isValidIsoDate(value);
      },
    },
    /**
     * A valid ISO date string in the format YYYY-MM-DD or null
     */
    dateTo: {
      type: String as PropType<string | null>,
      default: null,
      validator: (value: string | null) => {
        return value === null || isValidIsoDate(value);
      },
    },
    rows: {
      type: Object as PropType<Partial<Rows>>,
      default: (): Rows => defaultRows,
    },
    disabledRows: {
      type: Object as PropType<Partial<Rows>>,
      default: (): Rows => defaultDisabledRows,
    },
    hideDatePickers: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Periode',
    },
  },
  emits: Object.values(Event),
  data: (vm) => ({
    inputValue: '',
    years: [] as number[],
    menu: false,
    focused: false,
    errorMessages: '',
    // we have to keep track of the picker values separately so that we can update the main props only when the user clicks OK
    pickerDateFrom: vm.dateFrom,
    pickerDateTo: vm.dateTo,
  }),
  computed: {
    dateFromInternal: {
      get() {
        return this.dateFrom;
      },
      set(date: string) {
        this.pickerDateFrom = date;
        this.$emit(Event.UPDATE_FROM, date);
      },
    },
    dateToInternal: {
      get() {
        return this.dateTo;
      },
      set(date: string) {
        this.pickerDateTo = date;
        this.$emit(Event.UPDATE_TO, date);
      },
    },
    selectedInterval(): DateInterval | null {
      if (!this.dateFrom || !this.dateTo) return null;

      return {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
    selectedGridItem() {
      if (!this.dateFromInternal || !this.dateToInternal || !this.selectedInterval) return null;

      const makeGridItemId = (year: Grid['year'], rowType: Row, itemIndex: number) => {
        return `${year}-${rowType}-${itemIndex}`;
      };

      const dateFrom = parseISO(this.dateFromInternal);
      const year = dateFrom.getFullYear();

      if (isYearInterval(this.selectedInterval)) {
        return makeGridItemId(year, Row.YEARS, 0);
      } else if (isPeriodInterval(this.selectedInterval)) {
        return makeGridItemId(year, Row.PERIODS, getPeriodIndex(dateFrom));
      } else if (isMonthInterval(this.selectedInterval)) {
        return makeGridItemId(year, Row.MONTHS, dateFrom.getMonth());
      } else if (isDoubleWeekInterval(this.selectedInterval)) {
        return makeGridItemId(year, Row.WEEKS_DOUBLE, getWeekIndex(dateFrom) / 2);
      } else if (isSingleWeekInterval(this.selectedInterval)) {
        return makeGridItemId(year, Row.WEEKS_SINGLE, getWeekIndex(dateFrom));
      } else {
        return null;
      }
    },
    textFieldRef(): any {
      return this.$refs.textFieldRef;
    },
    gridsRef() {
      return this.$refs.grids as HTMLDivElement;
    },
    isEditing() {
      return this.menu || this.focused;
    },
    grids(): Grid[] {
      const grids: Grid[] = [];

      for (let yearIndex = 0; yearIndex < this.years.length; yearIndex++) {
        const year = this.years[yearIndex];
        const grid: Grid = {
          year,
          rows: [],
        };

        if (this._rows.years) {
          grid.rows.push({
            type: Row.YEARS,
            items: [year],
          });
        }

        if (this._rows.periods) {
          grid.rows.push({
            type: Row.PERIODS,
            items: PERIODS,
          });
        }

        if (this._rows.months) {
          grid.rows.push({
            type: Row.MONTHS,
            items: MONTHS,
          });
        }

        if (this._rows.weeksDouble) {
          grid.rows.push({
            type: Row.WEEKS_DOUBLE,
            items: WEEKS_DOUBLE,
          });
        }

        if (this._rows.weeksSingle) {
          grid.rows.push({
            type: Row.WEEKS_SINGLE,
            items: WEEKS_SINGLE,
          });
        }

        grids.push(grid);
      }

      return grids;
    },
    shortcuts(): Array<{ title: string; type: Shortcut }> {
      return [
        {
          title: 'I dag',
          type: Shortcut.TODAY,
        },
        {
          title: 'Hittil denne uken',
          type: Shortcut.THIS_WEEK,
        },
        {
          title: 'Hittil denne måneden',
          type: Shortcut.THIS_MONTH,
        },
        {
          title: 'Hittil dette terminet',
          type: Shortcut.THIS_PERIOD,
        },
        {
          title: 'Hittil dette året',
          type: Shortcut.THIS_YEAR,
        },
      ];
    },
    activeYear() {
      return this.dateFromInternal ? parseISO(this.dateFromInternal).getFullYear() : new Date().getFullYear();
    },
    isPrevNextDisabled() {
      return (
        this.disabled ||
        !this.inputValue ||
        !this.dateFromInternal ||
        !this.dateToInternal ||
        !isValidIntervalString(this.inputValue) ||
        !isValidDateInterval(this.selectedInterval)
      );
    },
    _rows() {
      return {
        ...defaultRows,
        ...this.rows,
      };
    },
    _disabledRows() {
      return {
        ...defaultDisabledRows,
        ...this.disabledRows,
      };
    },
  },
  watch: {
    selectedInterval: {
      handler(interval: DateInterval | null) {
        this.inputValue = intervalToString(interval);

        if (interval?.dateFrom && interval?.dateTo && !isValidDateInterval(interval)) {
          this.errorMessages = ErrorText.INVALID_INTERVAL;
        }
      },
      immediate: true,
      deep: true,
    },
    menu(isOpen: boolean) {
      if (isOpen) {
        this.genInitialYears();
        this.pickerDateFrom = this.dateFromInternal;
        this.pickerDateTo = this.dateToInternal;
        this.$nextTick(() => {
          this.scrollGridToSelectedItem();
        });
      }
    },
    inputValue() {
      this.errorMessages = '';
    },
    isEditing(isEditing: boolean) {
      if (!isEditing) {
        this.onSubmit();
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.onTabbedIn);
    this.gridsRef.addEventListener('scroll', this.onGridScroll);
  },
  destroyed() {
    window.removeEventListener('keyup', this.onTabbedIn);
    this.gridsRef.removeEventListener('scroll', this.onGridScroll);
  },
  methods: {
    prev() {
      if (!this.selectedInterval) return;

      const date = parseISO(this.selectedInterval.dateFrom);

      switch (true) {
        case isYearInterval(this.selectedInterval): {
          const targetYear = date.getFullYear() - 1;
          this.selectInterval(getIntervalYear(targetYear));
          break;
        }
        case isPeriodInterval(this.selectedInterval): {
          const year = date.getFullYear();
          const period = getPeriodIndex(date);

          const targetYear = period === 0 ? year - 1 : year;
          const targetPeriodIndex = period === 0 ? 5 : period - 1;

          this.selectInterval(getIntervalPeriod(targetYear, targetPeriodIndex));
          break;
        }
        case isMonthInterval(this.selectedInterval): {
          const year = date.getFullYear();
          const month = date.getMonth();

          const targetYear = month === 0 ? year - 1 : year;
          const targetMonth = month === 0 ? 11 : month - 1;

          this.selectInterval(getIntervalMonth(targetYear, targetMonth));
          break;
        }
        // TODO: should we handle case double weeks?
        case isSingleWeekInterval(this.selectedInterval): {
          const prevWeek = subWeeks(date, 1);
          const targetYear = prevWeek.getFullYear();
          const targetWeekIndex = getWeekIndex(prevWeek);

          this.selectInterval(getIntervalSingleWeek(targetYear, targetWeekIndex));
          break;
        }
        default: {
          const dateFrom = date;
          const dateTo = parseISO(this.selectedInterval.dateTo);
          const daysDiff = differenceInCalendarDays(dateTo, dateFrom);

          const targetDateFrom = subDays(dateFrom, daysDiff + 1);
          const targetDateTo = subDays(dateTo, daysDiff + 1);

          this.selectInterval({
            dateFrom: format(targetDateFrom, 'yyyy-MM-dd'),
            dateTo: format(targetDateTo, 'yyyy-MM-dd'),
          });
        }
      }
    },
    next() {
      if (!this.selectedInterval) return;

      const date = parseISO(this.selectedInterval.dateFrom);

      switch (true) {
        case isYearInterval(this.selectedInterval): {
          const targetYear = date.getFullYear() + 1;
          this.selectInterval(getIntervalYear(targetYear));
          break;
        }
        case isPeriodInterval(this.selectedInterval): {
          const year = date.getFullYear();
          const period = getPeriodIndex(date);

          const targetYear = period === 5 ? year + 1 : year;
          const targetPeriodIndex = period === 5 ? 0 : period + 1;

          this.selectInterval(getIntervalPeriod(targetYear, targetPeriodIndex));
          break;
        }
        case isMonthInterval(this.selectedInterval): {
          const year = date.getFullYear();
          const month = date.getMonth();

          const targetYear = month === 11 ? year + 1 : year;
          const targetMonth = month === 11 ? 0 : month + 1;

          this.selectInterval(getIntervalMonth(targetYear, targetMonth));
          break;
        }
        // TODO: should we handle case double weeks?
        case isSingleWeekInterval(this.selectedInterval): {
          const nextWeek = addWeeks(date, 1);
          const targetYear = nextWeek.getFullYear();
          const targetWeekIndex = getWeekIndex(nextWeek);

          this.selectInterval(getIntervalSingleWeek(targetYear, targetWeekIndex));
          break;
        }
        default: {
          const dateFrom = date;
          const dateTo = parseISO(this.selectedInterval.dateTo);
          const daysDiff = differenceInCalendarDays(dateTo, dateFrom);

          const targetDateFrom = addDays(dateFrom, daysDiff + 1);
          const targetDateTo = addDays(dateTo, daysDiff + 1);

          this.selectInterval({
            dateFrom: format(targetDateFrom, 'yyyy-MM-dd'),
            dateTo: format(targetDateTo, 'yyyy-MM-dd'),
          });
        }
      }
    },
    // if From > To, set To to equal From
    onDateFromInput() {
      if (!this.pickerDateFrom || !this.pickerDateTo) return;

      const dateFrom = parseISO(this.pickerDateFrom);
      const dateTo = parseISO(this.pickerDateTo);

      if (isAfter(dateFrom, dateTo)) {
        this.pickerDateTo = this.pickerDateFrom;
      }
    },
    // if To > From, set From to equal To
    onDateToInput() {
      if (!this.pickerDateFrom || !this.pickerDateTo) return;

      const dateFrom = parseISO(this.pickerDateFrom);
      const dateTo = parseISO(this.pickerDateTo);

      if (isBefore(dateTo, dateFrom)) {
        this.pickerDateFrom = this.pickerDateTo;
      }
    },

    selectInterval(interval: DateInterval) {
      const hasChanged = interval.dateFrom !== this.dateFromInternal || interval.dateTo !== this.dateToInternal;
      this.dateFromInternal = interval.dateFrom;
      this.dateToInternal = interval.dateTo;
      this.$emit(Event.INPUT, interval);
      hasChanged && this.$emit(Event.CHANGE, interval);
      this.menu = false;
      this.textFieldRef.focus();
    },

    onGridItemClick(year: Grid['year'], rowType: Row, itemIndex: number) {
      let interval!: DateInterval;

      switch (rowType) {
        case Row.YEARS:
          interval = getIntervalYear(year);
          break;
        case Row.PERIODS:
          interval = getIntervalPeriod(year, itemIndex);
          break;
        case Row.MONTHS:
          interval = getIntervalMonth(year, itemIndex);
          break;
        case Row.WEEKS_DOUBLE:
          interval = getIntervalWeeksDouble(year, itemIndex);
          break;
        case Row.WEEKS_SINGLE:
          interval = getIntervalSingleWeek(year, itemIndex);
          break;
      }

      this.selectInterval(interval);
    },

    onShortcutClick(shortcut: Shortcut) {
      let interval!: DateInterval;

      switch (shortcut) {
        case Shortcut.TODAY:
          interval = getIntervalToday();
          break;
        case Shortcut.THIS_WEEK:
          interval = getIntervalThisWeek();
          break;
        case Shortcut.THIS_MONTH:
          interval = getIntervalThisMonth();
          break;
        case Shortcut.THIS_PERIOD:
          interval = getIntervalThisPeriod();
          break;
        case Shortcut.THIS_YEAR:
          interval = getIntervalThisYear();
          break;
      }

      this.selectInterval(interval);
    },

    /**
     * Called when
     * - user presses enter in text-field
     * - user tabs away from text-field
     * - menu closes
     * - text-field loses focus
     */
    onSubmit() {
      this.menu = false;

      if (!this.inputValue) {
        this.dateFromInternal = null;
        this.dateToInternal = null;
        return;
      }

      let interval: DateInterval;

      if (isYearAlias(this.inputValue)) {
        interval = parseYearAlias(this.inputValue);
      } else if (isMonthAlias(this.inputValue)) {
        interval = parseMonthAlias(this.inputValue);
      } else if (isWeeksDoubleAlias(this.inputValue)) {
        interval = parseWeeksDoubleAlias(this.inputValue);
      } else if (isSingleWeekAlias(this.inputValue)) {
        interval = parseSingleWeekAlias(this.inputValue);
      } else if (isPeriodAlias(this.inputValue)) {
        interval = parsePeriodAlias(this.inputValue);
      } else if (isDateIntervalAlias(this.inputValue)) {
        interval = parseDateIntervalAlias(this.inputValue);
      } else {
        this.errorMessages = ErrorText.INVALID_FORMAT;
        return;
      }

      if (!isValidDateInterval(interval)) {
        this.errorMessages = ErrorText.INVALID_INTERVAL;
        return;
      }

      const hasChanged = interval.dateFrom !== this.dateFromInternal || interval.dateTo !== this.dateToInternal;
      this.dateFromInternal = interval.dateFrom;
      this.dateToInternal = interval.dateTo;
      this.inputValue = intervalToString(interval);
      this.$emit(Event.INPUT, interval);
      hasChanged && this.$emit(Event.CHANGE, interval);
      this.errorMessages = '';
    },
    onClear() {
      this.inputValue = '';
      this.$emit(Event.CLEAR);
    },
    submitDatePickerValues() {
      this.selectInterval({
        dateFrom: this.pickerDateFrom!,
        dateTo: this.pickerDateTo!,
      });
    },
    onTabbedIn(e: KeyboardEvent) {
      const key = e.key;

      if (['Tab', 'Enter'].includes(key)) {
        if (document.activeElement === this.textFieldRef.$refs.input) {
          this.menu = true;
          this.focused = true;
        } else {
          if (this.focused) {
            this.onSubmit();
          }
          this.menu = false;
          this.focused = false;
        }
      }
    },
    genInitialYears() {
      const years = [];

      for (let i = -YEARS_BEFORE_AFTER; i <= YEARS_BEFORE_AFTER; i++) {
        years.push(this.activeYear + i);
      }

      this.years = years;
    },
    scrollGridToSelectedItem() {
      const containerEl = this.gridsRef;
      const targetEl: HTMLElement | null = this.gridsRef?.querySelector(
        this.selectedGridItem ? '.grid-item--active' : `[data-year="${this.activeYear}"]`,
      );

      if (containerEl && targetEl) {
        setTimeout(() => {
          const gridWidth = containerEl.offsetWidth;
          const itemWidth = targetEl?.offsetWidth || 0;
          // offset needed to center the item inside the grid
          // if item is wider than grid (e.g. when year is selected), offset is 0
          const offset = Math.max(gridWidth / 2 - itemWidth / 2, 0);

          animateScrollTo(targetEl, {
            elementToScroll: containerEl,
            maxDuration: 200,
            horizontalOffset: -offset,
          });
        }, 100);
      }
    },
    onGridScroll: debounce(function (this: any) {
      const containerEl = this.gridsRef;

      if (containerEl.scrollLeft === 0) {
        this.onGridScrolledToStart();
      } else if (containerEl.scrollLeft + containerEl.clientWidth === containerEl.scrollWidth) {
        this.onGridScrolledToEnd();
      }
    }, 200),
    onGridScrolledToStart() {
      const minYear = Math.min(...this.years);
      const minYearEl = this.gridsRef.querySelector(`[data-year="${minYear}"]`) as HTMLElement;

      const yearsToPrepend = [];

      for (let i = 1; i <= YEARS_TO_LOAD; i++) {
        yearsToPrepend.push(minYear - i);
      }

      this.years.unshift(...yearsToPrepend.reverse());

      // browsers automatically scroll to the start of the focused container
      // in our case we want to keep the scroll position
      this.$nextTick(() => {
        animateScrollTo(minYearEl, {
          elementToScroll: this.gridsRef,
          maxDuration: 0,
        });
      });
    },
    onGridScrolledToEnd() {
      const maxYear = Math.max(...this.years);

      const yearsToAppend = [];

      for (let i = 1; i <= YEARS_TO_LOAD; i++) {
        yearsToAppend.push(maxYear + i);
      }

      this.years.push(...yearsToAppend);
    },
  },
});
</script>

<style lang="scss" scoped>
$border: thin solid rgba(0, 0, 0, 0.5);
$item-height: 32px;

.period-text-input::v-deep {
  .v-input__prepend-inner {
    align-self: center;
    margin-top: 0;
    cursor: pointer;
  }
  .v-text-field__slot {
    input {
      cursor: text;
    }
  }
  .v-input__append-inner {
    align-self: center;
    margin-top: 0;
  }
}

.labels {
  list-style-type: none;
  padding: 0;
  width: 80px;
  flex-shrink: 0;
  font-weight: 500;
  color: #000;
  li {
    line-height: $item-height;
    padding: 0 5px;
    margin: 1px 0;
  }
}

.grids-container {
  overflow-y: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    height: 16px;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  color: #000;
  text-align: center;
  border: $border;
  font-weight: 500;
  user-select: none;
  flex: 1;

  &-row {
    display: grid;
    grid-auto-flow: column;

    &:not(:last-child) {
      border-bottom: $border;
    }

    &--years {
      grid-template-columns: repeat(1, 1fr);
    }
    &--periods {
      grid-template-columns: repeat(6, 1fr);
    }
    &--months {
      grid-template-columns: repeat(12, 1fr);
    }
    &--weeksDouble {
      grid-template-columns: repeat(26, 44px);
    }
    &--weeksSingle {
      grid-template-columns: repeat(52, 22px);
    }
  }

  &-item {
    padding: 5px 0;
    height: $item-height;
    cursor: pointer;
    transition: background-color 50ms ease-in-out;
    font-size: 12px;

    &:not(:last-child) {
      border-right: $border;
    }

    &--active {
      background-color: var(--v-accent-base);
      color: #fff;
    }
    &:not(.grid-item--active):hover {
      background-color: rgba(#000, 0.08);
    }
  }
}

.shortcuts {
  text-align: center;
  border-collapse: collapse;
  user-select: none;
  tr {
    cursor: pointer;
    &:hover {
      background-color: var(--v-primary-lighten5);
    }
  }
  td {
    padding: 5px;
    border: $border;
    color: #000;
    font-weight: 500;
  }
}
.grid-item[disabled] {
  cursor: not-allowed;
  background-color: rgba(#000, 0.08);
}
</style>
