import api from '@/utils/api';
import type {
  AccountantsUpdateBody,
  AccountingFirmAccountantEditBody,
  AccountingFirmAccountantInviteBody,
  AccountingFirmCustomerInviteBody,
  AccountingFirmCustomerResponse,
  AccountingFirmEmployeeResponse,
  AccountingFirmResponse,
} from '@/types/schema';

export type EmployeeInvitationsNonUniqueEmails = {
  otherCompanyAssociatedEmails: string[];
  sameCompanyAssociatedEmails: string[];
};

export const AccountingFirmService = {
  getAccountingFirm(firmId: string): Promise<AccountingFirmResponse> {
    return api({
      method: 'get',
      url: `/accounting_firms/${firmId}`,
    });
  },
  resendInvitation(firmId: string, invitationId: string): Promise<void> {
    return api({
      method: 'post',
      url: `/accounting_firms/${firmId}/invite_resend/${invitationId}`,
    });
  },

  // Customers

  getCustomers(firmId: string): Promise<AccountingFirmCustomerResponse[]> {
    return api({
      method: 'get',
      url: `/accounting_firms/${firmId}/customers`,
    });
  },
  getCustomer(firmId: string, customerId: string): Promise<AccountingFirmCustomerResponse> {
    return api({
      method: 'get',
      url: `/accounting_firms/${firmId}/customers/${customerId}`,
    });
  },
  inviteCustomers(firmId: string, data: AccountingFirmCustomerInviteBody): Promise<void> {
    return api({
      method: 'post',
      url: `/accounting_firms/${firmId}/invite_customers`,
      data,
    });
  },
  updateCustomer(firmId: string, customerId: string, data: AccountantsUpdateBody): Promise<void> {
    return api({
      method: 'patch',
      url: `/accounting_firms/${firmId}/customers/${customerId}`,
      data,
    });
  },

  // Employees

  getEmployees(firmId: string): Promise<AccountingFirmEmployeeResponse[]> {
    return api({
      method: 'get',
      url: `/accounting_firms/${firmId}/employees`,
    });
  },
  getEmployee(firmId: string, employeeId: string): Promise<AccountingFirmEmployeeResponse> {
    return api({
      method: 'get',
      url: `/accounting_firms/${firmId}/employees/${employeeId}`,
    });
  },
  inviteEmployees(
    firmId: string,
    data: AccountingFirmAccountantInviteBody,
  ): Promise<void | EmployeeInvitationsNonUniqueEmails> {
    return api({
      method: 'post',
      url: `/accounting_firms/${firmId}/invite_employees`,
      data,
    });
  },
  updateEmployee(firmId: string, employeeId: string, data: AccountingFirmAccountantEditBody): Promise<void> {
    return api({
      method: 'put',
      url: `/accounting_firms/${firmId}/employees/${employeeId}`,
      data,
    });
  },
  removeEmployee(firmId: string, employeeId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `/accounting_firms/${firmId}/employees/${employeeId}`,
    });
  },
};
