import api from '@/utils/api';
import type {
  GetVatOverviewSpecificationQuery,
  GetVatOverviewTransactionsQuery,
  PaginatedVatOverviewTransactionsResponse,
  PaginationQueryRequest,
  VatDetailsReportResponse,
  VatOverviewSpecificationResponse,
  VatReportResponse,
  VatReportSaveBody,
} from '@/types/schema';

export type SummaryLine = {
  amount: number;
  baseAmount: number;
};

export const VATReportService = {
  getVATReports(companyId: string, period: string): Promise<VatReportResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vat_reports`,
      params: {
        period,
      },
      ignoreCache: true,
      abortKey: 'getVATReports',
    });
  },
  storeVATReport(companyId: string, data: VatReportSaveBody): Promise<VatReportResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/vat_reports`,
      data,
      ignoreCache: true,
    });
  },
  getVATReportOverview(companyId: string, period: string, vatId: string | number): Promise<VatDetailsReportResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vat_overview`,
      params: {
        period,
        vatId,
      },
      ignoreCache: true,
    });
  },
  getVatReportById(companyId: string, vatReportId: string): Promise<VatReportResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vat_reports/${vatReportId}`,
    });
  },
  unlock(companyId: string, reportId: string): Promise<VatReportResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/vat_reports/${reportId}/unlock`,
      ignoreCache: true,
    });
  },
  getVatSpecificationOverview(
    companyId: string,
    params: GetVatOverviewSpecificationQuery,
  ): Promise<VatOverviewSpecificationResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vat_overview/specification`,
      params,
      abortKey: 'getVatSpecificationOverview',
    });
  },
  getVatSpecificationTransactions(
    companyId: string,
    filters: GetVatOverviewTransactionsQuery & PaginationQueryRequest,
  ): Promise<PaginatedVatOverviewTransactionsResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vat_overview/transactions`,
      params: filters,
      abortKey: 'getVatSpecificationTransactions',
    });
  },
};
