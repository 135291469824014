import { CompanyProductService } from '@/services/companyProductService';

const state = {};
const actions = {
  getProducts(context, { companyId, ignoreCache }) {
    return CompanyProductService.getProducts(companyId, ignoreCache);
  },
  getProduct(context, { companyId, productId, ignoreCache }) {
    return CompanyProductService.getProduct(companyId, productId, ignoreCache);
  },
  createProduct(context, { companyId, data }) {
    return CompanyProductService.createProduct(companyId, data);
  },
  updateProduct(context, { companyId, productId, data }) {
    return CompanyProductService.updateProduct(companyId, productId, data);
  },
  deactivateProduct(context, { companyId, productId }) {
    return CompanyProductService.updateProduct(companyId, productId, { active: false });
  },
  getProductTypes(context, { companyId }) {
    return CompanyProductService.getProductTypes(companyId);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
