export default {
  defaultDueDays: 14,
  invoiceInitialNumber: 10001,
  creditNoteInitialNumber: 50001,
  sortLocale: 'nb',
  googleAuthScopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  facebookAuthScopes: 'email',
  modules: {
    brandingRemoval: 'systima_branding_removal',
  },
  apiHealthStatusSuccess: 'success',
  apiHealthStatusError: 'error',
  i18nLocalStorageKey: 'systima_translations',
  i18nLocalStorageTTL: 30 * 60 * 1000, // 30 minutes
  acceptedFiles: [
    '.jpg',
    '.jpeg',
    'image/jpeg',
    '.png',
    'image/png',
    '.pdf',
    'application/pdf',
    '.xml',
    'application/xml',
    '.xls',
    'application/vnd.ms-excel',
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.doc',
    'application/msword',
    '.docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.wps',
    '.odt',
    'application/vnd.oasis.opendocument.text',
    '.fodt',
    '.ods',
    'application/vnd.oasis.opendocument.spreadsheet',
    '.fods',
    '.ott',
    '.csv',
    'text/csv',
    '.txt',
    'text/plain',
    '.rtf',
    'application/rtf',
    '.heif',
    'image/heif',
    '.heifs',
    'image/heif-sequence',
    '.heic',
    'image/heic',
    '.heics',
    'image/heic-sequence',
    '.avci',
    'image/avci',
    '.avcs',
    'image/avcs',
    '.avif',
    'image/avif',
  ].join(','),
};
