import type { Middleware } from '../router-types';

export const HasNoCompanyMiddleware: Middleware = async ({ redirect, store }) => {
  if (store.getters.hasCompany) {
    const selectedCompany = store.getters.selectedCompany;

    return redirect({
      name: 'Dashboard',
      params: { companyNameSlug: selectedCompany.nameSlug },
    });
  }
};
