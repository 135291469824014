import axios from 'axios';
import { firstCharUppercase } from './shared';
export default async function (postalCode) {
  let result = await axios({
    method: 'get',
    url: '/postalCodes.txt',
  });
  result = result.data.split('\n').find((line) => line.indexOf(postalCode) === 0);
  if (result) {
    return firstCharUppercase(result.split('\t')[1].toLowerCase());
  }
}
