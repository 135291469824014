import api from '@/utils/api';
import type { GetIntegrationAppsQuery, IntegratingAppCategoryResponse, IntegratingAppResponse } from '@/types/schema';

export const IntegrationsService = {
  getCategories(companyId: string): Promise<IntegratingAppCategoryResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/integration_apps/categories`,
    });
  },

  getIntegrations(companyId: string, params?: GetIntegrationAppsQuery): Promise<IntegratingAppResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/integration_apps`,
      params,
    });
  },

  activateIntegration(companyId: string, integrationId: number): Promise<void> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/integration_apps/${integrationId}/activate`,
    });
  },
};
