import api from '@/utils/api';
import type { InternationalizationResponse } from '@/types/schema';

enum LocaleName {
  En = 'en',
  NbNO = 'nb-NO',
}

export const I18nService = {
  getTranslations(locale = LocaleName.NbNO): Promise<InternationalizationResponse> {
    return api({
      method: 'get',
      url: `/i18n/${locale}`,
    });
  },
};
