<template>
  <iframe
    v-if="src"
    v-show="!resizing"
    :src="src"
    style="height: 100%; width: 100%; border: 0; min-height: 250px"
    @load="loaded"
  ></iframe>
</template>

<script>
export default {
  props: {
    rawData: {
      type: [ArrayBuffer, Blob],
      required: true,
    },
    resizing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    src() {
      if (!this.rawData) return;

      const blobUrl = window.URL.createObjectURL(
        new Blob([this.rawData], { type: 'application/pdf', pagemode: 'none' }),
      );
      const zoom = '#zoom=100';

      return blobUrl + zoom;
    },
  },
  methods: {
    loaded() {
      this.$emit('loaded');
    },
  },
};
</script>
