import api from '@/utils/api';

export const OccupationService = {
  getOccupations() {
    return api({
      method: 'get',
      url: `occupations`,
      ignoreCache: true,
    });
  },
};
