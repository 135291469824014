import api from '@/utils/api';
import type {
  ActivityResponse,
  ActivitySaveBody,
  EmployeeTimesheetResponse,
  EmployeeTimesheetSearchResponse,
  TimesheetSaveBody,
} from '@/types/schema';
import type { TimesheetSearchFilters } from '@/modules/timesheets/utils/types';

export const TimesheetService = {
  getActivities(companyId: string, isActive = true, searchString?: string): Promise<ActivityResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/activities`,
      params: { searchString, isActive },
    });
  },
  createActivity(companyId: string, data: ActivitySaveBody): Promise<ActivityResponse> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/activities`,
      data,
    });
  },
  updateActivity(companyId: string, activityId: string, data: ActivitySaveBody): Promise<ActivityResponse> {
    return api({
      method: 'put',
      url: `/companies/${companyId}/activities/${activityId}`,
      data,
    });
  },
  createTimesheet(
    companyId: string,
    employmentId: string,
    data: TimesheetSaveBody,
  ): Promise<EmployeeTimesheetResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/employments/${employmentId}/timesheets`,
      data,
    });
  },
  getEmployeeTimesheet(companyId: string, employmentId: string, weekOf: string): Promise<EmployeeTimesheetResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/employments/${employmentId}/timesheets`,
      params: {
        weekOf,
      },
    });
  },
  searchTimesheet(companyId: string, filters: TimesheetSearchFilters): Promise<EmployeeTimesheetSearchResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/timesheets`,
      ignoreCache: true,
      params: filters,
    });
  },
};
