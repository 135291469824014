<template>
  <div class="resizable-sidebar" :style="styles" :class="classes">
    <!-- TOOLBAR -->
    <div v-if="!minimized" class="resizable-sidebar__toolbar">
      <div class="resizable-sidebar__title text-truncate">
        <slot name="title" />
      </div>

      <div class="resizable-sidebar__controls">
        <slot name="controls" :compact="compactControls" />

        <!-- Button: vertical/horizontal mode -->
        <v-btn icon @click="toggleMode">
          <v-icon>{{ modeIcon }}</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- CONTENT -->
    <div v-show="!minimized && !resizing" class="resizable-sidebar__content">
      <slot name="content" />
    </div>

    <!-- Button: minimize -->
    <v-btn absolute fab width="30" height="30" class="resizable-sidebar__minimize-btn" @click="toggleMinimized">
      <v-icon>{{ minimizeIcon }}</v-icon>
    </v-btn>

    <!-- Button: resize handle -->
    <button
      class="resizable-sidebar__stick"
      @mousedown.stop.prevent="onStickDown"
      @touchstart.stop.prevent="onStickDown"
    ></button>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, type Ref } from 'vue';

enum Mode {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface ElementSizeRef {
  width: Ref<number>;
  height: Ref<number>;
}

interface PointerPosition {
  pointerX: number;
  pointerY: number;
}

const CONTENT_CONTAINER_SELECTOR = '#contentContainer';
const OVERLAP_THRESHOLD = 0.5; // at what % of main container the sidebar starts to overlap content container
const MAX_OVERLAP = 0.8; // max size of the sidebar as % of main container
const MIN_WIDTH = 150; // (px) minimize if width is less than this
const MIN_HEIGHT = 150; // (px) minimize if height is less than this
const MINIMIZED_WIDTH = 30; // (px) width when minimized
const MINIMIZED_HEIGHT = 30; // (px) height when minimized
const GAP = 0; // (px) gap between sidebar and content container
const COMPACT_CONTROLS_THRESHOLD = 600; // (px) sidebar width below which control buttons are displayed as icons

export default defineComponent({
  setup() {
    const mainContainerSize = inject<ElementSizeRef>('mainContainerSize');

    return {
      mainContainerSize,
    };
  },
  data: () => ({
    resizing: false,
    dimensionsBeforeMove: {
      pointerX: 0,
      pointerY: 0,
      width: 0,
      height: 0,
    },
    mounting: null as ReturnType<typeof setTimeout> | null,
  }),
  computed: {
    classes() {
      return {
        'resizable-sidebar--minimized': this.minimized,
        'resizable-sidebar--vertical': this.mode === Mode.VERTICAL,
        'resizable-sidebar--horizontal': this.mode === Mode.HORIZONTAL,
      };
    },
    styles() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    modeIcon() {
      return this.mode === Mode.HORIZONTAL ? 'mdi-dock-right' : 'mdi-dock-bottom';
    },
    minimizeIcon() {
      if (this.mode === Mode.HORIZONTAL) {
        return this.minimized ? 'mdi-chevron-up' : 'mdi-chevron-down';
      }
      return this.minimized ? 'mdi-chevron-left' : 'mdi-chevron-right';
    },
    contentContainer() {
      return document.querySelector(CONTENT_CONTAINER_SELECTOR) as HTMLElement;
    },
    compactControls() {
      return this.mode === Mode.VERTICAL && this.width < COMPACT_CONTROLS_THRESHOLD;
    },

    /**
     * getters/setters
     */

    mode: {
      get(): Mode {
        return this.$store.state.theme.attachmentViewerMode;
      },
      set(mode: Mode) {
        this.$store.commit('theme/setAttachmentViewerMode', mode);
      },
    },
    minimized: {
      get(): boolean {
        return this.$store.state.theme.isAttachmentViewerMinimized;
      },
      set(bool: boolean) {
        this.$store.commit('theme/setAttachmentViewerMinimized', bool);
      },
    },
    width: {
      get(): number {
        return this.minimized ? MINIMIZED_WIDTH : this.$store.state.theme.attachmentViewerWidth;
      },
      set(val: number) {
        this.$store.commit('theme/setAttachmentViewerWidth', Math.round(val));
      },
    },
    height: {
      get(): number {
        return this.minimized ? MINIMIZED_HEIGHT : this.$store.state.theme.attachmentViewerHeight;
      },
      set(val: number) {
        this.$store.commit('theme/setAttachmentViewerHeight', Math.round(val));
      },
    },
  },
  watch: {
    // do we want to keep this?
    // $route(to, from) {
    //   if (to.path !== from.path && !this.$route.query.bulkFileCreate) {
    //     this.minimized = false;
    //   }
    // },
    minimized() {
      this.updateContentContainerSize();
    },
    mode() {
      this.updateContentContainerSize();
    },
    mainContainerSize: {
      handler() {
        this.updateContentContainerSize();
      },
      deep: true,
    },
  },
  mounted() {
    this.mounting = setTimeout(() => {
      this.updateContentContainerSize();
    }, 0);
  },
  beforeDestroy() {
    this.mounting && clearTimeout(this.mounting);
    this.contentContainer.style.width = '';
    this.contentContainer.style.height = '';
  },
  methods: {
    toggleMinimized() {
      this.minimized = !this.minimized;
    },
    toggleMode() {
      this.mode = this.mode === Mode.HORIZONTAL ? Mode.VERTICAL : Mode.HORIZONTAL;
    },

    /**
     * Resize stick
     */

    onStickDown(event: MouseEvent | TouchEvent) {
      const { pointerX, pointerY } = this.getPointerPosition(event);

      this.saveDimensionsBeforeMove({ pointerX, pointerY });
      this.attachListeners();
    },
    onStickMove(event: MouseEvent | TouchEvent) {
      this.minimized = false;
      this.resizing = true;

      const { pointerX, pointerY } = this.getPointerPosition(event);
      const { dimensionsBeforeMove } = this;

      // How far the mouse has been moved
      const dx = dimensionsBeforeMove.pointerX - pointerX;
      const dy = dimensionsBeforeMove.pointerY - pointerY;

      switch (this.mode) {
        case Mode.VERTICAL:
          this.resizeWidth(dx);
          break;
        case Mode.HORIZONTAL:
          this.resizeHeight(dy);
          break;
      }
    },
    onStickUp() {
      this.removeListeners();
      this.resizing = false;

      switch (this.mode) {
        case Mode.VERTICAL:
          if (this.width < MIN_WIDTH) {
            this.minimized = true;
            this.width = MIN_WIDTH;
          }
          break;
        case Mode.HORIZONTAL:
          if (this.height < MIN_HEIGHT) {
            this.minimized = true;
            this.height = MIN_HEIGHT;
          }
          break;
      }
    },

    resizeWidth(amount = 0) {
      if (!this.mainContainerSize) return;

      const newWidth = this.dimensionsBeforeMove.width + amount;
      const maxWidth = this.mainContainerSize.width.value * MAX_OVERLAP;

      if (newWidth > maxWidth) {
        this.width = maxWidth;
        return;
      }

      if (newWidth < MINIMIZED_WIDTH) {
        this.width = MINIMIZED_WIDTH;
        return;
      }

      this.width = newWidth;

      if (newWidth < this.mainContainerSize.width.value * OVERLAP_THRESHOLD) {
        this.updateContentContainerSize();
      }
    },

    resizeHeight(amount = 0) {
      if (!this.mainContainerSize) return;

      const newHeight = this.dimensionsBeforeMove.height + amount;
      const maxHeight = this.mainContainerSize.height.value * MAX_OVERLAP;

      if (newHeight > maxHeight) {
        this.height = maxHeight;
        return;
      }

      if (newHeight < MINIMIZED_HEIGHT) {
        this.height = MINIMIZED_HEIGHT;
        return;
      }

      this.height = newHeight;

      if (newHeight < this.mainContainerSize.height.value * OVERLAP_THRESHOLD) {
        this.updateContentContainerSize();
      }
    },

    updateContentContainerSize() {
      if (!this.mainContainerSize) return;

      const { width, height } = this.mainContainerSize;

      switch (this.mode) {
        case Mode.VERTICAL:
          this.contentContainer.style.width = `${width.value - this.width - GAP}px`;
          this.contentContainer.style.height = '';
          break;
        case Mode.HORIZONTAL:
          this.contentContainer.style.width = '';
          this.contentContainer.style.height = `${height.value - this.height - GAP}px`;
          break;
      }
    },

    /**
     * Utils
     */

    attachListeners() {
      document.addEventListener('mousemove', this.onStickMove);
      document.addEventListener('mouseup', this.onStickUp);
      document.addEventListener('touchmove', this.onStickMove);
      document.addEventListener('touchend', this.onStickUp);
    },

    removeListeners() {
      document.removeEventListener('mousemove', this.onStickMove);
      document.removeEventListener('mouseup', this.onStickUp);
      document.removeEventListener('touchmove', this.onStickMove);
      document.removeEventListener('touchend', this.onStickUp);
    },

    saveDimensionsBeforeMove({ pointerX, pointerY }: PointerPosition) {
      this.dimensionsBeforeMove.pointerX = pointerX;
      this.dimensionsBeforeMove.pointerY = pointerY;
      this.dimensionsBeforeMove.width = this.width;
      this.dimensionsBeforeMove.height = this.height;
    },

    getPointerPosition(event: MouseEvent | TouchEvent): PointerPosition {
      if (event instanceof TouchEvent) {
        return {
          pointerX: event.touches[0].pageX,
          pointerY: event.touches[0].pageY,
        };
      }
      return {
        pointerX: event.pageX,
        pointerY: event.pageY,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.resizable-sidebar {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 0 10px #ccc;
  z-index: 7;

  &--vertical {
    min-width: 30px;
    height: 100% !important;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &--horizontal {
    min-height: 30px;
    width: 100% !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 5px 15px;
  }

  &__title {
    overflow: hidden;
    flex: 1;
    padding: 0 8px;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 15px 15px;
  }

  &__minimize-btn {
    .resizable-sidebar--vertical & {
      top: 10px;
      left: -15px;
    }
    .resizable-sidebar--horizontal & {
      top: -15px;
      left: 10px;
    }
  }

  &__stick {
    position: absolute;
    font-size: 1px;
    background: #ffffff;
    border: 1px solid #949494;
    border-radius: 8px;
    box-shadow: 0 0 2px #bbb;
    &::before {
      content: '';
      position: absolute;
      border: 1px dashed #949494;
    }

    .resizable-sidebar--vertical & {
      cursor: ew-resize;
      top: calc(50% - 24px);
      left: -8px;
      width: 16px;
      height: 48px;
      &::before {
        top: 8px;
        left: 6px;
        width: 2px;
        height: 32px;
      }
    }
    .resizable-sidebar--horizontal & {
      cursor: ns-resize;
      top: -8px;
      left: calc(50% - 24px);
      width: 48px;
      height: 16px;
      &::before {
        top: 6px;
        left: 8px;
        width: 32px;
        height: 2px;
      }
    }
  }
}
</style>
