import api from '@/utils/api';
import type { InvoiceSettingsResponse, InvoiceSettingsSaveBody } from '@/types/schema';

export const InvoiceSettingsService = {
  getInvoiceSettings(companyId: string): Promise<InvoiceSettingsResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoice_settings`,
    });
  },
  updateInvoiceSettings(companyId: string, data: InvoiceSettingsSaveBody): Promise<InvoiceSettingsResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoice_settings`,
      data,
    });
  },
};
