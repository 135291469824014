<template>
  <v-combobox
    v-model="value"
    prepend-inner-icon="mdi-magnify"
    :search-input.sync="search"
    :items="search ? searchResult : []"
    item-text="description"
    return-object
    auto-select-first
    :menu-props="{ 'allow-overflow': true }"
    :error-messages="error ? [error] : []"
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @input.native="value = $event.srcElement.value"
  ></v-combobox>
</template>

<script>
import { handleError } from '@/utils/shared';

export default {
  data: () => ({
    search: '',
    searchResult: [],
    timer: null,
    value: null,
    disableNextSearch: false,
    error: '',
  }),
  watch: {
    search(val) {
      this.error = '';
      if (this.disableNextSearch) return (this.disableNextSearch = false);
      val && val !== this.select && this.bregRequest(val);
    },
  },
  methods: {
    bregRequest() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const data = { text: this.search };
        // if (this.$route.params.companyNameSlug)
        //   data.excludeCompanyIdContacts = this.$store.getters.getCompanyIdByNameSlug(
        //     this.$route.params.companyNameSlug
        //   );

        this.$store
          .dispatch('companyContact/lookupContacts', data)
          .then((result) => {
            this.searchResult = result;
          })
          .catch((err) => {
            this.error = 'Det oppstod en feil ved søking i Brønnøysundregistrene';
            handleError(err);
          });
      }, 500);
    },
    setCompany(company) {
      this.disableNextSearch = true;
      this.value = company;
    },
  },
};
</script>
