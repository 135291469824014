import api from '@/utils/api';

export const HealthCheckService = {
  healthCheck() {
    return api({
      method: 'get',
      url: 'healthcheck/statuses',
      ignoreCache: true,
      timeout: 10000,
    });
  },
};
