import type { CompanyResponse } from '@/types/schema';
import type { Middleware } from '../router-types';

export const AccessControlGuardMiddleware: Middleware = async ({ to, store, redirect }) => {
  const { roles, modules } = to.meta || {};

  const company = store.state.currentCompany as CompanyResponse | null;
  const subscription = store.getters['subscription/getSubscriptionByCompanyId'](company?.id);
  const isTimesheetUser = store.getters['subscription/getIsTimesheetUser'](company?.id);

  if (company && isTimesheetUser && to.name === 'Modules') {
    return redirect({
      name: 'Timesheet',
      params: { companyNameSlug: company.nameSlug },
    });
  }

  if (roles) {
    const user = store.getters.getUser;

    if (!roles.includes(user?.systemRole)) {
      return redirect({ name: 'SelectCompany' });
    }
  }

  if (modules) {
    if (!company) {
      return redirect({ name: 'SelectCompany' });
    }

    if (!subscription) {
      return redirect({ name: 'Modules', params: { companyNameSlug: company.nameSlug } });
    }

    if (!modules.some((module) => subscription.subscriptionPlan.modules.includes(module))) {
      return redirect({
        name: isTimesheetUser ? 'Timesheet' : 'Modules',
        params: { companyNameSlug: company.nameSlug },
      });
    }
  }
};
