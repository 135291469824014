import { AuthService } from '@/services/authService';

const state = {};
const actions = {
  sendResetPasswordLink(context, { email }) {
    return AuthService.sendResetPasswordLink({ email });
  },
  setNewPassword(context, { token, password }) {
    return AuthService.setNewPassword({ token, password });
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
