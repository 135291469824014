<template>
  <div>
    <v-sheet class="header-backup"></v-sheet>
    <v-app-bar color="white" app class="px-sm text-left shadow-sm ma-3 rounded-lg" flat height="75">
      <v-app-bar-nav-icon v-ripple="{ class: 'primary--text' }" @click="toggleNavDrawerVisible" />
      <PageTitle />

      <v-spacer />

      <v-menu v-if="downloadOptions" left bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="downloadOptions.pdf" @click="download('PDF')">
            <v-list-item-title>{{ downloadOptions.pdf.title ?? 'Last ned .pdf' }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="downloadOptions.xls" @click="download('EXCEL')">
            <v-list-item-title>{{ downloadOptions.xls.title ?? 'Last ned .xls (excel)' }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="downloadOptions.csv" @click="download('CSV')">
            <v-list-item-title>{{ downloadOptions.csv.title ?? 'Last ned .csv (excel)' }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="downloadOptions.saf" @click="download('ZIP')">
            <v-list-item-title>{{ downloadOptions.saf.title ?? 'Last ned SAF-T' }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-badge
        v-if="unreadNotifications"
        bordered
        overlap
        :content="unreadNotifications"
        color="red"
        offset-x="22"
        offset-y="22"
      >
        <v-btn icon @click="notificationDrawer = !notificationDrawer">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
      <v-btn v-else icon @click="notificationDrawer = !notificationDrawer">
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <ContactHelpButton />
      <FeedbackButton />
      <ServiceStatus />
      <div style="margin-top: -21px; margin-left: 20px; min-width: 71px">
        <span style="font-size: 10px">{{ advancedMode ? 'Avansert visning' : 'Enkel visning' }}</span>
        <v-switch v-model="advancedMode" :hide-details="true" color="blue" class="align-center" inset dense flat />
      </div>
      <CompanySelect v-if="companies.length" solo flat hide-details navigation />
    </v-app-bar>

    <!-- notificationDrawer  -->
    <v-navigation-drawer v-model="notificationDrawer" fixed right height="100%" temporary floating width="350">
      <notification-drawer>
        <template #notificationDrawerCloseButton>
          <v-btn icon color @click.stop="notificationDrawer = !notificationDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CompanySelect from '@/components/CompanySelect.vue';
import PageTitle from '@/components/app-bar/PageTitle.vue';
import ServiceStatus from '@/modules/ServiceStatus.vue';
import FeedbackButton from '@/modules/FeedbackButton.vue';
import ContactHelpButton from '@/components/app-bar/ContactHelpButton.vue';

export default {
  components: {
    NotificationDrawer: () => import('./NotificationDrawer.vue'),
    CompanySelect,
    PageTitle,
    ServiceStatus,
    FeedbackButton,
    ContactHelpButton,
  },
  data: () => ({
    notificationDrawer: false,
  }),
  computed: {
    downloadReportModule() {
      return this.$store.getters['downloadReport/module'];
    },
    downloadReportParams() {
      return this.$store.getters['downloadReport/params'];
    },
    companies() {
      return this.$store.getters.companies;
    },
    unreadNotifications() {
      return this.$store.getters.user ? this.$store.getters.user.unreadNotifications : 0;
    },
    advancedMode: {
      get() {
        return this.$store.getters['theme/getAdvancedMode'];
      },
      set(value) {
        this.$store.dispatch('theme/toggleAdvancedMode', value);
      },
    },
    downloadOptions() {
      return this.downloadReportModule !== null ? this.$route.meta.download : undefined;
    },
  },
  methods: {
    ...mapActions(['changeVerticalSidebarDrawer', 'changeVerticalSidebarMini', 'signOut']),
    toggleNavDrawerVisible() {
      this.$store.dispatch('theme/toggleNavDrawerVisible');
    },
    logoutUser() {
      this.signOut();
      this.$router.push('/app/sessions/sign-in-two');
    },
    download(format) {
      const params = this.downloadReportParams;

      this.$api.downloadReport.download({
        module: this.downloadReportModule,
        companyId: this.companyId,
        format,
        routeParams: params.route,
        queryParams: params.query,
        body: params.body,
        method: params.body ? 'post' : 'get',
      });
    },
  },
};
</script>

<style lang="scss">
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
</style>
