import { CompanyContactService } from '@/services/companyContactService';
import { LookupService } from '@/services/lookupService';

const getDefaultState = () => ({
  contacts: [],
});

const state = getDefaultState();

const actions = {
  async getContacts({ commit }, { companyId, ignoreCache }) {
    const contacts = await CompanyContactService.getContacts(companyId, ignoreCache);
    commit('setContacts', contacts);
    return contacts;
  },
  getContact(context, { companyId, contactId }) {
    return CompanyContactService.getContact(companyId, contactId);
  },
  async createContact({ dispatch, commit }, { companyId, data }) {
    const contact = await CompanyContactService.createContact(companyId, data);

    commit('pushContact', contact);
    dispatch('accounting/actualAccountsFetch', { companyId }, { root: true });

    return contact;
  },
  async updateContact({ commit }, { companyId, contactId, data }) {
    const contact = await CompanyContactService.updateContact(companyId, contactId, data);

    commit('updateContact', contact);

    return contact;
  },
  deleteContact(context, { companyId, contactId }) {
    return CompanyContactService.deleteContact(companyId, contactId);
  },
  findContacts(context, { companyId, data }) {
    return CompanyContactService.findContacts(companyId, data);
  },
  lookupContacts(context, { text, excludeCompanyIdContacts }) {
    const data = text.length === 9 && /^\d+$/.test(text) ? { organizationNumber: text } : { name: text };
    if (excludeCompanyIdContacts) data.excludeCompanyIdContacts = excludeCompanyIdContacts;
    return LookupService.lookupContacts(data);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts;
  },
  pushContact(state, contact) {
    state.contacts.push(contact);
  },
  updateContact(state, contact) {
    const index = state.contacts.findIndex((item) => item.id === contact.id);

    if (index !== -1) {
      state.contacts.splice(index, 1, contact);
    }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  customers(state) {
    return state.contacts ? state.contacts.filter((contact) => contact.isCustomer) : [];
  },
  suppliers(state) {
    return state.contacts ? state.contacts.filter((contact) => contact.isSupplier) : [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
