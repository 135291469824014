const getDefaultState = () => ({
  isVisible: false,
  file: null, // ArrayBuffer | null
  metadata: null, // FileResponse | null
});

const state = getDefaultState;

const actions = {
  setFile({ commit }, file) {
    commit('SET_FILE', file);
  },
  setMetadata({ commit }, metadata) {
    commit('SET_METADATA', metadata);
  },
  reset({ commit }) {
    commit('SET_FILE', null);
    commit('SET_METADATA', null);
  },
};

const mutations = {
  SET_FILE(state, file) {
    state.file = file;
  },
  SET_METADATA(state, metadata) {
    state.metadata = metadata;
  },
  SET_VISIBLE(state, isVisible) {
    state.isVisible = isVisible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
