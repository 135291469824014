const localStorageVersion = '0.0.1';
const previousStorageVersion = undefined; // replace with localStorageVersion

export class SystimaStorage {
  constructor({ localStorageKey, state }) {
    if (!localStorageKey) {
      throw new Error('localStorageKey must be provided');
    }
    this.localStorageKey = localStorageKey;
    this.state = state || null;
    this.initializeStore();
    return this;
  }

  initializeStore() {
    try {
      const localStorageState = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (localStorageState) {
        if (localStorageState.version === localStorageVersion) {
          this.state = Object.assign(this.state, localStorageState.data);
        } else if (localStorageState.version === previousStorageVersion) {
          localStorage.setItem(this.localStorageKey, '');
        } else {
          localStorage.clear();
        }
      }
    } catch (e) {
      console.error("Coudn't retrieve item: " + this.localStorageKey, e);
    }
    return this.state;
  }

  saveState(state) {
    const localStorageData = {
      version: localStorageVersion,
      data: state,
    };
    try {
      localStorage.setItem(this.localStorageKey, JSON.stringify(localStorageData));
    } catch (e) {
      // silence
    }
  }
}
