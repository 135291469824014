export function isPdf(file: File) {
  return file.type === 'application/pdf';
}

export function isImage(file: File) {
  return file.type.startsWith('image/');
}

// Returns a truncated filename
// e.g. 'this-is-a-very-long-file-name.pdf' => 'this-is-a-very-lon....pdf'
export function formatFileName(fileName: string, maxLength = 20) {
  if (!fileName) return '';

  const name = fileName.slice(0, fileName.lastIndexOf('.'));
  const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

  return `${name.slice(0, maxLength)}${name.length > maxLength ? '...' : ''}.${extension}`;
}

// Returns a pretty formatted filesize
// e.g. "1.1 MB", "120 KB"
export function formatFileSize(bytes: number) {
  const units = ['TB', 'GB', 'MB', 'KB', 'b'] as const;
  let selectedSize = 0;
  let selectedUnit: (typeof units)[number] = 'b';

  if (bytes > 0) {
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const cutoff = Math.pow(1000, 4 - i) / 10;

      if (bytes >= cutoff) {
        selectedSize = bytes / Math.pow(1000, 4 - i);
        selectedUnit = unit;
        break;
      }
    }

    selectedSize = Math.round(10 * selectedSize) / 10; // Cutting of digits
  }

  return `${selectedSize} ${selectedUnit}`;
}
