import api from '@/utils/api';

export const InvoiceDraftPreviewService = {
  getInvoiceDraftPreview(companyId, data) {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoicepreview`,
      responseType: 'arraybuffer',
      data,
    });
  },
};
