import { InvoiceSendingService } from '@/services/invoiceSendingService';
import { InvoiceService } from '@/services/invoiceService';
import { CreditNoteService } from '@/services/creditNoteService';
import { formatDate } from '@/utils/shared';

const state = {};
const actions = {
  getInvoiceSendings(context, { companyId, invoiceId, ignoreCache }) {
    return InvoiceSendingService.getInvoiceSendings(companyId, invoiceId, ignoreCache);
  },
  createInvoiceSending(context, { companyId, invoiceId, data }) {
    return InvoiceSendingService.createInvoiceSending(companyId, invoiceId, data);
  },
  async getPossibleDocumentsForSending(context, { companyId, invoiceId }) {
    const invoice = await InvoiceService.getInvoice(companyId, invoiceId, true);
    invoice.type = 'INVOICE';
    invoice.label = `Faktura ${formatDate(invoice.createdAt)}`;
    const creditNotes = (await CreditNoteService.getCreditNotes(companyId, invoiceId, true)).map((cn) => ({
      ...cn,
      type: 'CREDIT_NOTE',
      label: `Kreditnota ${formatDate(cn.createdAt)}`,
    }));
    const reminders = invoice.reminders
      .map((rm) => ({
        ...rm,
        type: 'REMINDER',
        label: `Betalingspåminnelse ${formatDate(rm.createdAt)}`,
      }))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return [...creditNotes, ...reminders, invoice].sort((a, b) => a.createdAt - b.createdAt);
  },
  getEHFEligibility(context, { companyId, invoiceId }) {
    return InvoiceSendingService.checkEHFEligibility(companyId, invoiceId);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
