import api from '@/utils/api';

export const AuthService = {
  login(data) {
    return api({
      method: 'post',
      url: 'auth',
      ignoreCache: true,
      data,
    });
  },
  refreshToken(data) {
    return api({
      method: 'post',
      url: 'refreshtoken',
      data,
    });
  },
  confirmEmail(data) {
    return api({
      method: 'post',
      url: 'confirm-email',
      data,
    });
  },
  acceptInvitation(data) {
    return api({
      method: 'post',
      url: 'accept-invitation',
      data,
    });
  },
  sendResetPasswordLink(data) {
    return api({
      method: 'post',
      url: 'reset-password',
      data,
    });
  },
  setNewPassword(data) {
    return api({
      method: 'post',
      url: 'new-password',
      data,
    });
  },
};
