import type { CompanyResponse } from '@/types/schema';
import type { Middleware } from '../router-types';
import { TermSettlementService } from '@/services/term-settlement';
import { handleError } from '@/utils/shared';

export const TermSettlementPeriodMiddleware: Middleware = async ({ to, store, redirect }) => {
  if (to.params.termSettlementId) {
    const currentCompany = store.state.currentCompany as CompanyResponse | null;

    if (currentCompany) {
      try {
        const { period } = await TermSettlementService.getTermSettlementById(
          currentCompany.id,
          to.params.termSettlementId,
        );

        return redirect({
          name: 'SalaryTermSettlement',
          params: { companyNameSlug: currentCompany.nameSlug },
          query: { period },
        });
      } catch (e) {
        handleError(e);
      }
    }
  }
};
