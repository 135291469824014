export async function loadComponentChunk(importFn: () => Promise<any>) {
  try {
    return await importFn();
  } catch (error: any) {
    // Handle vite chunk loading errors
    // This may be due to a new deployment or a network problem
    const isChunkError = error?.message?.includes('Failed to fetch dynamically imported module');

    if (isChunkError && typeof window !== 'undefined' && window.sessionStorage) {
      const timeNow = Date.now();
      const lastReloadTime = window.sessionStorage.getItem('systima-app-reload');
      const lastReloadTimeParsed = lastReloadTime ? parseInt(lastReloadTime) : null;

      // check for previous reload time not to reload infinitely
      if (!lastReloadTimeParsed || lastReloadTimeParsed + 60000 < timeNow) {
        window.sessionStorage.setItem('systima-app-reload', timeNow.toString());
        window.location.reload();
      }
    }

    throw error;
  }
}
