import api from '@/utils/api';
import type {
  FinancialStatementItemResponse,
  FinancialStatementReportDefaultQueryModelResponse,
  FinancialStatementReportQuery,
} from '@/types/schema';

export const ReportsService = {
  getFinancialStatement(
    companyId: string,
    params: FinancialStatementReportQuery,
  ): Promise<FinancialStatementItemResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/financial_statement`,
      params,
      abortKey: 'getFinancialStatement',
    });
  },
  getFinancialStatementQuery(companyId: string): Promise<FinancialStatementReportDefaultQueryModelResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/financial_statement/query`,
    });
  },
};
