<template>
  <v-dialog
    :value="value"
    v-bind="$attrs"
    @keydown.enter="$emit('keydownEnter', $event)"
    @input="$emit('input', $event)"
  >
    <div style="position: relative">
      <template v-if="noCard">
        <slot></slot>
      </template>
      <v-card v-else>
        <slot></slot>
      </v-card>
      <div style="position: absolute; right: 16px; top: 16px">
        <v-btn small icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    noCard: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'input', 'keydownEnter'],
  watch: {
    value: {
      handler(newVal: boolean, oldVal: boolean | undefined) {
        if (newVal !== !!oldVal) {
          this.$store.dispatch('toggleDialog', newVal);
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    if (this.value) {
      this.$store.dispatch('toggleDialog', false);
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
});
</script>
