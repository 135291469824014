import api from '@/utils/api';
import type { CompanyVatResponse, VatCreateBody, FullVatResponse } from '@/types/schema';

export const CompanyVatService = {
  getCompanyVatList(
    companyId: string,
    params?: {
      showAll: boolean;
      search: string | null;
    },
  ): Promise<CompanyVatResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/company-vats`,
      params,
    });
  },
  getVatCode(companyId: string, vatId: string): Promise<FullVatResponse> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/company-vats/${vatId}`,
    });
  },
  saveVatCode(companyId: string, payload: VatCreateBody): Promise<CompanyVatResponse> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/company-vats`,
      data: payload,
    });
  },
  updateVatCode(companyId: string, vatId: string, payload: VatCreateBody): Promise<CompanyVatResponse> {
    return api({
      method: 'put',
      url: `/companies/${companyId}/company-vats/${vatId}`,
      data: payload,
    });
  },
  activate(companyId: string, vatId: string): Promise<void> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/company-vats/${vatId}/activate`,
    });
  },
  deactivate(companyId: string, vatId: string): Promise<void> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/company-vats/${vatId}/deactivate`,
    });
  },
};
