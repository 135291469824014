import { SystimaStorage } from '@/utils/systimaStorage';
import { ProfileService } from '@/services/profileService';

const localStorageKey = 'vuexThemeModule';
const defaultState = {
  isNavDrawerMinimized: false,
  isNavDrawerVisible: true,
  advancedMode: false,

  attachmentViewerMode: 'vertical', // vertical, horizontal
  isAttachmentViewerMinimized: false,
  attachmentViewerWidth: 300,
  attachmentViewerHeight: 300,
};

const systimaStorage = new SystimaStorage({ localStorageKey, state: defaultState });
const state = systimaStorage.state;

const actions = {
  toggleNavDrawerMinimized({ commit }) {
    commit('toggleNavDrawerMinimized');
  },
  toggleNavDrawerVisible({ commit }) {
    commit('toggleNavDrawerVisible');
  },
  toggleAdvancedMode({ state, commit }, checked) {
    const isChecked = typeof checked === 'boolean' ? checked : !state.advancedMode;
    commit('setAdvancedMode', isChecked);
    ProfileService.updateUser({
      mode: isChecked ? 'ADVANCED' : 'SIMPLE',
    });
  },
};
const mutations = {
  toggleNavDrawerMinimized(state) {
    state.isNavDrawerMinimized = !state.isNavDrawerMinimized;
    systimaStorage.saveState(state);
  },
  toggleNavDrawerVisible(state) {
    state.isNavDrawerVisible = !state.isNavDrawerVisible;
    systimaStorage.saveState(state);
  },
  setAdvancedMode(state, payload) {
    state.advancedMode = payload;
    systimaStorage.saveState(state);
  },
  setAttachmentViewerMode(state, mode) {
    state.attachmentViewerMode = mode;
    systimaStorage.saveState(state);
  },
  setAttachmentViewerMinimized(state, bool) {
    state.isAttachmentViewerMinimized = bool;
    systimaStorage.saveState(state);
  },
  setAttachmentViewerWidth(state, width) {
    state.attachmentViewerWidth = width;
    systimaStorage.saveState(state);
  },
  setAttachmentViewerHeight(state, height) {
    state.attachmentViewerHeight = height;
    systimaStorage.saveState(state);
  },
};
const getters = {
  getNavDrawerMinimized(state) {
    return state.isNavDrawerMinimized;
  },
  getNavDrawerVisible(state) {
    return state.isNavDrawerVisible;
  },
  getAdvancedMode(state) {
    return state.advancedMode;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
