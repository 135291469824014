import { format } from 'date-fns';
import { norwegianFormat } from '../constants';
import type { DateInterval } from '../types';

/**
 * Format a date interval to a string.
 *
 * @example
 * intervalToString({ dateFrom: '2021-01-01', dateTo: '2021-12-31' })
 * // '01.01.2021 - 31.12.2021'
 */
export function intervalToString(interval: DateInterval | null, dateFormat = norwegianFormat) {
  if (!interval?.dateFrom || !interval?.dateTo) return '';

  const from = format(new Date(interval.dateFrom), dateFormat);
  const to = format(new Date(interval.dateTo), dateFormat);
  const output = `${from} - ${to}`;

  return output;
}
