import { parseISO, isBefore, isSameDay } from 'date-fns';
import moment from 'moment'; // date-fns cannot strict check date format... https://github.com/date-fns/date-fns/issues/942
import { parseDateIntervalAlias } from './alias-parsers';
import type { DateInterval } from '../types';

/**
 * Validates that date is a valid ISO date.
 *
 * @example
 * isValidIsoDate('2021-01-01') // true
 * isValidIsoDate('2021-01-32') // false
 *
 * @param date ISO date string
 */
export function isValidIsoDate(date: string) {
  return moment(date, 'YYYY-MM-DD', true).isValid();
}

/**
 * Validates that date is a valid Norwegian date.
 *
 * @example
 * isValidNorwegianDate('01.01.2021') // true
 * isValidNorwegianDate('32.01.2021') // false
 *
 * @param date Norwegian date string
 */
export function isValidNorwegianDate(date: string) {
  return moment(date, 'DD.MM.YYYY', true).isValid();
}

/**
 * - Validates boths dates are valid ISO dates
 * - Validates that dateFrom is before or equal to dateTo
 */
export function isValidDateInterval(interval: DateInterval | null) {
  if (!interval) return false;

  if (!isValidIsoDate(interval.dateFrom) || !isValidIsoDate(interval.dateTo)) return false;

  const dateFrom = parseISO(interval.dateFrom);
  const dateTo = parseISO(interval.dateTo);

  return isBefore(dateFrom, dateTo) || isSameDay(dateFrom, dateTo);
}

/**
 * Validates that input is a valid interval string.
 *
 * Expected format: DD.MM.YYYY - DD.MM.YYYY
 *
 * @example
 * isValidIntervalString('01.01.2021 - 31.12.2021') // true
 * isValidIntervalString('01.01.2021 - 99.12.2021') // false
 * isValidIntervalString('01.01.2021 - 31.99.2021') // false
 * isValidIntervalString('01.01.2021 - 31.12.2021 - 31.12.2021') // false
 */
export function isValidIntervalString(input: string) {
  if (!input) return false;

  try {
    const interval = parseDateIntervalAlias(input);
    return isValidDateInterval(interval);
  } catch {
    return false;
  }
}
