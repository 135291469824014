import { CompanyLogoService } from '@/services/companyLogoService';
import { handleError } from '@/utils/shared';

const getDefaultState = () => ({
  logo: null,
});

const state = getDefaultState();

const actions = {
  async fetchLogo({ commit }, { companyId }) {
    try {
      const response = await CompanyLogoService.getLogo(companyId);
      commit('setLogo', window.URL.createObjectURL(new Blob([response])));
    } catch (error) {
      commit('setLogo', null);
      handleError(error);
    }
  },
  uploadLogo(context, { companyId, file }) {
    return CompanyLogoService.createLogo(companyId, file);
  },
  async deleteLogo({ commit }, { companyId }) {
    await CompanyLogoService.deleteLogo(companyId);
    commit('setLogo', null);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setLogo(state, logo) {
    state.logo = logo;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getLogo(state) {
    return state.logo;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
