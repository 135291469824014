import type { AxiosHeaders } from 'axios';

export const getFileNameFromHeaders = (headers: AxiosHeaders): string => {
  const filename = headers['content-disposition']?.split(';').pop();

  return decodeURIComponent(filename.split('=').pop());
};

export const downloadFile = (data: ArrayBuffer, type: string, fileName: string) => {
  const blob = new Blob([data], { type });
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(link.href);
};
