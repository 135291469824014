import * as Sentry from '@sentry/vue';
import { AMessageCodeService } from '@/services/AMessageCodeService';

const getDefaultState = () => ({
  aMessageCodes: [],
  loading: false,
});

const state = getDefaultState();

const actions = {
  async fetchAMessageCodes({ commit }) {
    try {
      commit('SET_LOADING', true);
      const aMessageCodes = await AMessageCodeService.getAMessageCodes();
      commit('SET_A_MESSAGE_CODES', aMessageCodes);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_A_MESSAGE_CODES(state, codesList) {
    state.aMessageCodes = codesList;
  },
  SET_LOADING(state, bool) {
    state.loading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
