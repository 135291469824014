import api from '@/utils/api';

export const InvoiceCommentService = {
  getInvoiceComments(companyId, invoiceId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/comments`,
      ignoreCache,
    });
  },
  createInvoiceComment(companyId, invoiceId, data) {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoices/${invoiceId}/comments`,
      data,
      ignoreCache: true,
    });
  },
  updateInvoiceComment(companyId, invoiceId, commentId, data) {
    return api({
      method: 'patch',
      url: `companies/${companyId}/invoices/${invoiceId}/comments/${commentId}`,
      data,
    });
  },
  deleteInvoiceComment(companyId, invoiceId, commentId) {
    return api({
      method: 'delete',
      url: `companies/${companyId}/invoices/${invoiceId}/comments/${commentId}`,
    });
  },
};
