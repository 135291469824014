import api from '@/utils/api';

export const CompanyLogoService = {
  getLogo(companyId) {
    return api({
      method: 'get',
      url: `companies/${companyId}/logo`,
      responseType: 'arraybuffer',
      ignoreCache: true,
    });
  },
  createLogo(companyId, file) {
    return api({
      method: 'post',
      url: `companies/${companyId}/logo`,
      file: file,
    });
  },
  deleteLogo(companyId) {
    return api({
      method: 'delete',
      url: `companies/${companyId}/logo`,
    });
  },
};
