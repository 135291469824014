import api from '@/utils/api';

import type {
  BankReconciliationSettingsShortcutResponse,
  BankReconciliationSettingsShortcutsRequest,
} from '@/types/schema';

export const BankReconciliationSettingsService = {
  getShortcuts(companyId: string): Promise<BankReconciliationSettingsShortcutResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/bank_reconciliation_settings/shortcuts`,
    });
  },
  saveShortcuts(
    companyId: string,
    payload: BankReconciliationSettingsShortcutsRequest,
  ): Promise<BankReconciliationSettingsShortcutResponse[]> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/bank_reconciliation_settings/shortcuts`,
      data: payload,
    });
  },
};
