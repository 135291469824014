<template>
  <v-menu
    v-model="showMenu"
    absolute
    offset-y
    content-class="menu-round"
    :close-on-click="messageSent || !inputs.message"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn :icon="!text" depressed :text="!!text" v-bind="attrs" v-on="on">
        <v-icon>mdi-message-outline</v-icon>
        {{ text }}
      </v-btn>
    </template>
    <v-card width="500">
      <v-form v-model="validForm" @submit.prevent="submit">
        <v-card-title>
          <span v-if="messageSent"> Takk for tilbakemeldingen! </span>
          <span v-else>Gi tilbakemelding</span>
        </v-card-title>
        <v-card-text>
          <div v-if="!messageSent" class="word-break">
            Send oss en melding med feedback, systemfeil eller nye forslag!
          </div>
          <div v-if="!messageSent" class="mt-3">
            <v-textarea
              v-model="inputs.message"
              :rules="rules.feedback"
              rows="5"
              label="Melding"
              @input="setFeedbackMessage"
            ></v-textarea>
          </div>
          <div v-if="!messageSent && !userEmail">
            <v-text-field v-model="inputs.email" :rules="rules.emailRequired" label="E-post"></v-text-field>
          </div>
          <div v-if="messageSent">
            <div>
              Vi skal se på dette snarest og komme tilbake til deg dersom det er nødvendig. Dersom du har flere
              tilbakemeldinger hører vi gjerne fra deg igjen.
            </div>
          </div>
          <div v-if="error" class="mb-2">
            <v-alert type="error">{{ error }}</v-alert>
          </div>
          <div class="d-flex justify-end">
            <v-btn
              :text="!messageSent"
              depressed
              :color="messageSent ? 'primary' : 'secondary'"
              :disabled="loading"
              @click="showMenu = false"
              >{{ messageSent ? 'Lukk' : 'Avbryt' }}</v-btn
            >
            <v-btn
              v-if="!messageSent"
              depressed
              color="primary"
              type="submit"
              :loading="loading"
              class="ml-2"
              :disabled="loading || !validForm"
              >Send</v-btn
            >
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
import validationRules from '@/utils/validationRules';
import { handleError } from '@/utils/shared';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    inputs: {
      message: '',
      email: '',
    },
    loading: false,
    messageSent: false,
    error: '',
    validForm: false,
    showMenu: false,
    rules: validationRules,
  }),
  computed: {
    userEmail() {
      return this.$store.getters.user?.email;
    },
  },
  watch: {
    showMenu(val) {
      if (!val && this.messageSent) {
        setTimeout(() => {
          this.resetToDefault();
        }, 200);
      }
    },
  },
  mounted() {
    this.setInputs();
  },
  methods: {
    setInputs() {
      this.inputs.message = this.$store.getters.feedbackMessage;
    },
    resetToDefault() {
      this.inputs.message = '';
      this.inputs.email = '';
      this.setFeedbackMessage('');
      this.error = '';
      this.messageSent = false;
    },
    setFeedbackMessage(val) {
      this.$store.dispatch('set', {
        feedbackMessage: val,
      });
    },
    submit() {
      this.loading = true;
      this.error = '';
      this.$store
        .dispatch('feedback/sendFeedback', {
          message: this.inputs.message,
          email: this.inputs.email ? this.inputs.email : this.userEmail,
          page: location.toString(),
        })
        .then(() => {
          this.messageSent = true;
          this.setFeedbackMessage('');
          this.loading = false;
        })
        .catch((err) => {
          this.error = 'Kunne ikke sende inn tilbakemelding';
          this.loading = false;
          handleError(err);
        });
    },
  },
};
</script>
