// import all modules from `./modules` folder
// https://vitejs.dev/guide/features.html#glob-import
const importedModules: Record<string, any> = import.meta.glob('./modules/*.js', {
  import: 'default',
  eager: true,
});

// rename all keys from './modules/accounting.js' -> 'accounting'
const modules = Object.entries(importedModules).reduce<Record<string, any>>((acc, [key, value]) => {
  const keyName = key.split('/').pop()?.split('.').shift() as string;
  acc[keyName] = value;
  return acc;
}, {});

export default modules;
