import api from '@/utils/api';

export const FacebookAuthService = {
  login({ token }) {
    return api({
      method: 'post',
      url: '/facebook/auth',
      ignoreCache: true,
      data: { token },
    });
  },
};
