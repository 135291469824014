import api from '@/utils/api';
import type {
  InvoiceResponse,
  InvoiceCreateBody,
  InvoiceUpdateBody,
  InvoiceReminderCreateBody,
  InvoiceReminderResponse,
  GetInvoicesQuery,
  PaginationQueryRequest,
} from '@/types/schema';
import type { PaginatedResponse } from '@/utils/pagination';

export const InvoiceService = {
  getInvoices(companyId: string): Promise<InvoiceResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices`,
    });
  },
  getPaginatedInvoices(
    companyId: string,
    filters: GetInvoicesQuery & PaginationQueryRequest,
  ): Promise<PaginatedResponse<InvoiceResponse>> {
    return api({
      method: 'get',
      url: `/v2/companies/${companyId}/invoices`,
      params: {
        ...filters,
      },
      abortKey: 'getInvoicesV2',
    });
  },
  getInvoice(companyId: string, invoiceId: string): Promise<InvoiceResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}`,
    });
  },
  createInvoice(companyId: string, data: InvoiceCreateBody): Promise<InvoiceResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoices`,
      data,
    });
  },
  updateInvoice(companyId: string, invoiceId: string, data: InvoiceUpdateBody): Promise<InvoiceResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/invoices/${invoiceId}`,
      data,
    });
  },
  createInvoiceReminder(
    companyId: string,
    invoiceId: string,
    data: InvoiceReminderCreateBody,
  ): Promise<InvoiceReminderResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoices/${invoiceId}/reminder`,
      data,
    });
  },
};
