<template>
  <div
    class="dropzone-area"
    :class="{
      'drag-hover': hover,
      'dropzone-area--full-height': fullHeight,
    }"
    @dragenter.prevent="handleDragEnter"
    @drop.prevent="handleDrop"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent=""
  >
    <div class="dropzone-content">
      <slot></slot>
    </div>
    <div class="dropzone-title">
      <h3>Slipp fil(er) her</h3>
    </div>
  </div>
</template>
<script lang="ts">
import constants from '@/utils/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    counter: 0,
  }),
  computed: {
    hover(): boolean {
      return this.counter >= 1;
    },
  },
  methods: {
    handleDragEnter() {
      this.counter++;
    },
    handleDragLeave() {
      this.counter--;
    },
    handleDrop(e: DragEvent) {
      const files = [];
      let mimeMismatch = false;

      for (const file of Array.from(e.dataTransfer?.files || [])) {
        if (constants.acceptedFiles.split(',').includes(file.type)) {
          files.push(file);
        } else {
          mimeMismatch = true;
        }
      }

      this.counter = 0;

      if (files.length) {
        this.$emit('drop', { files, warning: mimeMismatch });
      } else if (mimeMismatch) {
        this.$emit('error');
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.dropzone-area {
  position: relative;
  height: 100%;
  &.dropzone-area--full-height {
    min-height: calc(100vh - 150px);
  }

  .dropzone-content {
    height: 100%;
  }
}

.dropzone-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.drag-hover {
  border: 2px dashed var(--v-primary-base);
  border-radius: 12px;

  .dropzone-content {
    opacity: 0.3;
  }

  .dropzone-title {
    display: block;
  }
}
</style>
