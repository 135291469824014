<template>
  <div v-if="errors.length" class="v-alert--global__wrapper">
    <div class="pa-3">
      <v-alert dismissible class="v-alert--global" type="error" :value="true" @input="dismiss">
        <div v-if="errors.length === 1">{{ errors[0] }}</div>
        <ul v-else class="error-list">
          <li v-for="error of errors" :key="error">{{ error }}</li>
        </ul>
      </v-alert>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    errors(): string[] {
      return this.$store.getters.errors;
    },
  },
  methods: {
    dismiss() {
      this.$store.dispatch('clearErrorAlert');
    },
  },
});
</script>
