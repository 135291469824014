import type { Middleware } from '../router-types';

export const AuthGuardMiddleware: Middleware = async ({ to, redirect, store }) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];

  if (!isLoggedIn) {
    if (to.query.invitationToken) {
      if (to.query.hasAccount === '1') {
        return redirect({
          name: 'Login',
          query: { invitationToken: to.query.invitationToken, email: to.query.email },
        });
      } else {
        return redirect({ name: 'Register1', query: to.query });
      }
    } else {
      return redirect({ name: 'Login', query: { redirect: to.fullPath } });
    }
  } else {
    if (!store.getters.getUser) {
      await store.dispatch('getUserAndCompanies');
    }
  }
};
