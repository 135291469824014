import api from '@/utils/api';
import type { TermSettlementResponse, TermSettlementSaveBody } from '@/types/schema';

export const TermSettlementService = {
  getTermSettlement(companyId: string, period: string): Promise<TermSettlementResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/term_settlement`,
      params: {
        period,
      },
    });
  },
  getTermSettlementById(companyId: string, id: string): Promise<TermSettlementResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/term_settlement/${id}`,
    });
  },

  saveTermSettlement(companyId: string, payload: TermSettlementSaveBody): Promise<TermSettlementResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/term_settlement`,
      data: payload,
    });
  },
};
