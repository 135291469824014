import type { Directive } from 'vue';
import type { Vue } from 'vue/types/vue';

export interface NavigatableHTMLElement extends HTMLElement {
  keyboardNavigation: {
    vueInstance: VueExtended;
    options: Options;
  };
}

export type Options = {
  ref?: string;
  disabled?: boolean;
  skipOnEnter?: boolean;
  onEnter?: () => void;
};

export type VueExtended = Vue & {
  onFocus?: () => void;
  onBlur?: () => void;
  focus?: () => void;
  blur?: () => void;
  isFocused?: boolean;
  disabled?: boolean;
};

const directive: Directive<NavigatableHTMLElement, Options> = {
  bind: (el, { value = {} }, vnode) => {
    if (!vnode.componentInstance) {
      console.warn('[kb-nav] No component instance found', { el, vnode });
      return;
    }

    let instance: VueExtended = vnode.componentInstance;

    if (value.ref) {
      instance = instance.$refs[value.ref] as VueExtended;
    }

    el.setAttribute('kb-nav', '');
    el.keyboardNavigation = {
      vueInstance: instance,
      options: value,
    };
  },
  update(el, { value = {} }) {
    el.keyboardNavigation.options = value;
  },
};

export default directive;
