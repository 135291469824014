import api from '@/utils/api';

export const FeedbackService = {
  sendFeedback(data) {
    return api({
      method: 'post',
      url: 'feedback',
      data,
    });
  },
};
