import { BankAccountService } from '@/services/bankAccountService';
import { CompanyService } from '@/services/companyService';

const state = {};
const actions = {
  async getBankAccount(context, { companyId, ignoreCache }) {
    const company = await CompanyService.getCompany(companyId, ignoreCache);
    if (!company) {
      throw new Error('No company is got');
    }
    if (!company.defaultBankAccountId) {
      throw new Error('Default bank account should be set');
    }
    return BankAccountService.getBankAccount(companyId, company.defaultBankAccountId, ignoreCache);
  },
  async savePreInvoiceInfo({ dispatch }, { companyId, defaultDueDays, number, swift, iban, bankAccountId }) {
    const companyUpdateResponse = await CompanyService.updateCompany(companyId, { defaultDueDays });
    dispatch('updateCompanyState', companyUpdateResponse, { root: true });

    const data = {
      number,
      iban: iban || null,
      swift: swift || null,
    };

    if (bankAccountId) {
      return BankAccountService.updateBankAccount(companyId, bankAccountId, data);
    } else {
      return BankAccountService.createBankAccount(companyId, {
        name: 'Bankkonto',
        type: 'STANDARD',
        ...data,
      });
    }
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
