import { GoogleAuthService } from '@/services/googleAuthService';

const state = {};
const actions = {
  async login({ dispatch }, code) {
    const authResponse = await GoogleAuthService.login({ code });
    if (authResponse) {
      await dispatch('auth/saveAuth', authResponse, { root: true });
      await dispatch('getUserAndCompanies', null, { root: true });
    }
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
