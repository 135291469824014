import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import config from '@/utils/config';

Sentry.init({
  Vue,
  dsn: config.sentryDsn,
  environment: config.sentryEnv,
  ignoreErrors: [
    'NavigationDuplicated',
    'Failed to fetch dynamically imported module',
    'Unable to preload CSS',
    'Redirected when going from',
    'Navigation cancelled from',
  ],
});
