import api from '@/utils/api';
import type {
  AgingReportResponse,
  CreateSubAccountConnectionBody,
  GetAgingReportQuery,
  GetSubAccountReportQuery,
  PaginationQueryRequest,
  SubAccountReportResponse,
} from '@/types/schema';
import type { PaginatedResponse } from '@/utils/pagination';

interface GetSubAccountsReportOptions {
  type: GetSubAccountReportQuery['type'];
  search: string;
  showLocked: boolean;
  date: string;
  startDate: string;
  endDate: string;
  contactId: string;
  employeeId: string;
}

export const SubAccountsService = {
  getAll(
    companyId: string,
    params: GetSubAccountsReportOptions & PaginationQueryRequest,
  ): Promise<PaginatedResponse<SubAccountReportResponse>> {
    const _params: GetSubAccountReportQuery = {
      ...params,
      notLocked: !params.showLocked,
    };

    if (params.contactId) {
      _params.contactId = params.contactId;
    }

    if (params.employeeId) {
      _params.employeeId = params.employeeId;
    }

    return api({
      method: 'get',
      url: `companies/${companyId}/sub_accounts_v2/report`,
      params: _params,
      abortKey: 'getSubAccounts',
    });
  },
  async getAging(companyId: string, params: Omit<GetAgingReportQuery, 'companyId'>): Promise<AgingReportResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/aging_report`,
      params,
      abortKey: 'getSubAccountsAging',
    });
  },
  lock(companyId: string, groups: CreateSubAccountConnectionBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/sub_accounts/lock`,
      data: {
        groups,
      },
    });
  },
  open(companyId: string, connectionIds: string[]): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/sub_accounts/open`,
      data: {
        connectionIds,
      },
    });
  },
};
