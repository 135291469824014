import type { AMessageCodeResponse } from '@/types/schema';
import api from '@/utils/api';

export const AMessageCodeService = {
  getAMessageCodes(): Promise<AMessageCodeResponse[]> {
    return api({
      method: 'get',
      url: `a_message_codes`,
      ignoreCache: true,
    });
  },
};
