import api from '@/utils/api';
import { getOnboardStepText } from '@/utils/shared';

function extendOnboardStep(step = {}) {
  return { ...step, description: getOnboardStepText(step.name) };
}

function mapResponse(response) {
  if (Array.isArray(response)) {
    const extended = response.map(extendOnboardStep);
    if (response.fromCache) extended.fromCache = true;
    return extended;
  } else {
    return extendOnboardStep(response);
  }
}

export const CompanyOnboardStepService = {
  getOnboardSteps(companyId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/onboard-steps`,
      ignoreCache,
    }).then(mapResponse);
  },
  passOnboardStep(companyId, stepName) {
    return api({
      method: 'post',
      url: `companies/${companyId}/onboard-steps/${stepName}`,
    });
  },
};
