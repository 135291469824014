import api from '@/utils/api';
export const ImportContactsService = {
  import(companyId, file) {
    return api({
      method: 'post',
      url: `companies/${companyId}/importcontacts`,
      file,
    });
  },
  downloadTemplate() {
    return api({
      method: 'get',
      url: `importcontacts/template`,
      responseType: 'arraybuffer',
      ignoreCache: true,
    });
  },
};
