import * as Sentry from '@sentry/vue';
import { DepartmentService } from '@/services/departmentService';

const getDefaultState = () => ({
  departments: [],
  loading: false,
});

const state = getDefaultState();

const actions = {
  async fetchDepartments({ commit }, { companyId }) {
    try {
      commit('SET_LOADING', true);
      const departments = await DepartmentService.getDepartments(companyId);
      commit('SET_DEPARTMENTS', departments);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createDepartment({ commit }, { companyId, payload }) {
    try {
      const department = await DepartmentService.createDepartment(companyId, payload);
      commit('CREATE_DEPARTMENT', department);
      return department;
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  async updateDepartment({ commit }, { companyId, departmentId, payload }) {
    try {
      const department = await DepartmentService.updateDepartment(companyId, departmentId, payload);
      commit('UPDATE_DEPARTMENT', department);
      return department;
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_LOADING(state, bool) {
    state.loading = bool;
  },
  SET_DEPARTMENTS(state, departments) {
    state.departments = departments;
  },
  CREATE_DEPARTMENT(state, department) {
    state.departments.push(department);
  },
  UPDATE_DEPARTMENT(state, department) {
    const index = state.departments.findIndex((d) => d.id === department.id);
    if (index !== -1) {
      state.departments.splice(index, 1, department);
    }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  allDepartments: (state) => {
    return state.departments.map((department) => ({
      ...department,
      title: (department.number ? `${department.number} ` : '') + department.name,
    }));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
