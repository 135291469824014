import { BankAccountService } from '@/services/bankAccountService';

const getDefaultState = () => ({
  bankAccounts: [],
  editBankAccount: null,
});

const state = getDefaultState();

const actions = {
  getBankAccounts(_context, { companyId, ignoreCache }) {
    return BankAccountService.getBankAccounts(companyId, ignoreCache);
  },
  async deleteBankAccount(_context, { companyId, bankAccountId, ignoreCache = true }) {
    return BankAccountService.deleteBankAccount(companyId, bankAccountId, ignoreCache);
  },
  async loadBankAccounts(context, { companyId, ignoreCache }) {
    const bankAccounts = await BankAccountService.getBankAccounts(companyId, ignoreCache);
    context.commit('setBankAccounts', bankAccounts);
  },
  async loadBankAccountsWithConsent(context, { companyId, ignoreCache }) {
    const bankAccounts = await BankAccountService.getBankAccounts(companyId, ignoreCache);
    for (const bankAccount of bankAccounts) {
      const bankAccountConsentStatus = await BankAccountService.getBankAccountConsentStatus(companyId, bankAccount.id);
      bankAccount.consented = bankAccountConsentStatus.consented;
      bankAccount.consentExpirationDate = bankAccountConsentStatus.expirationDate;
    }
    const bankAccountsArray = bankAccounts.map((bankAccount) => {
      const consentExpiresInDays = bankAccount.consentExpirationDate
        ? Math.floor((Date.parse(bankAccount.consentExpirationDate) - Date.now()) / 86400000)
        : null;
      const consentExpiringIn10days = consentExpiresInDays < 10;
      const consentedAndNotExpiring = !consentExpiringIn10days && bankAccount.consented;
      const title = `${bankAccount.number} (${bankAccount.account?.number} ${bankAccount.account?.name})`;
      return { ...bankAccount, consentExpiresInDays, consentExpiringIn10days, consentedAndNotExpiring, title };
    });
    context.commit('setBankAccounts', bankAccountsArray);
  },
  async loadEditBankAccount(context, { companyId, bankAccountId }) {
    context.commit('setEditBankAccount', null);
    const bankAccount = await BankAccountService.getBankAccount(companyId, bankAccountId, true);
    context.commit('setEditBankAccount', bankAccount);
  },
  resetEditBankAccount(context) {
    context.commit('setEditBankAccount', null);
  },
  async storeBankAccount(_ctx, { companyId, number, name, iban, swift, account }) {
    const accountNumber = account.number;
    const payload = {
      number,
      name,
      iban: iban || null,
      swift: swift || null,
      account: `${accountNumber}`,
      type: 'STANDARD',
    };

    return await BankAccountService.createBankAccount(companyId, payload);
  },
  async updateBankAccount(_ctx, { companyId, number, name, iban, swift, account, id }) {
    const accountNumber = account.number;
    const payload = {
      number,
      name,
      iban: iban || null,
      swift: swift || null,
      account: `${accountNumber}`,
      type: 'STANDARD',
    };

    return await BankAccountService.updateBankAccount(companyId, id, payload);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setBankAccounts(state, bankAccounts) {
    state.bankAccounts = bankAccounts;
  },
  setEditBankAccount(state, bankAccount) {
    state.editBankAccount = bankAccount;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getBankAccounts(state) {
    return state.bankAccounts?.sort((a, b) => {
      return a.account?.number > b.account?.number ? 1 : -1;
    });
  },
  getConsentedBankAccounts(state) {
    return state.bankAccounts?.filter((bankAccount) => bankAccount.consented);
  },
  getEditBankAccount(state) {
    return state.editBankAccount;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
