import type { InvoiceDraftRequest, InvoiceResponse } from '@/types/schema';
import api from '@/utils/api';

export const InvoiceDraftService = {
  getInvoiceDrafts(companyId: string, ignoreCache?: boolean): Promise<InvoiceResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoicedrafts`,
      ignoreCache,
    });
  },
  getInvoiceDraft(companyId: string, invoiceDraftId: string, ignoreCache?: boolean): Promise<InvoiceResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoicedrafts/${invoiceDraftId}`,
      ignoreCache,
    });
  },
  createInvoiceDraft(companyId: string, data: InvoiceDraftRequest): Promise<InvoiceResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoicedrafts`,
      data,
    });
  },
  updateInvoiceDraft(companyId: string, invoiceDraftId: string, data: InvoiceDraftRequest): Promise<InvoiceResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/invoicedrafts/${invoiceDraftId}`,
      data,
    });
  },
  deleteInvoiceDraft(companyId: string, invoiceDraftId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/invoicedrafts/${invoiceDraftId}`,
    });
  },
  createOffer(companyId: string, data: InvoiceDraftRequest): Promise<ArrayBuffer> {
    return api({
      method: 'post',
      url: `companies/${companyId}/offers`,
      responseType: 'arraybuffer',
      data,
    });
  },
  updateOffer(companyId: string, invoiceDraftId: string, data: InvoiceDraftRequest): Promise<ArrayBuffer> {
    return api({
      method: 'put',
      url: `companies/${companyId}/offers/${invoiceDraftId}`,
      responseType: 'arraybuffer',
      data,
    });
  },
};
