import api from '@/utils/api';
import type { AuthUrlResponse, IDPortenLoginStatus } from '@/types/schema';

export const IdPortenService = {
  getStatus(): Promise<IDPortenLoginStatus> {
    return api({
      method: 'get',
      url: `idporten/status`,
      ignoreCache: true,
    });
  },
  getAuthUrl(): Promise<AuthUrlResponse> {
    return api({
      method: 'get',
      url: `idporten/url`,
      ignoreCache: true,
    });
  },
  authorize(code: string): Promise<void> {
    return api({
      method: 'post',
      url: `idporten/auth`,
      ignoreCache: true,
      data: {
        code,
      },
    });
  },
};
