import api from '@/utils/api';
import type {
  CompanySubscriptionPlanResponse,
  CompanySubscriptionPlanSaveBody,
  SubscriptionPlanResponse,
} from '@/types/schema';

export const SubscriptionService = {
  fetchCurrentSubscription(companyId: string): Promise<CompanySubscriptionPlanResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/current_subscription`,
    });
  },
  changeSubscription(
    companyId: string,
    payload: CompanySubscriptionPlanSaveBody,
  ): Promise<CompanySubscriptionPlanResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/current_subscription`,
      data: payload,
    });
  },
  fetchAvailableSubscriptions(): Promise<SubscriptionPlanResponse[]> {
    return api({
      method: 'get',
      url: `subscription_plans`,
    });
  },
};
