import api from '@/utils/api';

export const CreditNoteService = {
  getCreditNotes(companyId, invoiceId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/creditnotes`,
      ignoreCache,
    });
  },
  createCreditNote(companyId, invoiceId, data) {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoices/${invoiceId}/creditnotes`,
      data,
      ignoreCache: true,
    });
  },
};
