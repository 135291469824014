import { HealthCheckService } from '@/services/healthCheckService';
import constants from '@/utils/constants';
import store from '@/store';

const state = {
  healthCheckTimer: null,
  healthCheckCount: 0,
};
const actions = {
  healthCheck({ state, commit }) {
    clearTimeout(state.checkStatusTimer);
    HealthCheckService.healthCheck()
      .then(() => {
        commit('set', { apiHealthStatus: constants.apiHealthStatusSuccess }, { root: true });
        state.checkStatusCount = 0;
      })
      .catch(() => {
        commit('set', { apiHealthStatus: constants.apiHealthStatusError }, { root: true });
        state.checkStatusCount++;
        if (state.checkStatusCount < 100) {
          state.checkStatusTimer = setTimeout(() => store.dispatch('healthCheck/healthCheck'), 5000);
        }
      });
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
