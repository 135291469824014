import api from '@/utils/api';
import type { VoucherReportResponse } from '@/types/schema';
import type { VoucherFilters } from '@/modules/vouchers/utils/types';

export const VoucherService = {
  getVouchers(companyId: string, filters: Partial<VoucherFilters>): Promise<VoucherReportResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vouchers`,
      params: filters,
    });
  },
};
