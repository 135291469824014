import api from '@/utils/api';

export const InvoiceSendingService = {
  getInvoiceSendings(companyId, invoiceId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/sendings`,
      ignoreCache,
    });
  },
  createInvoiceSending(companyId, invoiceId, data) {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoices/${invoiceId}/sendings`,
      data,
      ignoreCache: true,
    });
  },
  checkEHFEligibility(companyId, invoiceId) {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/sendings/eligibility/EHF`,
      ignoreCache: true,
    });
  },
};
