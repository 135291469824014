const getDefaultState = () => ({
  pageTitle: null,
  pageDescription: null,
});

const state = getDefaultState();

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle;
  },
  setPageDescription(state, pageDescription) {
    state.pageDescription = pageDescription;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getPageTitle(state) {
    return state.pageTitle;
  },
  getPageDescription(state) {
    return state.pageDescription;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
