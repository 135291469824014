import api from '@/utils/api';
import type {
  BankIntegrationOnboardingStatusResponse,
  BankIntegrationOnboardingInitiationResponse,
  BankConsentResultCreateBody,
  BankPaymentUpdateStatusCreateBody,
  BankIntegrationPaymentsStatusResponse,
  BankSalaryPaymentUpdateStatusCreateBody,
  PaymentCancellationResultBody,
} from '@/types/schema';

export const BankIntegrationService = {
  getCompanyOnboardingStatus(companyId: string): Promise<BankIntegrationOnboardingStatusResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bank_integration/onboarding`,
      ignoreCache: true,
    });
  },
  startCompanyOnboarding(companyId: string, redirectUrl = ''): Promise<BankIntegrationOnboardingInitiationResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bank_integration/onboarding`,
      ignoreCache: true,
      data: {
        redirectUrl,
      },
    });
  },
  updateCompanyOnboardingStatus(companyId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bank_integration/onboarding/status`,
      ignoreCache: true,
    });
  },
  saveConsentResult(companyId: string, data: BankConsentResultCreateBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bank_integration/consent`,
      data,
    });
  },
  updatePayment(
    companyId: string,
    data: BankPaymentUpdateStatusCreateBody,
  ): Promise<BankIntegrationPaymentsStatusResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bank_integration/payment_update`,
      data,
    });
  },
  updateSalaryPayment(
    companyId: string,
    data: BankSalaryPaymentUpdateStatusCreateBody,
  ): Promise<BankIntegrationPaymentsStatusResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bank_integration/salary_payment_update`,
      data,
    });
  },
  saveCancellationResult(companyId: string, data: PaymentCancellationResultBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bank_integration/cancellation`,
      data,
    });
  },
};
