<template>
  <div>
    <v-card-title>{{ modalTitle }}</v-card-title>
    <v-card-text>
      <div>
        <slot>{{ modalText }}</slot>
      </div>
      <div class="d-flex justify-end mt-4">
        <v-btn class="mr-2" text color="secondary" @click="$emit('close')">Avbryt</v-btn>
        <v-btn depressed color="error" :loading="loading" :disabled="loading" @click="commitDelete">
          Slett<v-icon right>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    modalText: {
      type: String,
      default: '',
    },
    commitDelete: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
});
</script>
