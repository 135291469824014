<template>
  <VueDropzone
    id="dropzone"
    ref="dropzone"
    :options="dropzoneOptions"
    use-custom-slot
    class="s-file-dropzone"
    @vdropzone-files-added="updateFiles"
    @vdropzone-removed-file="updateFiles"
  >
    <div>
      <h3>Dra og slipp fil her</h3>
      <div>eller <span class="text-decoration-underline">klikk her for å velge fil</span></div>
    </div>
  </VueDropzone>
</template>

<script>
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import constants from '@/utils/constants';

export default {
  components: {
    VueDropzone,
  },
  props: {
    maxFiles: {
      type: Number,
      default: null,
    },
  },
  computed: {
    dropzoneOptions() {
      return {
        url: '/',
        acceptedFiles: constants.acceptedFiles,
        autoProcessQueue: false,
        maxFilesize: 20, // MB,
        maxFiles: this.maxFiles,
        disablePreviews: true,
      };
    },
  },
  methods: {
    updateFiles() {
      this.$nextTick(() => {
        this.$emit('update:files', this.$refs?.dropzone?.getQueuedFiles());
      });
    },
    reset() {
      this.$refs.dropzone && this.$refs.dropzone.removeAllFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
.s-file-dropzone {
  padding: 20px 20px 30px;
  min-height: 250px;
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &.dz-drag-hover {
    background: #eee;
  }
}
</style>
