import type {
  CompanyAccountingFirmResponse,
  CompanyCreateBody,
  CompanyResponse,
  CompanyUpdateBody,
  CompanyUserResponse,
  InvitationCreateBody,
  InvitationResponse,
  GetCompaniesQuery,
} from '@/types/schema';
import api from '@/utils/api';

const basePath = 'companies';

export const getFilteredCompaniesAbortKey = 'getFilteredCompanies';

export const CompanyService = {
  getCompanies(): Promise<CompanyResponse[]> {
    return api({
      method: 'get',
      url: basePath,
      ignoreCache: true,
    });
  },
  getFilteredCompanies(params: GetCompaniesQuery): Promise<CompanyResponse[]> {
    return api({
      method: 'get',
      url: `companies/selector`,
      params,
      abortKey: getFilteredCompaniesAbortKey,
    });
  },
  getCompany(companyId: string, ignoreCache: boolean): Promise<CompanyResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}`,
      ignoreCache,
    });
  },
  createCompany(data: CompanyCreateBody): Promise<CompanyResponse> {
    return api({
      method: 'post',
      url: basePath,
      data,
    });
  },
  updateCompany(companyId: string, data: CompanyUpdateBody): Promise<CompanyResponse> {
    return api({
      method: 'patch',
      url: `${basePath}/${companyId}`,
      data,
    });
  },
  inviteUser(companyId: string, data: InvitationCreateBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invitations`,
      data,
    });
  },
  updateLockDate(companyId: string, date: string | null): Promise<CompanyResponse> {
    return api({
      method: 'post',
      url: `${basePath}/${companyId}/lockdate`,
      data: { lockDate: date },
    });
  },
  revokeUserAccess(companyId: string, userId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/users/${userId}`,
    });
  },
  removeUserInvitation(companyId: string, invitationId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/invitations/${invitationId}`,
    });
  },
  getUsers(companyId: string): Promise<CompanyUserResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/users`,
    });
  },
  getInvitations(companyId: string): Promise<InvitationResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invitations`,
    });
  },
  getAccountingFirms(companyId: string): Promise<CompanyAccountingFirmResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/accounting-firms`,
    });
  },
  revokeAccountantFirmAccess(companyId: string, accountantFirmId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/accounting-firms/${accountantFirmId}`,
    });
  },
};
