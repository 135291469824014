import api from '@/utils/api';
import type {
  BankAccountConsentCreateBody,
  BankAccountConsentStatusResponse,
  BankAccountCreateBody,
  BankAccountCreateConsentResponse,
  BankAccountResponse,
  BankAccountUpdateBody,
  BankInformationResponse,
} from '@/types/schema';

export const BankAccountService = {
  getBankAccounts(companyId: string, ignoreCache = true): Promise<BankAccountResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bankaccounts`,
      ignoreCache,
    });
  },
  getBankAccount(companyId: string, bankAccountId: string, ignoreCache = true): Promise<BankAccountResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bankaccounts/${bankAccountId}`,
      ignoreCache,
    });
  },
  deleteBankAccount(companyId: string, bankAccountId: string, ignoreCache = true): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/bankaccounts/${bankAccountId}`,
      ignoreCache,
    });
  },
  createBankAccount(companyId: string, data: BankAccountCreateBody): Promise<BankAccountResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bankaccounts`,
      data,
    });
  },
  updateBankAccount(
    companyId: string,
    bankAccountId: string,
    data: BankAccountUpdateBody,
  ): Promise<BankAccountResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/bankaccounts/${bankAccountId}`,
      data,
    });
  },
  getBankAccountConsentStatus(companyId: string, bankAccountId: string): Promise<BankAccountConsentStatusResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bankaccounts/${bankAccountId}/consent`,
    });
  },
  createBankAccountConsent(
    companyId: string,
    bankAccountId: string,
    data: BankAccountConsentCreateBody,
  ): Promise<BankAccountCreateConsentResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bankaccounts/${bankAccountId}/consent`,
      data,
    });
  },
  getBankInformationByNumber(bankNumber: string, ignoreCache = true): Promise<BankInformationResponse> {
    return api({
      method: 'get',
      url: `bankinformation/${bankNumber}`,
      ignoreCache,
    });
  },
};
