import { AuthService } from '@/services/authService';
import { SystimaStorage } from '@/utils/systimaStorage';

const localStorageKey = 'vuexAuthModule';
const defaultState = {
  accessToken: null,
  refreshToken: null,
};

const systimaStorage = new SystimaStorage({ localStorageKey, state: defaultState });
const state = systimaStorage.state;
const actions = {
  async login({ dispatch }, payload) {
    const authResponse = await AuthService.login(payload);
    if (authResponse) {
      await dispatch('saveAuth', authResponse);
      await dispatch('getUserAndCompanies', null, { root: true });
    }
  },
  async refreshToken({ dispatch, state }) {
    const authResponse = await AuthService.refreshToken({ refreshToken: state.refreshToken });
    if (authResponse) {
      await dispatch('saveAuth', authResponse);
    }
  },
  async acceptInvitation({ dispatch }, payload) {
    await AuthService.acceptInvitation(payload);
    await dispatch('company/getCompanies', null, { root: true });
  },
  async confirmEmail({ dispatch, state }, payload) {
    await AuthService.confirmEmail(payload);
    if (state.accessToken) {
      await dispatch('getUserAndCompanies', null, { root: true });
    }
  },
  async saveAuth({ commit }, { accessToken, refreshToken }) {
    await commit('set', { accessToken });
    await commit('set', { refreshToken });
  },
  async logout({ dispatch, commit }) {
    await commit('set', { accessToken: null });
    await commit('set', { refreshToken: null });
    await dispatch('resetToDefault', null, { root: true });
  },
};
const mutations = {
  set(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key];
    });
    systimaStorage.saveState(state);
  },
};
const getters = {
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  isLoggedIn: (state, getters) => !!getters.accessToken,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
