<template>
  <div class="loading-overlay" :class="{ dimmed }">
    <v-progress-circular indeterminate color="primary" />
    <div v-if="text" class="text-h6 mt-2">{{ text }}</div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    dimmed: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.loading-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  &.dimmed {
    background-color: rgba(255, 255, 255, 0.75);
  }
}
</style>
