<template>
  <v-card>
    <v-form ref="companyForm" v-model="validCompanyForm" lazy-validation @submit.prevent="registerCompany">
      <v-card-title>Legg til selskap</v-card-title>
      <v-card-text>
        <CompanySearch
          v-if="!showCompanyInputs"
          label="Finn ditt selskap etter navn eller organisasjonsnummer (9 siffer)"
          placeholder="Skriv for å søke i Brønnøysundregistrene"
          @input="selectCompany"
        ></CompanySearch>

        <v-btn
          v-if="!showCompanyInputs"
          class="full-width"
          text
          color="primary"
          @click="
            privatePerson = false;
            showCompanyInputs = true;
            predefineInputs();
          "
        >
          {{ notRegisteredButtonText ? notRegisteredButtonText : 'Jeg er ikke registrert i Foretaksregisteret' }}
        </v-btn>
        <v-btn
          v-if="!showCompanyInputs"
          class="full-width mt-2"
          text
          color="primary"
          @click="
            privatePerson = true;
            showCompanyInputs = true;
            predefineInputs();
          "
        >
          {{ privatePersonButtonText ? privatePersonButtonText : 'Jeg ønsker å registrere meg som privatperson' }}
        </v-btn>

        <v-expand-transition>
          <div v-if="showCompanyInputs">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-if="showCompanyInputs"
                  v-model="inputs.name"
                  :label="privatePerson ? 'Fullt navn *' : 'Foretaksnavn *'"
                  :rules="rules.name"
                ></v-text-field>
              </v-col>
              <v-col v-if="!privatePerson" cols="6">
                <v-select
                  v-model="inputs.type"
                  :label="'Selskapsform' + (false ? ' (Låst)' : ' *')"
                  :items="options.type"
                  :rules="rules.companyType"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="inputs.addressLine1"
                  label="Adresselinje 1 *"
                  :rules="rules.address"
                  autocomplete="address-line1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="inputs.addressLine2"
                  label="Adresselinje 2"
                  autocomplete="address-line2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="inputs.postalCode"
                  label="Postnummer *"
                  :rules="norwegianRules ? rules.postalCode : rules.postalCodeNonNorwegian"
                  autocomplete="postal-code"
                  @input="postalCodeInput"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="inputs.city"
                  label="Sted *"
                  :rules="rules.city"
                  autocomplete="address-level2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <CountrySelect
                  v-model="inputs.country"
                  label="Land *"
                  autocomplete="country"
                  :rules="rules.country"
                ></CountrySelect>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="inputs.email"
                  :rules="rules.emailRequired"
                  label="E-post *"
                  autocomplete="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="inputs.phone"
                  :rules="rules.phoneRequired"
                  label="Telefon nr. *"
                  autocomplete="phone"
                ></v-text-field>
              </v-col>
              <v-col v-if="!privatePerson" cols="6">
                <v-select
                  v-model="inputs.vatPaymentFrequency"
                  :items="options.vatPaymentFrequencies"
                  :rules="rules.vatPaymentFrequency"
                  label="Hvor ofte skal firmaet levere mva. melding? *"
                ></v-select>
              </v-col>
            </v-row>
            <div
              v-if="
                inputs.vatPaymentFrequency &&
                brregSearchedCompany &&
                originalIsVatRegistered !== (inputs.vatPaymentFrequency != 'NONE')
              "
              class="my-4"
            >
              <v-alert dismissible type="warning">
                Selskapet er {{ !originalIsVatRegistered ? 'ikke' : '' }} registrert i mva. registeret
              </v-alert>
            </div>
            <div v-if="error" class="my-4">
              <v-alert type="error">{{ error }}</v-alert>
            </div>
            <div class="d-flex justify-end mt-2">
              <v-btn
                text
                class="mr-2"
                @click="showCompanyInputs ? resetToDefault() : $listeners.cancel ? $emit('cancel') : resetToDefault()"
              >
                Tilbake
              </v-btn>
              <v-btn type="submit" depressed color="primary" :loading="loading" :disabled="loading || !validCompanyForm"
                >Opprett</v-btn
              >
            </div>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import CountrySelect from '@/components/CountrySelect.vue';
import CompanySearch from '@/components/CompanySearch.vue';
import { companyTypes, vatPaymentFrequencies } from '@/utils/types';
import validationRules from '@/utils/validationRules';
import postalCodeSearch from '@/utils/postalCodeSearch';
import { handleError } from '@/utils/shared';

export default {
  components: {
    CompanySearch,
    CountrySelect,
  },
  props: {
    notRegisteredButtonText: {
      type: String,
      default: '',
    },
    privatePersonButtonText: {
      type: String,
      default: '',
    },
    deferred: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    validCompanyForm: false,
    organizationNumber: null,
    inputs: {
      type: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      city: '',
      country: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      terms: false,
      newsletter: false,
      vatPaymentFrequency: null,
    },
    brregSearchedCompany: false,
    originalIsVatRegistered: false,
    options: {
      type: companyTypes,
      vatPaymentFrequencies: [{ value: 'NONE', text: 'Ikke mva. registrert' }, ...vatPaymentFrequencies],
    },
    rules: validationRules,
    loading: false,
    showCompanyInputs: false,
    privatePerson: false,
    originalInputData: {},
    error: '',
  }),
  computed: {
    norwegianRules() {
      return this.inputs.country === 'Norge';
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    user() {
      this.resetToDefault();
    },
    inputs: {
      deep: true,
      handler() {
        this.$emit('hasChangesUpdate', this.hasChanges());
      },
    },
  },
  mounted() {
    this.resetToDefault();
  },
  methods: {
    setOriginalInputData() {
      this.originalInputData = JSON.stringify(this.inputs);
    },
    hasChanges(e) {
      if (JSON.stringify(this.inputs) !== this.originalInputData) {
        if (e) {
          e.preventDefault();
          e.returnValue = '';
        } else {
          return true;
        }
      }
    },
    resetToDefault() {
      this.showCompanyInputs = false;

      this.inputs.type = '';
      this.inputs.sicCode = '';
      this.inputs.addressLine1 = '';
      this.inputs.addressLine2 = '';
      this.inputs.postalCode = '';
      this.inputs.city = '';
      this.inputs.country = 'Norge';
      this.inputs.email = '';
      this.inputs.name = '';
      this.inputs.phone = '';
      this.inputs.password = '';
      this.inputs.passwordConfirm = '';
      this.inputs.terms = false;
      this.inputs.newsletter = false;
      this.inputs.vatPaymentFrequency = null;

      this.error = '';

      this.originalIsVatRegistered = false;
      this.brregSearchedCompany = false;
      this.$refs.companyForm.resetValidation();

      this.setOriginalInputData();
    },
    predefineInputs() {
      if (this.privatePerson && this.user) {
        this.inputs.name = `${this.user.firstName} ${this.user.lastName}`;
        this.inputs.phone = this.user.phone;
        this.inputs.email = this.user.email;
      } else {
        this.inputs.name = '';
        this.inputs.phone = '';
        this.inputs.email = '';
      }
    },
    registerCompany() {
      this.error = '';
      if (this.$refs.companyForm.validate()) {
        this.loading = true;
        const companyData = {
          name: this.inputs.name,
          phone: this.inputs.phone,
          email: this.inputs.email,
          billingEmail: this.inputs.email,
          type: this.privatePerson ? 'PRIVATPERSON' : this.inputs.type,
          addressLine1: this.inputs.addressLine1,
          addressLine2: this.inputs.addressLine2,
          postalCode: this.inputs.postalCode,
          organizationNumber: this.organizationNumber,
          city: this.inputs.city,
          country: this.inputs.country,
          vatPaymentFrequency: this.privatePerson ? 'NONE' : this.inputs.vatPaymentFrequency,
          ...(this.inputs.sicCode ? { sicCode: this.inputs.sicCode } : {}),
        };

        if (!this.deferred) {
          this.$store
            .dispatch('company/createCompany', companyData)
            .then((companyResponse) => {
              this.resetToDefault();
              this.$emit('update', companyResponse);
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              if (err && err.params && err.params.find((e) => e.key === 'postalCode')) {
                this.error = 'Selskapet må registreres med postnummer';
              } else {
                this.error = 'Kunne ikke registrere selskap';
              }
              handleError(err);
            });
        } else {
          this.$emit('update', companyData);
          this.loading = false;
        }
      }
    },
    selectCompany(company) {
      this.privatePerson = false;
      if (typeof company === 'string' || !company) {
        this.inputs.name = company;
        this.inputs.type = null;
        this.inputs.addressLine1 = '';
        this.inputs.addressLine2 = '';
        this.inputs.postalCode = '';
        this.inputs.city = '';
        this.inputs.country = '';
        this.inputs.vatPaymentFrequency = null;
        this.inputs.sicCode = '';

        this.originalIsVatRegistered = false;
        this.brregSearchedCompany = false;
      } else {
        this.organizationNumber = company.organizationNumber;
        this.inputs.name = company.name || '';

        const searchCompanyType = companyTypes.find((type) => type.value === company.type);
        if (searchCompanyType) this.inputs.type = searchCompanyType.value;
        if (company.type && !searchCompanyType) console.error('Unknown company type', company.type);

        this.inputs.sicCode = company.sicCode;
        this.inputs.addressLine1 = company.addressLine1 || '';
        this.inputs.addressLine2 = company.addressLine2 || '';
        this.inputs.postalCode = company.postalCode || '';
        this.inputs.city = company.city || '';
        this.inputs.country = company.country || '';

        this.originalIsVatRegistered = company.isVatRegistered;
        this.brregSearchedCompany = true;

        this.showCompanyInputs = true;

        this.$nextTick(() => {
          this.$refs.companyForm.validate();
        });
      }
    },
    async postalCodeInput(val) {
      if (val && val.length === 4 && this.norwegianRules) {
        const search = await postalCodeSearch(val);
        this.inputs.city = search || '';
      }
    },
  },
};
</script>
