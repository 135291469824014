import type { InboxFileResponse } from '@/types/schema';
import moment from 'moment';

export class InboxFile implements InboxFileResponse {
  id!: string;
  name!: string;
  approved!: boolean;
  fileId!: string;
  uploadedAt!: string;
  deletedAt!: string;
  departmentId!: string;
  paymentCard!: 'PERSONAL' | 'COMPANY';
  projectId!: string;

  private _description!: string;
  private _data: InboxFileResponse;

  get description(): string {
    return this._description || this.name;
  }
  set description(val: string) {
    this._description = val;
  }

  constructor(data: InboxFileResponse) {
    this._data = data;
    Object.assign(this, {
      ...data,
      uploadedAt: moment(data.uploadedAt).format('YYYY-MM-DD'),
      uploadedAtSort: moment(data.uploadedAt).unix(),
    });
  }

  clone(): InboxFile {
    return new InboxFile(this._data);
  }
}
