import api from '@/utils/api';
import type { AuthUrlResponse, GoogleLoginBody } from '@/types/schema';

export const GoogleAuthService = {
  login({ code }: GoogleLoginBody): Promise<AuthUrlResponse> {
    return api({
      method: 'post',
      url: '/google/auth',
      ignoreCache: true,
      data: { code },
    });
  },
};
