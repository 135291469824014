import type { CustomerType } from '@/types';
import type { InvoiceRepetitiveSettingsBody } from '@/types/schema';
import type { ListItems } from '@/types/util';
import constants from '@/utils/constants';

export const dateFormat = 'DD.MM.YYYY';
export const dateTimeFormat = 'DD.MM.YYYY, HH:mm';

export const companyTypes = [
  { value: 'ANS', text: 'ANS: Ansvarlig selskap' },
  { value: 'AS', text: 'AS: Aksjeselskap' },
  { value: 'ASA', text: 'ASA: Allmennaksjeselskap' },
  { value: 'DA', text: 'DA: Delt ansvar' },
  { value: 'ENK', text: 'ENK: Enkeltpersonforetak' },
  { value: 'FLI', text: 'FLI: Forening/lag/innretning' },
  { value: 'KS', text: 'KS: Kommandittselskap' },
  { value: 'NUF', text: 'NUF: Norsk utelandsk foretak' },
  { value: 'OTHER', text: 'OTHER: Annet' },
  { value: 'SA', text: 'SA: Samvirkeforetak' },
  { value: 'SB', text: 'SB: Skolebedrift' },
  { value: 'STI', text: 'Stiftelse' },
  { value: 'UB', text: 'UB: Ungdomsbedrift' },
  { value: 'UTLA', text: 'UTLA: Utenlandsk enhet' },
];

export const productTypes = [
  { text: 'Tjeneste', value: 'TJENESTE' },
  { text: 'Vare (egenprodusert)', value: 'VARE' },
  { text: 'Vare (kjøpt inn for videresalg)', value: 'VARE_VIDERESALG' },
  { text: 'Annen', value: 'ANNEN' },
];

export const customerTypes: CustomerType[] = [
  { text: 'Regnskapsbyrå', value: 'ACCOUNTANT_FIRM' },
  { text: 'Demo bruker', value: 'DEMO' },
  { text: 'Sluttbruker', value: 'COMPANY' },
];

export const vatPaymentFrequencies = [
  { value: 'BIMONTHLY', text: 'Annenhver måned' },
  { value: 'ANNUALLY', text: 'Hvert år' },
];

export const onboardSteps = [
  { value: 'verify_email', text: 'Verifiser e-post' },
  { value: 'add_bank_account', text: 'Legg inn kontonummer' },
  { value: 'init_invoice_number', text: 'Legg inn fakturanummer' },
  { value: 'create_first_invoice', text: 'Lag din første faktura' },
];

export const modules = [
  {
    value: constants.modules.brandingRemoval,
    price: 'Pris: 49 kr / mnd',
    title: 'Legg til egen logo og skjul Systima logo',
    description:
      'Legg til din egen logo og fjern Systima logoen på faktura, kreditnotaer og betalingspåminnelser. Når denne modulen er aktivert legger du til logo på faktura under selskapsinnstillinger.',
  },
];

export const contactTypes = [
  { value: 'ALL', text: 'Alle' },
  { value: 'CUSTOMER', text: 'Kunde' },
  { value: 'SUPPLIER', text: 'Leverandør' },
];

export type SendType = 'ehf' | 'email_link' | 'email_attachment' | 'manual';
export const sendTypes: ListItems<SendType> = [
  { value: 'ehf', text: 'EHF' },
  { value: 'email_attachment', text: 'E-post med PDF vedlegg' },
  { value: 'email_link', text: 'E-post med link' },
  { value: 'manual', text: 'Send manuelt' },
];

export const sendPeriods: ListItems<InvoiceRepetitiveSettingsBody['period']> = [
  { text: 'Dag', value: 'DAY' },
  { text: 'Uke', value: 'WEEK' },
  { text: 'Måned', value: 'MONTH' },
  { text: 'År', value: 'YEAR' },
];
