<template>
  <v-btn
    v-bind="$attrs"
    :disabled="disabled"
    :loading="loading"
    class="pr-0"
    :class="[multiline ? 'v-btn--multiline fill-width' : 'pl-4']"
    depressed
    :outlined="outlined"
    :small="small"
    :color="color"
    @click="$emit('click', $event)"
  >
    <v-icon v-if="icon" left>{{ icon }}</v-icon>
    <span v-html="title" />
    <v-menu offset-y left :nudge-left="nudgeLeft" nudge-bottom="2">
      <template #activator="{ on, attrs }">
        <v-btn
          :id="menuId"
          depressed
          :color="dropdownButtonColor || outlined ? color : ''"
          :small="small"
          style="min-width: 20px"
          :class="[small ? 'ml-0 pa-0 mr-1' : 'ml-2 px-2']"
          v-bind="attrs"
          text
          dark
          v-on="on"
          @click.stop
        >
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <slot></slot>
    </v-menu>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    menuId: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    dropdownButtonColor: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nudgeLeft: {
      type: [String, Number],
      default: '0',
    },
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>
