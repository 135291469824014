<template>
  <v-menu
    v-model="showMenu"
    absolute
    offset-y
    content-class="menu-round"
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn depressed :x-small="isExtraSmall" v-bind="attrs" fab icon v-on="on">
        <v-icon :color="statusColor" dark>
          {{ statusIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card width="400">
      <v-card-text>
        <div class="align-center my-4 d-flex">
          <v-btn depressed fab dark :color="statusColor">
            <v-icon dark>
              {{ statusIcon }}
            </v-icon>
          </v-btn>
          <div class="ml-4">{{ statusText }}</div>
        </div>
        <div class="d-flex justify-end">
          <FeedbackButton text="Gi tilbakemelding"></FeedbackButton>
          <v-btn class="ml-2" depressed color="primary" @click="showMenu = false">Lukk</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import FeedbackButton from '@/modules/FeedbackButton.vue';
import constants from '@/utils/constants';

export default {
  components: {
    FeedbackButton,
  },
  props: {
    isExtraSmall: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
    warning: null, // TODO if variable has a value, show warning (temporary)
  }),
  computed: {
    apiHealthStatus() {
      return this.$store.getters.apiHealthStatus;
    },
    isApiWorks() {
      return this.apiHealthStatus === constants.apiHealthStatusSuccess;
    },
    isWarning() {
      return !!this.warning;
    },
    statusColor() {
      if (!this.isApiWorks) return 'error';
      if (this.isWarning) return 'warning';
      return 'success';
    },
    statusIcon() {
      if (!this.isApiWorks) return 'mdi-close';
      if (this.isWarning) return 'mdi-exclamation';
      return 'mdi-check';
    },
    statusText() {
      if (!this.isApiWorks) return 'Systemet funker ikke som normalt.';
      if (this.isWarning) return this.warning;
      return 'Alle våre systemer funker som normalt.';
    },
  },
  mounted() {
    this.$store.dispatch('healthCheck/healthCheck');
  },
};
</script>
