<template>
  <ResizableSidebar>
    <template v-if="fileId && metadata" #title>
      {{ formatFileName(metadata.name) }}
    </template>

    <template #controls="{ compact }">
      <!-- Upload files from File Inbox -->
      <v-tooltip :disabled="!compact" :open-delay="100" bottom transition="fade-transition">
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              color="primary"
              v-bind="compact ? { icon: true } : { outlined: true, small: true, class: 'mr-1' }"
              @click="openFileInboxDialog"
            >
              <v-icon v-if="compact">mdi-book-plus-outline</v-icon>
              <template v-else>Fil fra filmottak</template>
            </v-btn>
          </div>
        </template>
        <span>Hent fil fra filmottaket som lastes opp som fil til bilaget.</span>
      </v-tooltip>
      <BaseDialog v-model="fileInboxDialog" max-width="1400px">
        <v-card-text>
          <FileInbox v-if="fileInboxDialog" v-model="selectedInboxFiles" attachment-viewer />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="secondary" class="mr-2" @click="fileInboxDialog = false">Avbryt</v-btn>
          <v-tooltip top transition="fade-transition">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  color="primary"
                  :disabled="!selectedInboxFiles.length || isAddingFilesFromInbox"
                  :loading="isAddingFilesFromInbox"
                  @click="addFilesFromFileInbox"
                  >Velg fil(er)</v-btn
                >
              </div>
            </template>
            <span> Velg minst 1 fil å laste opp. </span>
          </v-tooltip>
        </v-card-actions>
      </BaseDialog>

      <!-- Upload files from local machine -->
      <v-btn
        color="primary"
        v-bind="compact ? { icon: true } : { outlined: true, small: true, class: 'mr-1' }"
        @click="openFilesUploadWindow"
      >
        <v-icon v-if="compact">mdi-cloud-upload-outline</v-icon>
        <template v-else>Last opp fil</template>
      </v-btn>
      <input
        ref="filesInput"
        :accept="accept"
        :multiple="maxFiles !== 1"
        type="file"
        class="d-none"
        @change="onFilesInput"
      />

      <!-- File actions -->
      <template v-if="fileId">
        <!-- Delete file -->
        <template v-if="!isFileInbox">
          <v-tooltip :disabled="canDelete" max-width="400" bottom transition="fade-transition">
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn :disabled="!canDelete" icon @click="deleteFileDialog = true">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Det er ikke tillatt å slette dette vedlegget.</span>
          </v-tooltip>
          <BaseDialog v-model="deleteFileDialog" width="500">
            <DeleteAttachmentDialog
              :file-id="fileId"
              :entity-id="entityId"
              :entity-type="entityType"
              @cancel="deleteFileDialog = false"
              @deleted="onFileDeleted"
            />
          </BaseDialog>
        </template>
        <!-- Open file in popup -->
        <v-btn icon @click="openInPopupWindow">
          <v-icon>mdi-dock-window</v-icon>
        </v-btn>
      </template>
    </template>

    <!-- Display the file content, or a dropzone if no file is selected -->
    <template #content>
      <LoadingOverlay v-if="isLoading || isUploading" />
      <PDFPreview v-else-if="file" :raw-data="file" />
      <FileDropzone v-else @update:files="uploadFiles" />
    </template>
  </ResizableSidebar>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { FileDropzone } from '@/components/files';
import { formatFileName } from '@/components/files/utils';
import BaseDialog from '@/components/BaseDialog.vue';
import PDFPreview from '@/components/PDFPreview.vue';
import ResizableSidebar from './ResizableSidebar.vue';
import DeleteAttachmentDialog from './DeleteAttachmentDialog.vue';
import { getTextByError } from '@/utils/errorTextHelper';
import type { Entity } from '@/types';
import type { FileResponse, InboxFileResponse } from '@/types/schema';
import constants from '@/utils/constants';
import FileInbox from '@/modules/file-inbox/FileInbox.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

export default defineComponent({
  components: {
    ResizableSidebar,
    FileDropzone,
    PDFPreview,
    BaseDialog,
    DeleteAttachmentDialog,
    FileInbox,
    LoadingOverlay,
  },
  props: {
    accept: {
      type: String as PropType<string>,
      default: constants.acceptedFiles,
    },
    maxFiles: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  data: () => {
    return {
      isUploading: false,
      isLoading: false,
      deleteFileDialog: false,
      popupWindow: null as WindowProxy | null,
      fileInboxDialog: false,
      isAddingFilesFromInbox: false,
      selectedInboxFiles: [] as InboxFileResponse[],
    };
  },
  computed: {
    file: {
      get() {
        return this.$store.state.attachmentViewer.file as ArrayBuffer | null;
      },
      set(file: ArrayBuffer | null) {
        this.$store.commit('attachmentViewer/SET_FILE', file);
      },
    },
    fileId: {
      get() {
        return this.$route.query.fileId as string | undefined;
      },
      set(fileId: string | undefined) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              fileId,
            },
          })
          .catch(() => {});
      },
    },
    metadata: {
      get() {
        return this.$store.state.attachmentViewer.metadata as FileResponse | null;
      },
      set(metadata: FileResponse | null) {
        this.$store.commit('attachmentViewer/SET_METADATA', metadata);
      },
    },
    entityType(): Entity['entityType'] | undefined {
      const entityTypeSource = this.$route.meta?.entityTypeSource ?? 'routeMeta';
      const entityTypeRef = this.$route.meta?.entityTypeRef;

      const entityType =
        entityTypeSource === 'query' ? this.$route.query[entityTypeRef!] : this.$route.meta?.entityType;

      return entityType as Entity['entityType'] | undefined;
    },
    entityId() {
      const entityIdSource = this.$route.meta?.entityIdSource ?? 'params';
      const entityIdRef = this.$route.meta?.entityIdRef;
      const entityId = entityIdRef && this.$route[entityIdSource]?.[entityIdRef];

      return entityId as Entity['entityId'] | undefined;
    },
    entity(): Entity | undefined {
      return this.entityId && this.entityType
        ? {
            entityId: this.entityId,
            entityType: this.entityType,
          }
        : undefined;
    },
    isFileInbox() {
      return this.$route.name === 'FileInbox';
    },
    isBankReconciliation() {
      return this.$route.name === 'BankReconciliationShow';
    },
    canDelete() {
      return this.entityType !== 'INVOICE';
    },
  },
  watch: {
    fileId: {
      handler(fileId?: string) {
        if (fileId) {
          this.loadFile(fileId);
        } else {
          this.reset();
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    this.$store.dispatch('attachmentViewer/reset');

    if (this.popupWindow) {
      this.popupWindow.close();
      this.$store.commit('theme/setAttachmentViewerMinimized', false);
    }
  },
  methods: {
    formatFileName,
    async loadFile(fileId: string) {
      if (this.metadata?.id === fileId) return;

      try {
        this.isLoading = true;

        this.metadata = await this.$api.file.getFile(this.companyId, fileId);

        // Download the file so we can display it
        this.file = await this.$api.file.downloadFile(this.companyId, this.metadata.id);

        if (this.popupWindow) {
          this.updatePopupWindowFileId(fileId);
        }
      } catch (err: any) {
        this.$store.dispatch('setErrorAlert', getTextByError(err));
        this.$sentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    reset() {
      this.metadata = null;
      this.file = null;
      this.fileId = undefined;
    },

    async uploadFiles(files: File[]) {
      if (!files.length) {
        console.error('[uploadFiles] No files to upload');
        return;
      }

      try {
        this.isUploading = true;

        // If the user is on the bank reconciliation page and uploads a CSV file in an empty attachment viewer,
        // the CSV file will be uploaded as a bank reconciliation file
        if (
          this.isBankReconciliation &&
          !this.fileId &&
          (files[0].type === 'text/csv' || files[0].type === 'text/plain')
        ) {
          const fileMetadata = await this.$api.file.uploadFile(this.companyId, files[0]);
          this.$eventBus.emit('bankRecCSVUploaded', fileMetadata);
          return;
        }

        // Multiple files will be merged into a single pdf on the backend
        this.metadata = await this.$api.file.uploadFiles(this.companyId, files, {
          entity: this.entity,
          fileId: this.fileId,
        });

        // Download the merged file so we can display it
        this.file = await this.$api.file.downloadFile(this.companyId, this.metadata.id);

        // Update the route query to the newly uploaded file
        this.fileId = this.metadata.id;

        // Emit a global event so other components can react to the uploaded file
        this.$eventBus.emit('fileUploaded', this.metadata);

        this.$store.dispatch('setSuccessMessage', 'Fil(er) lagt til');
      } catch (e: any) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
        this.$sentry.captureException(e);
      } finally {
        this.isUploading = false;
      }
    },
    async addFilesFromFileInbox() {
      if (!this.selectedInboxFiles.length) {
        console.error('[uploadFilesFromInbox] No files selected');
        return;
      }

      try {
        this.isAddingFilesFromInbox = true;

        const fileIds = [this.fileId, ...this.selectedInboxFiles.map((i) => i.fileId)].filter(Boolean) as string[];

        // Multiple files will be merged into a single pdf on the backend
        this.metadata = await this.$api.file.mergeFiles(this.companyId, fileIds, this.entity);

        // Download the file so we can display it
        this.file = await this.$api.file.downloadFile(this.companyId, this.metadata.id);

        // Update the route query to the newly uploaded file
        this.fileId = this.metadata.id;

        // Emit a global event so other components can react to the uploaded file
        this.$eventBus.emit('fileUploaded', this.metadata);

        this.fileInboxDialog = false;

        this.$store.dispatch('setSuccessMessage', 'Fil(er) lagt til');
      } catch (e: any) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
        this.$sentry.captureException(e);
      } finally {
        this.isAddingFilesFromInbox = false;
      }
    },

    openFileInboxDialog() {
      this.selectedInboxFiles = [];
      this.fileInboxDialog = true;
    },
    openFilesUploadWindow() {
      const input = this.$refs.filesInput as HTMLInputElement | null;
      input?.click();
    },

    onFilesInput() {
      const input = this.$refs.filesInput as HTMLInputElement | null;
      const files = Array.from(input?.files ?? []);

      if (!files.length) {
        return;
      }

      this.uploadFiles(files);
    },
    onFileDeleted() {
      this.deleteFileDialog = false;
      const deletedFileId = this.fileId;
      this.fileId = undefined;
      this.popupWindow?.close();
      this.$eventBus.emit('fileDeleted', deletedFileId);
    },

    openInPopupWindow() {
      if (!this.fileId) return;

      if (this.popupWindow) {
        this.updatePopupWindowFileId(this.fileId);
        this.popupWindow.focus();
        return;
      }

      const { width, height } = window.screen;
      const { params, query } = this.$route;

      this.popupWindow = window.open(
        `/${params?.companyNameSlug}/previewFile?fileId=${query.fileId}`,
        '',
        `width=${width / 2},height=${height}`,
      );

      this.$store.commit('theme/setAttachmentViewerMinimized', true);

      const interval = setInterval(() => {
        if (this.popupWindow?.closed) {
          clearInterval(interval);
          this.popupWindow = null;
          this.$store.commit('theme/setAttachmentViewerMinimized', false);
        }
      }, 500);
    },
    updatePopupWindowFileId(fileId: string) {
      this.popupWindow?.app.$router.replace({
        query: {
          fileId,
        },
      });
    },
  },
});
</script>
