import api from '@/utils/api';

export const CreditNoteAttachmentService = {
  getCreditNoteAttachments(companyId, invoiceId, creditNoteId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/creditnotes/${creditNoteId}/attachments`,
      ignoreCache,
    });
  },
};
