import api from '@/utils/api';
import type { InvoiceRepetitiveActivationBody, InvoiceRepetitiveBody, InvoiceRepetitiveResponse } from '@/types/schema';

export const InvoiceRepetitiveService = {
  getRepetitiveInvoices(
    companyId: string,
    params?: {
      searchString?: string;
      withNotActive?: string;
      withDraft?: string;
    },
  ): Promise<InvoiceRepetitiveResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoicerepetitives`,
      params,
    });
  },
  getRepetitiveInvoice(companyId: string, invoiceId: string): Promise<InvoiceRepetitiveResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoicerepetitives/${invoiceId}`,
    });
  },
  createRepetitiveInvoice(companyId: string, data: InvoiceRepetitiveBody): Promise<InvoiceRepetitiveResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoicerepetitives`,
      data,
    });
  },
  updateRepetitiveInvoice(
    companyId: string,
    invoiceId: string,
    data: InvoiceRepetitiveBody,
  ): Promise<InvoiceRepetitiveResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/invoicerepetitives/${invoiceId}`,
      data,
    });
  },
  deleteRepetitiveInvoice(companyId: string, invoiceId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/invoicerepetitives/${invoiceId}`,
    });
  },
  toggleRepetitiveInvoiceActive(
    companyId: string,
    invoiceId: string,
    active: boolean,
  ): Promise<InvoiceRepetitiveResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoicerepetitives/${invoiceId}/activate`,
      data: {
        active,
      } as InvoiceRepetitiveActivationBody,
    });
  },
};
