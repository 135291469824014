import { InvoiceCommentService } from '@/services/invoiceCommentService';

const state = {};
const actions = {
  getInvoiceComments(context, { companyId, invoiceId, ignoreCache }) {
    return InvoiceCommentService.getInvoiceComments(companyId, invoiceId, ignoreCache);
  },
  createInvoiceComment(context, { companyId, invoiceId, data }) {
    return InvoiceCommentService.createInvoiceComment(companyId, invoiceId, data);
  },
  updateInvoiceComment(context, { companyId, invoiceId, commentId, data }) {
    return InvoiceCommentService.updateInvoiceComment(companyId, invoiceId, commentId, data);
  },
  deleteInvoiceComment(context, { companyId, invoiceId, commentId }) {
    return InvoiceCommentService.deleteInvoiceComment(companyId, invoiceId, commentId);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
