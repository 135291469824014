import type { AccountGroupResponse, AccountGroupSuggestionResponse } from '@/types/schema';
import api from '@/utils/api';

export const AccountGroupService = {
  fetchGroups(companyId: string): Promise<AccountGroupResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/account/group/list`,
    });
  },
  fetchGroupSuggestions(companyId: string): Promise<AccountGroupSuggestionResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/account/group/suggestions`,
    });
  },
};
