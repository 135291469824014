import { chain } from 'mathjs';

export function toPercentage(val: number | undefined, { decimals = 2, hideZero = false } = {}): string {
  if (!val) {
    if (hideZero) return '';

    return decimals ? `0,${'0'.repeat(decimals)} %` : '0 %';
  }

  return (
    chain(val)
      .multiply(100)
      .round(2)
      .done()
      .toFixed(decimals)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      .replace('.', ',') + ' %'
  );
}

export const formatCurrency = (val: string) => (val === 'NOK' ? 'kr' : val);
