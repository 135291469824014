export const isYearAliasRegexp = /^(19|20)\d{2}$/;
/**
 * Match text like "2022", "2023", "1999", etc.
 *
 * Rules:
 * - Must be exactly 4 digits.
 * - Starts with "19" or "20".
 */
export function isYearAlias(alias: string) {
  return isYearAliasRegexp.test(alias);
}

/**
 * Capture groups:
 * - `month`: Month abbreviation ("Jan", "Feb", etc.)
 * - `year`: Year (if present, either 2 or 4 digits)
 */
export const isMonthAliasRegexp =
  /^(?<month>Jan|Feb|Mar|Apr|Mai|Jun|Jul|Aug|Sep|Okt|Nov|Des)(?:\w*)?(?:(?:\s+)(?<year>\d{4}|\d{2}))?$/i;
/**
 * Match text like "Jan", "Jan 2022", "Janasdasdasd 2022", "Feb 22", etc.
 *
 * Case insensitive.
 *
 * Rules:
 * - Start with one of the specified month abbreviations.
 * - Optionally followed by any word-like characters.
 * - Optionally followed by a space and then 2 or 4 digits.
 */
export function isMonthAlias(alias: string) {
  return isMonthAliasRegexp.test(alias);
}

/**
 * Capture groups:
 * - `weekPair`: Week pair ("1-2", "3-4", etc.)
 * - `year`: Year (if present, either 2 or 4 digits)
 */
export const isWeeksDoubleAliasRegexp =
  /^Uke (?<weekPair>1-2|3-4|5-6|7-8|9-10|11-12|13-14|15-16|17-18|19-20|21-22|23-24|25-26|27-28|29-30|31-32|33-34|35-36|37-38|39-40|41-42|43-44|45-46|47-48|49-50|51-52)(?:(?:\s+)(?<year>\d{4}|\d{2}))?$/i;
/**
 * Match text like "Uke 1-2", "Uke 1-2 2022", "Uke 1-2 22", etc.
 *
 * Case insensitive.
 *
 * Rules:
 * - Start with "Uke".
 * - Followed by a space.
 * - Followed by a week pair in the format "1-2".
 * - Optionally followed by a space and then 2 or 4 digits.
 */
export function isWeeksDoubleAlias(alias: string) {
  return isWeeksDoubleAliasRegexp.test(alias);
}

/**
 * Capture groups:
 * - `week`: Week number
 * - `year`: Year (if present, either 2 or 4 digits)
 */
export const isSingleWeekAliasRegexp = /^Uke (?<week>[1-9]|[1-4][0-9]|5[0-2])(?:(?:\s+)(?<year>\d{4}|\d{2}))?$/i;
/**
 * Match text like "Uke 1", "Uke 1 2022", "Uke 1 22", etc.
 *
 * Case insensitive.
 *
 * Rules:
 * - Start with "Uke".
 * - Followed by a space.
 * - Followed by a week number between 1-52.
 * - Optionally followed by a space and then 2 or 4 digits.
 */
export function isSingleWeekAlias(alias: string) {
  return isSingleWeekAliasRegexp.test(alias);
}

/**
 * Capture groups:
 * - `period`: Period number
 * - `year`: Year (if present, either 2 or 4 digits)
 */
export const isPeriodAliasRegexp = /^Termin (?<period>[1-6])(?:(?:\s+)(?<year>\d{4}|\d{2}))?$/i;
/**
 * Match text like "Termin 1", "Termin 1 2022", "Termin 1 22", etc.
 *
 * Case insensitive.
 *
 * Rules:
 * - Start with "Termin".
 * - Followed by a space.
 * - Followed by a number between 1-6.
 * - Optionally followed by a space and then 2 or 4 digits.
 */
export function isPeriodAlias(alias: string) {
  return isPeriodAliasRegexp.test(alias);
}

/**
 * Capture groups:
 * - `from`: From date (4, 6 or 8 digits)
 * - `fromDay`: From Day (2 digits)
 * - `fromMonth`: From Month (2 digits)
 * - `fromYear`: From Year (if present, either 2 or 4 digits)
 * - `to`: To date (4, 6 or 8 digits)
 * - `toDay`: To Day (2 digits)
 * - `toMonth`: To Month (2 digits)
 * - `toYear`: To Year (if present, either 2 or 4 digits)
 */
export const isDateIntervalAliasRegexp =
  /^(?<from>(?<fromDay>\d{2})(?<fromMonth>\d{2})(?<fromYear>\d{2}|\d{4})?)-(?<to>(?<toDay>\d{2})(?<toMonth>\d{2})(?<toYear>\d{2}|\d{4})?)$/;
/**
 * Match text like:
 * - 0101 - 0101
 * - 01 01 23 - 02 02 23
 * - 01.01 - 02.02
 * - 01.01.2023-02.02.2023
 * - etc.
 *
 * Any non-digit characters (except "-") are ignored.
 *
 * Rules:
 * - Must include 2 sides separated by a single "-" character.
 * - Sides must be equal length and exactly 4, 6 or 8 digits.
 */
export function isDateIntervalAlias(alias: string) {
  // Remove all non-digit characters, except "-".
  const cleanedInput = alias.replace(/[^\d-]/g, '');

  const dates = cleanedInput.match(isDateIntervalAliasRegexp);

  if (!dates) return false;

  const dateFrom = dates.groups?.from;
  const dateTo = dates.groups?.to;

  if (dateFrom?.length !== dateTo?.length) return false;

  return true;
}

/**
 * Capture groups:
 * - `day`: Day (2 digits)
 * - `month`: Month (2 digits)
 * - `year`: Year (if present, either 2 or 4 digits)
 */
export const isDateAliasRegexp = /^(?<day>\d{2})(?<month>\d{2})(?<year>\d{2}|\d{4})?$/;
/**
 * Match text like "0101", "010222", "01022022", etc.
 *
 * Any non-digit characters are ignored.
 *
 * Rules:
 * - Must be exactly 4, 6 or 8 digits.
 */
export function isDateAlias(alias: string) {
  // Remove all non-digit characters.
  const cleanedInput = alias.replace(/\D/g, '');

  return isDateAliasRegexp.test(cleanedInput);
}
