import { CompanyModuleService } from '@/services/companyModuleService';
import { handleError } from '@/utils/shared';

const getDefaultState = () => ({
  modules: [],
});

const state = getDefaultState();

const actions = {
  fetchModules({ commit }, { companyId, ignoreCache = true }) {
    CompanyModuleService.getModules(companyId, ignoreCache)
      .then((response) => {
        commit('setModules', response);
      })
      .catch(handleError);
  },
  activateModule({ commit }, { companyId, id }) {
    CompanyModuleService.activateModule(companyId, id).then(() => {
      commit('activateModule', { id });
    });
  },
  async deactivateModule({ commit }, { companyId, id }) {
    await CompanyModuleService.deactivateModule(companyId, id);
    commit('deactivateModule', { id });
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setModules(state, modules) {
    state.modules = modules;
  },
  activateModule(state, payload) {
    const module = state.modules.find((module) => module.id === payload.id);
    module.enabled = true;
  },
  deactivateModule(state, payload) {
    const module = state.modules.find((module) => module.id === payload.id);
    module.enabled = false;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getModules(state) {
    return state.modules;
  },
  findModuleByName: (state) => (moduleName) => state.modules.find((module) => module.name === moduleName),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
