<template>
  <div class="pa-4">
    <vue-dropzone
      id="dropzone"
      ref="dropZone"
      class="mt-10"
      :use-custom-slot="true"
      :options="dropzoneOptions"
      @vdropzone-files-added="updateQueuedFiles"
    >
      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title">Dra og slipp fil(er) her</h3>
        <div class="subtitle">eller <span class="text-decoration-underline">klikk for å velge fil(er)</span></div>
      </div>
    </vue-dropzone>

    <v-row v-if="files.length">
      <v-col cols="9"></v-col>
      <v-col cols="3" class="d-flex justify-center align-center">Godkjenn</v-col>
    </v-row>
    <div v-for="(file, index) in files" :key="`file-${index}`">
      <v-row>
        <v-col cols="9" class="fs-14">
          {{ file.name }}
          <v-text-field v-model="files[index].description" label="Beskrivelse"></v-text-field>
        </v-col>
        <v-col class="d-flex justify-center align-center" cols="3">
          <v-checkbox v-model="files[index].approved" />
        </v-col>
      </v-row>
    </div>

    <v-row v-if="alert.show">
      <v-col cols="12">
        <v-alert :type="alert.type" icon="mdi-alert" dense
          >En eller flere filetyper støttes ikke. Last opp gyldige filtyper.</v-alert
        >
      </v-col>
    </v-row>

    <div class="d-flex justify-end mt-4">
      <v-btn class="ml-2" color="secondary" text @click="$emit('close', [])">Avbryt</v-btn>
      <v-btn
        type="submit"
        class="ml-2"
        depressed
        color="primary"
        :loading="uploadInProgress"
        :disabled="uploadInProgress || files.length === 0"
        @click="uploadFiles"
      >
        Last opp</v-btn
      >
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import constants from '@/utils/constants';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    selectedFiles: {
      type: Array,
      default: () => [],
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dropzoneOptions: {
      autoProcessQueue: false,
      previewsContainer: false,
      maxFilesize: 20, // MB,
      acceptedFiles: constants.acceptedFiles,
      url: '/',
    },
    files: [],
    alert: {
      show: false,
      type: null,
    },
    uploadInProgress: false,
  }),
  mounted() {
    this.files = this.selectedFiles.map((file) => {
      file.approved = file.approved ?? true;
      return file;
    });

    if (this.warning) {
      this.alert = {
        show: true,
        type: 'warning',
      };
    }
  },
  methods: {
    updateQueuedFiles(files) {
      this.$nextTick(() => {
        const failedCount = Array.from(files).filter(({ status }) => status === 'error').length;

        if (failedCount) {
          this.alert.show = true;

          if (failedCount === files.length) {
            this.alert.type = 'error';
          } else {
            this.alert.type = 'warning';
          }
        } else {
          this.alert.show = false;
        }

        const queuedFiles = this.$refs?.dropZone?.getQueuedFiles();
        queuedFiles.map((file) => {
          file.approved = file.approved ?? true;
          return file;
        });
        this.files = this.selectedFiles.concat(queuedFiles);
      });
    },
    async uploadFiles() {
      if (this.files.length) {
        const queue = [];

        this.uploadInProgress = true;

        for (const file of this.files) {
          const res = this.$api.fileInbox.upload(this.companyId, file);
          queue.push(res);
        }

        const uploaded = await Promise.all(queue);

        this.uploadInProgress = false;

        this.$emit('close', uploaded);
      }
    },
  },
};
</script>
