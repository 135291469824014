import { ImportProductsService } from '@/services/importProductsService';

const state = {};
const actions = {
  import(context, { companyId, file }) {
    return ImportProductsService.import(companyId, file);
  },
  downloadTemplate() {
    return ImportProductsService.downloadTemplate();
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
