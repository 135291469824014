import api from '@/utils/api';

import type { EntitySuggestionType } from '@/types';
import type { BookkeepingSuggestionResponse } from '@/types/schema';

export const BookkeepingSuggestionService = {
  getBookkeepingSuggestion(
    companyId: string,
    params: {
      metaEntityType: EntitySuggestionType;
      contactId: string;
    },
  ): Promise<BookkeepingSuggestionResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bookkeeping_suggestion`,
      params,
    });
  },
};
