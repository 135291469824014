export function getAccountClass(accountNumber) {
  return accountNumber.toString().charAt(0);
}

export function getAccountGroup(accountNumber) {
  return accountNumber.toString().substring(0, 2);
}

export function formatAccountNumber(account) {
  let accountFullNumber = `${account.number}`;
  if (account.subNumber) {
    accountFullNumber += `:${account.subNumber}`;
  }
  return accountFullNumber;
}

export function extendVAT(VAT) {
  const rateInPercents = (VAT.rate * 100).toFixed(2);
  const description = VAT.description ? `${VAT.description}: ` : '';
  const percentage = VAT.parentVatId
    ? `, ${(Number(VAT.proportionalPercentage) * 100).toFixed(2)}% forholdsmessig mva`
    : '';

  return {
    ...VAT,
    VATText: `${description}${VAT.code} (${rateInPercents}%) ${VAT.name}${percentage}`,
  };
}

export function searchAndSort(searchWord, data, onlyBy) {
  if (searchWord) {
    data = data
      .filter((account) => {
        return account.searchboxTitle.toLowerCase().includes(searchWord);
      })
      .sort((a, b) => {
        // Sort data by matching name with keyword position in name
        if (a.searchboxTitle.toLowerCase().indexOf(searchWord) > b.searchboxTitle.toLowerCase().indexOf(searchWord)) {
          return 1;
        } else if (
          a.searchboxTitle.toLowerCase().indexOf(searchWord) < b.searchboxTitle.toLowerCase().indexOf(searchWord)
        ) {
          return -1;
        } else {
          if (a.searchboxTitle > b.searchboxTitle) return 1;
          return -1;
        }
      });
  }
  if (onlyBy) {
    data = data.filter((account) => {
      return account.searchboxTitle.toLowerCase().indexOf(onlyBy) === 0;
    });
  }
  return data;
}
