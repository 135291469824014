import { BankIntegrationService } from '@/services/bankIntegrationService';

const getDefaultState = () => ({
  companyIsOnboarded: false,
});

const state = getDefaultState();

const actions = {
  getCompanyOnboardingStatus({ commit }, { companyId }) {
    BankIntegrationService.getCompanyOnboardingStatus(companyId).then((response) => {
      if (response) {
        commit('setCompanyOnboardingStatus', response.onboarded);
      }
    });
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setCompanyOnboardingStatus(state, onboardingStatus) {
    state.companyIsOnboarded = onboardingStatus;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getCompanyOnboardingStatus(state) {
    return state.companyIsOnboarded;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
