// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.blue,
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.orange,
        info: colors.indigo,
        orange: '#ffc107',
        dark: '#242939',
        background: '#f2f3f8',
      },
    },
  },
});
