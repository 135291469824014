import api from '@/utils/api';
import type {
  UserResponse,
  SystimaEmployeeInviteRequest,
  SystimaCustomerResponse,
  SystimaInvitationBody,
} from '@/types/schema';
import type { EmployeeInvitationsNonUniqueEmails } from '@/services/accountingFirmService';

export const ControlPanelService = {
  getSystimaEmployees(): Promise<UserResponse[]> {
    return api({
      method: 'get',
      url: 'systima_employees',
    });
  },
  createSystimaEmployee(data: SystimaEmployeeInviteRequest): Promise<void> {
    return api({
      method: 'post',
      url: `systima_employees`,
      data,
    });
  },
  getSystimaCustomers(): Promise<SystimaCustomerResponse[]> {
    return api({
      method: 'get',
      url: 'systima_customers',
    });
  },
  createSystimaCustomers(data: SystimaInvitationBody): Promise<void | EmployeeInvitationsNonUniqueEmails> {
    return api({
      method: 'post',
      url: `systima_customers`,
      data,
    });
  },
};
