<template>
  <v-date-picker
    ref="datePickerRef"
    :active-picker.sync="activePicker"
    first-day-of-week="1"
    locale="no"
    show-adjacent-months
    class="date-picker"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

type ActivePicker = 'DATE' | 'MONTH' | 'YEAR';

const helperText: Record<ActivePicker, string> = {
  DATE: 'Klikk for måned og år',
  MONTH: 'Klikk for år',
  YEAR: '',
};

export default defineComponent({
  data: () => ({
    activePicker: null as ActivePicker | null,
  }),
  watch: {
    activePicker: {
      handler(currentPicker: ActivePicker | null) {
        if (currentPicker && currentPicker !== 'YEAR') {
          this.$nextTick(() => {
            this.attachHelperText(currentPicker);
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    attachHelperText(currentPicker: Exclude<ActivePicker, 'YEAR'>) {
      const containerClass = 'date-picker-helper-text';
      const datePickerRef = this.$refs.datePickerRef as any;

      // Already attached, just update text
      const existingContainer = datePickerRef.$el.querySelector(containerClass);
      if (existingContainer) {
        existingContainer.innerText = helperText[currentPicker];
        return;
      }

      const table = datePickerRef.$refs.table.$el as HTMLElement;
      const container = document.createElement('div');
      const link = document.createElement('a');
      link.innerText = helperText[currentPicker];
      link.onclick = () => {
        this.activePicker = currentPicker === 'DATE' ? 'MONTH' : 'YEAR';
      };
      container.classList.add(containerClass);
      container.appendChild(link);
      table.parentNode?.insertBefore(container, table);
    },
  },
});
</script>

<style lang="scss" scoped>
.date-picker::v-deep {
  .v-date-picker-header {
    padding-bottom: 0;
  }
  .date-picker-helper-text {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
  }
}
</style>
