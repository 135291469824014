import api from '@/utils/api';
import type { DepartmentCreateBody, DepartmentResponse, DepartmentUpdateBody } from '@/types/schema';

export const DepartmentService = {
  getDepartments(companyId: string): Promise<DepartmentResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/departments`,
    });
  },
  createDepartment(companyId: string, data: DepartmentCreateBody): Promise<DepartmentResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/departments`,
      data,
    });
  },
  updateDepartment(companyId: string, departmentId: string, data: DepartmentUpdateBody): Promise<DepartmentResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/departments/${departmentId}`,
      data,
    });
  },
};
