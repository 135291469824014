import api from '@/utils/api';
import { getProductTypeText } from '@/utils/shared';

function extendProduct(product) {
  return {
    ...product,
    productTypeText: getProductTypeText(product.type),
  };
}

function mapResponse(response) {
  if (Array.isArray(response)) {
    const extended = response.map(extendProduct);
    if (response.fromCache) extended.fromCache = true;
    return extended;
  } else {
    return extendProduct(response);
  }
}

export const CompanyProductService = {
  getProducts(companyId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/products`,
      ignoreCache,
    }).then(mapResponse);
  },
  getProduct(companyId, productId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/products/${productId}`,
      ignoreCache,
    }).then(mapResponse);
  },
  createProduct(companyId, data) {
    return api({
      method: 'post',
      url: `companies/${companyId}/products`,
      data,
    }).then(mapResponse);
  },
  updateProduct(companyId, productId, data) {
    return api({
      method: 'patch',
      url: `companies/${companyId}/products/${productId}`,
      data,
    }).then(mapResponse);
  },
  getProductTypes(companyId) {
    return api({
      method: 'get',
      url: `companies/${companyId}/product_types`,
    }).then(mapResponse);
  },
};
