import api from '@/utils/api';

const basePath = 'users/me';

export const ProfileService = {
  getProfile(ignoreCache) {
    return api({
      method: 'get',
      url: `${basePath}/profile`,
      ignoreCache,
    });
  },
  updateProfile(data) {
    return api({
      method: 'patch',
      url: `${basePath}/profile`,
      data,
    });
  },
  updateUser(data) {
    return api({
      method: 'patch',
      url: basePath,
      data,
    });
  },
};
