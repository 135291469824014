import {
  addWeeks,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  format,
  startOfISOWeek,
  startOfISOWeekYear,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import {
  isDateAliasRegexp,
  isDateIntervalAlias,
  isDateIntervalAliasRegexp,
  isMonthAliasRegexp,
  isPeriodAliasRegexp,
  isSingleWeekAliasRegexp,
  isWeeksDoubleAliasRegexp,
  isYearAliasRegexp,
} from './alias-regexp';
import { isoFormat, monthAbbrs } from '../constants';
import type { DateInterval } from '../types';
import { parseYear } from './parse-year';

/**
 * Parse a year alias and return a date interval.
 *
 * @param alias "2022", "2023", "1999", etc.
 * @throws Error if input is not a valid year alias.
 */
export function parseYearAlias(alias: string): DateInterval {
  const match = isYearAliasRegexp.exec(alias);

  if (!match) {
    throw new Error(`Invalid year alias: "${alias}"`);
  }

  const year = parseYear(match.input);

  const dateFrom = format(startOfYear(new Date(year, 0, 1)), isoFormat);
  const dateTo = format(endOfYear(new Date(year, 0, 1)), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Parse a month alias and return a date interval.
 *
 * @param alias "Jan", "Feb 2022", etc.
 * @throws Error if input is not a valid month alias.
 */
export function parseMonthAlias(alias: string): DateInterval {
  const groups = isMonthAliasRegexp.exec(alias)?.groups;

  if (!groups) {
    throw new Error(`Invalid month alias: "${alias}"`);
  }

  const year = groups.year ? parseYear(groups.year) : new Date().getFullYear();
  const monthIndex = monthAbbrs.indexOf(groups.month.toLowerCase());

  const dateFrom = format(startOfMonth(new Date(year, monthIndex, 1)), isoFormat);
  const dateTo = format(endOfMonth(new Date(year, monthIndex, 1)), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Parse a weeks pair alias and return a date interval.
 *
 * @param alias "Uke 1-2", "Uke 5-6 2022", etc.
 * @throws Error if input is not a valid weeks double alias.
 */
export function parseWeeksDoubleAlias(alias: string): DateInterval {
  const groups = isWeeksDoubleAliasRegexp.exec(alias)?.groups;

  if (!groups) {
    throw new Error(`Invalid weeks double alias: "${alias}"`);
  }

  const year = groups.year ? parseYear(groups.year) : new Date().getFullYear();
  const [week1Index, week2Index] = groups.weekPair.split('-').map((week) => parseInt(week) - 1);

  const firstIsoDayOfYear = startOfISOWeekYear(new Date(year, 5, 5));
  const weekFrom = addWeeks(firstIsoDayOfYear, week1Index);
  const weekTo = addWeeks(firstIsoDayOfYear, week2Index);

  const dateFrom = format(startOfISOWeek(weekFrom), isoFormat);
  const dateTo = format(endOfISOWeek(weekTo), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Parse a single week alias and return a date interval.
 *
 * @param alias "Uke 1", "Uke 6 2022", etc.
 * @throws Error if input is not a valid single week alias.
 */
export function parseSingleWeekAlias(alias: string): DateInterval {
  const groups = isSingleWeekAliasRegexp.exec(alias)?.groups;

  if (!groups) {
    throw new Error(`Invalid single week alias: "${alias}"`);
  }

  const year = groups.year ? parseYear(groups.year) : new Date().getFullYear();
  const weekIndex = parseInt(groups.week) - 1;

  const firstIsoDayOfYear = startOfISOWeekYear(new Date(year, 5, 5));
  const week = addWeeks(firstIsoDayOfYear, weekIndex);

  const dateFrom = format(startOfISOWeek(week), isoFormat);
  const dateTo = format(endOfISOWeek(week), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Parse a period alias and return a date interval.
 *
 * @param alias "Termin 1", "Termin 2 2022", etc.
 * @throws Error if input is not a valid period alias.
 */
export function parsePeriodAlias(alias: string): DateInterval {
  const groups = isPeriodAliasRegexp.exec(alias)?.groups;

  if (!groups) {
    throw new Error(`Invalid period alias: "${alias}"`);
  }

  const year = groups.year ? parseYear(groups.year) : new Date().getFullYear();
  const periodIndex = parseInt(groups.period) - 1;

  const dateFrom = format(startOfMonth(new Date(year, periodIndex * 2, 1)), isoFormat);
  const dateTo = format(endOfMonth(new Date(year, periodIndex * 2 + 1, 1)), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Parse a date interval alias and return a date interval.
 *
 * @param alias "0101 - 0202", "020222-030323", "01.02.23 - 22.02.23", etc.
 * @throws Error if input is not a valid date interval alias.
 */
export function parseDateIntervalAlias(alias: string): DateInterval {
  const _alias = alias.replace(/[^\d-]/g, '');
  const groups = isDateIntervalAliasRegexp.exec(_alias)?.groups;

  // Guarantees that both sides of the interval are the same length (4, 6 or 8 digits).
  if (!groups || !isDateIntervalAlias(_alias)) {
    throw new Error(`Invalid date interval alias: "${_alias}"`);
  }

  const currentYear = new Date().getFullYear();

  const fromYear = groups.fromYear ? parseYear(groups.fromYear) : currentYear;
  const fromMonth = groups.fromMonth;
  const fromDay = groups.fromDay;

  const toYear = groups.toYear ? parseYear(groups.toYear) : currentYear;
  const toMonth = groups.toMonth;
  const toDay = groups.toDay;

  const dateFrom = `${fromYear}-${fromMonth}-${fromDay}`;
  const dateTo = `${toYear}-${toMonth}-${toDay}`;

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Parse a date alias and return YYYY-MM-DD formatted date.
 *
 * @param alias "0101", "020222", "01.02.23", etc.
 * @throws Error if input is not a valid date alias.
 */
export function parseDateAlias(alias: string): string {
  const _alias = alias.replace(/\D/g, '');
  const groups = isDateAliasRegexp.exec(_alias)?.groups;

  if (!groups) {
    throw new Error(`Invalid date alias: "${_alias}"`);
  }

  const currentYear = new Date().getFullYear();

  const year = groups.year ? parseYear(groups.year) : currentYear;
  const month = groups.month;
  const day = groups.day;

  return `${year}-${month}-${day}`;
}
