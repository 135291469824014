import { InboxFile } from '@/modules/accounting/models/InboxFile';
import type { InboxFileResponse, InboxFileUpdateBody } from '@/types/schema';
import api from '@/utils/api';
import { flatten } from 'lodash-es';

export const FileInboxService = {
  async fetch(companyId: string, startDate?: string, endDate?: string): Promise<InboxFile[]> {
    const res: InboxFileResponse[] = await api({
      method: 'get',
      url: `companies/${companyId}/inbox_files`,
      ignoreCache: true,
      params: {
        startDate,
        endDate,
      },
    });

    return res.map((item: InboxFileResponse) => new InboxFile(item));
  },
  async upload(companyId: string, fileObject: any): Promise<InboxFile> {
    const res: InboxFileResponse = await api({
      method: 'post',
      url: `companies/${companyId}/inbox_files`,
      file: fileObject,
      description: fileObject.description,
      approved: fileObject.approved,
    });

    return new InboxFile(res);
  },
  async update(companyId: string, fileId: string, payload: InboxFileUpdateBody): Promise<InboxFile> {
    const res: InboxFileResponse = await api({
      method: 'patch',
      url: `companies/${companyId}/inbox_files/${fileId}`,
      data: payload,
    });

    return new InboxFile(res);
  },
  delete(companyId: string, fileId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/inbox_files/${fileId}`,
    });
  },
  async split(companyId: string, inboxFileIds: string[]): Promise<InboxFile[]> {
    const queue: Promise<InboxFileResponse[]>[] = [];

    for (const id of inboxFileIds) {
      const req: Promise<InboxFileResponse[]> = api({
        method: 'patch',
        url: `companies/${companyId}/inbox_files/${id}/split`,
      });

      queue.push(req);
    }

    const res = await Promise.all(queue);

    return flatten(res).map((item) => new InboxFile(item));
  },
  async merge(companyId: string, inboxFileIds: string[]): Promise<InboxFile> {
    const res: InboxFileResponse = await api({
      method: 'PATCH',
      url: `companies/${companyId}/inbox_files/merge`,
      data: { inboxFileIds },
    });

    return new InboxFile(res);
  },
};
