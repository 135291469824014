import api from '@/utils/api';
import { getContactWarning } from '@/utils/contactHelper';

function extendExternalCompany(externalCompany) {
  return {
    ...externalCompany,
    id: externalCompany.organizationNumber,
    description: `${externalCompany.name} (${externalCompany.organizationNumber})`,
    warning: getContactWarning(externalCompany),
    isExternal: true,
  };
}

function mapFindExternalResponse(response) {
  if (Array.isArray(response)) {
    const extended = response.map(extendExternalCompany);
    if (response.fromCache) extended.fromCache = true;
    return extended;
  } else {
    return extendExternalCompany(response);
  }
}

export const LookupService = {
  lookupContacts(data) {
    return api({
      method: 'get',
      url: 'lookup/contacts',
      params: data,
    }).then(mapFindExternalResponse);
  },
};
