import { FeedbackService } from '@/services/feedbackService';

const state = {};
const actions = {
  sendFeedback(context, { message, email, page }) {
    return FeedbackService.sendFeedback({
      message: `${message} Email: ${email} Page: ${page}`,
    });
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
