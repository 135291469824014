import { CompanyAuditLogService } from '@/services/companyAuditLogService';

const state = {};
const actions = {
  getAuditLog(context, { companyId, ignoreCache }) {
    return CompanyAuditLogService.getAuditLog(companyId, ignoreCache);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
