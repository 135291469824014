import * as Sentry from '@sentry/vue';
import { AccountGroupService } from '@/services/accountGroupService';

const getDefaultState = () => ({
  groups: [],
  suggestions: [],
  loading: false,
});

const state = getDefaultState();

const actions = {
  async fetchGroups({ commit }, { companyId }) {
    try {
      commit('SET_LOADING', true);
      const groups = await AccountGroupService.fetchGroups(companyId);
      commit('SET_GROUPS', groups);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchSuggestions({ commit }, { companyId }) {
    try {
      commit('SET_LOADING', true);
      const suggestions = await AccountGroupService.fetchGroupSuggestions(companyId);
      commit('SET_SUGGESTIONS', suggestions);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },
  SET_SUGGESTIONS(state, suggestions) {
    state.suggestions = suggestions;
  },
  SET_LOADING(state, bool) {
    state.loading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
