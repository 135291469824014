const getDefaultState = () => ({
  params: {},
  module: null,
});

const state = getDefaultState();

const getters = {
  params(state) {
    return state.params;
  },
  module(state) {
    return state.module;
  },
};

const actions = {
  setParams({ commit }, payload) {
    commit('SET_PARAMS', payload);
  },
  setModule({ commit }, { module }) {
    commit('SET_MODULE', module);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_PARAMS(state, params) {
    state.params = params;
  },
  SET_MODULE(state, module) {
    state.module = module;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
