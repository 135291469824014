<template>
  <div>
    <v-select
      ref="select"
      v-bind="$attrs"
      v-model="company"
      :items="companies"
      :menu-props="{ contentClass: 'company-selector-menu' }"
      :label="label"
      item-text="name"
      item-value="id"
      return-object
      no-data-text="Ingen selskap"
      class="company-selector"
    >
      <template #prepend-item>
        <div class="prepend-sticky d-flex align-center white px-4 pt-4 pb-3">
          <v-text-field v-model="filterByName" autofocus clearable dense hide-details label="Søk" />
          <v-btn depressed color="primary" class="ml-3" @click="handleNewCompanyClick">
            <v-icon left>mdi-plus</v-icon>
            Nytt selskap
          </v-btn>
        </div>
      </template>
      <template #item="{ item, attrs, on }">
        <v-list-item
          v-bind="attrs"
          :class="{ navigation }"
          :to="navigation ? { name: 'Dashboard', params: { companyNameSlug: item.nameSlug } } : undefined"
          v-on="on"
          >{{ item.name }}</v-list-item
        >
      </template>
    </v-select>
    <BaseDialog v-model="companyCreatorDialog" width="700" :persistent="companyCreatorPersistent">
      <CompanyCreator
        ref="CompanyCreator"
        not-registered-button-text="Selskapet er ikke registrert i Brønnøysundregistrene"
        private-person-button-text="Registrer som privatperson"
        :deferred="deferred"
        @hasChangesUpdate="companyCreatorPersistent = $event"
        @update="handleCompanyCreate"
      />
      <template #footer> </template>
    </BaseDialog>
    <CreateCompanyConfirmationModal
      v-if="companyConfirmationDialog"
      v-model="companyConfirmationDialog"
      @submit="createCompanyScenarioHandler"
    />
  </div>
</template>

<script>
import CompanyCreator from '@/modules/CompanyCreator.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import CreateCompanyConfirmationModal from '@/components/CreateCompanyConfirmationModal.vue';
import { mapState } from 'vuex';

export default {
  components: {
    CompanyCreator,
    CreateCompanyConfirmationModal,
    BaseDialog,
  },
  props: {
    label: {
      type: String,
      default: 'Velg selskap',
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    deferred: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    filterByName: '',
    companyCreatorDialog: false,
    companyCreatorPersistent: false,
    companyConfirmationDialog: false,
    deferredCompany: null,
  }),
  computed: {
    ...mapState('subscription', {
      currentSubscription: (state) => state.currentSubscription,
    }),
    user() {
      return this.$store.getters.getUser;
    },
    companies() {
      const companies = this.$store.getters.companiesSortedByName;
      const query = this.filterByName?.trim().toLowerCase();

      const filtered = query ? companies.filter((c) => c.name.toLowerCase().includes(query)) : companies;

      if (this.deferredCompany) {
        return [...filtered, this.deferredCompany];
      }

      return filtered;
    },
    company: {
      get() {
        return this.navigation ? this.companyId : this.value;
      },
      async set(company) {
        if (!this.navigation) {
          this.$emit('input', company);
        }
      },
    },
  },
  // Clear filter when menu is closed
  // https://github.com/vuetifyjs/vuetify/issues/11488
  // we're using an old version of Vuetify, so we need to use this workaround
  // TODO: replace with @blur handler on v-select
  mounted() {
    this.unwatchMenuActive = this.$watch(
      () => this.$refs.select.isMenuActive,
      (val) => val || (this.filterByName = ''),
    );
  },
  destroyed() {
    this.unwatchMenuActive();
  },
  methods: {
    handleNewCompanyClick() {
      if (this.user.accountingFirmId && !this.deferred) {
        return (this.companyConfirmationDialog = true);
      }
      this.$refs?.select?.blur();
      return this.openCreateNewDialog();
    },
    createCompanyScenarioHandler(scenario) {
      this.companyConfirmationDialog = false;
      if (scenario === 'redirect') {
        this.$router.push({ name: 'AccountingFirmInvite' });
      } else {
        this.openCreateNewDialog();
      }
    },
    openCreateNewDialog() {
      this.companyCreatorDialog = true;
      this.$nextTick(() => {
        this.$refs.CompanyCreator.resetToDefault();
      });
    },
    handleCompanyCreate(createdCompany) {
      if (!createdCompany.id) {
        if (this.deferred) {
          this.deferredCompany = { id: '[deferred]', ...createdCompany };
          this.company = this.deferredCompany;
        } else {
          // TODO: throw error
        }
      } else {
        this.company = createdCompany;
        this.$router.push({ name: 'Dashboard', params: { companyNameSlug: createdCompany.nameSlug } });
      }

      this.companyCreatorDialog = false;
      this.companyCreatorPersistent = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.company-selector {
  max-width: 190px;
}
.company-selector-menu {
  .v-list {
    padding-top: 0;
  }
  .v-list-item.navigation {
    font-size: 1rem;

    &:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: var(--v-primary-base);
    }
  }
  .prepend-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
</style>
