import { ProfileService } from '@/services/profileService';

const state = {};
const actions = {
  getProfile(context, { ignoreCache }) {
    return ProfileService.getProfile(ignoreCache);
  },
  updateProfile(context, data) {
    return ProfileService.updateProfile(data);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
