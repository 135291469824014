<template>
  <v-sheet elevation="4" class="sticky-footer">
    <slot />
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  //
});
</script>

<style lang="scss" scoped>
.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 7;
  padding: 16px;
  margin-top: 16px;
  border-radius: 12px;
}
</style>
