import { FileService } from '@/services/fileService';
import { InvoiceDraftService } from '@/services/invoiceDraftService';
import { InvoiceDraftPreviewService } from '@/services/invoiceDraftPreviewService';
import { CompanyContactService } from '@/services/companyContactService';
import { InvoiceService } from '@/services/invoiceService';

const state = {};
const actions = {
  async getInvoiceDrafts(context, { companyId, ignoreCache }) {
    const drafts = await InvoiceDraftService.getInvoiceDrafts(companyId, ignoreCache);
    const contacts = await CompanyContactService.getContacts(companyId, ignoreCache);
    return drafts.map((draft) => {
      if (!draft.contactId) return draft;
      return {
        contact: contacts.find((el) => el.id === draft.contactId),
        ...draft,
      };
    });
  },
  async getInvoiceDraft(context, { companyId, invoiceDraftId, ignoreCache }) {
    let draft = await InvoiceDraftService.getInvoiceDraft(companyId, invoiceDraftId, ignoreCache);
    if (draft.contactId) {
      const contact = await CompanyContactService.getContact(companyId, draft.contactId, ignoreCache);
      draft = { contact, ...draft };
    }
    if (draft.attachmentIds) {
      const attachments = [];
      for (let i = 0; i < draft.attachmentIds.length; i++) {
        attachments.push(await FileService.getFile(companyId, draft.attachmentIds[i]));
      }
      draft = { attachments, ...draft };
    }
    return draft;
  },
  async createInvoiceDraftFromInvoice(context, { companyId, invoiceId }) {
    const fullInvoice = await InvoiceService.getInvoice(companyId, invoiceId, true);
    fullInvoice.lines.map((item) => {
      item.account = item.account.number.toString();
    });
    const data = {
      contactId: fullInvoice.contact.id,
      ...fullInvoice,
    };

    return await InvoiceDraftService.createInvoiceDraft(companyId, data);
  },
  updateInvoiceDraft(context, { companyId, invoiceDraftId, data }) {
    return InvoiceDraftService.updateInvoiceDraft(companyId, invoiceDraftId, data);
  },
  deleteInvoiceDraft(context, { companyId, invoiceDraftId }) {
    return InvoiceDraftService.deleteInvoiceDraft(companyId, invoiceDraftId);
  },
  getInvoiceDraftPreview(context, { companyId, data }) {
    return InvoiceDraftPreviewService.getInvoiceDraftPreview(companyId, data);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
