import api from '@/utils/api';
import type {
  AltinnSettingsRequest,
  AltinnLoginRequest,
  AltinnLoginResponse,
  AltinnSettingsResponse,
} from '@/types/schema';

export const AltinnIntegrationService = {
  getAltinnSettings(companyId: string): Promise<AltinnSettingsResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/altinn/settings`,
      ignoreCache: true,
    });
  },
  updateAltinnSettings(companyId: string, data: AltinnSettingsRequest): Promise<AltinnSettingsResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/altinn/settings`,
      data,
      ignoreCache: true,
    });
  },
  altinnLogin(companyId: string, data: AltinnLoginRequest): Promise<AltinnLoginResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/altinn/login`,
      data,
      ignoreCache: true,
    });
  },
  altinnLoginVerifyOTP(companyId: string, altinnRequestId: string, otp: string): Promise<AltinnLoginResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/altinn/login/${altinnRequestId}/otp`,
      data: {
        otp,
      },
      ignoreCache: true,
    });
  },
};
