import type { PaymentEntityType } from '@/types';
import type {
  CountryResponse,
  InvoicePaymentResponse,
  MetaPaymentCreateBody,
  OutgoingPaymentApproveBatchRequest,
  OutgoingPaymentApproveBatchResponse,
  OutgoingPaymentCancelRequest,
  OutgoingPaymentCancelResponse,
  OutgoingPaymentResponse,
  OutgoingSalaryPaymentApproveRequest,
  OutgoingSalaryPaymentApproveResponse,
  PaymentHistoryResponse,
} from '@/types/schema';
import api from '@/utils/api';

export const PaymentService = {
  getPayments(companyId: string, invoiceId: string): Promise<InvoicePaymentResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/payments`,
    });
  },
  getOutgoingPayments(companyId: string): Promise<OutgoingPaymentResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/outgoing_payments`,
    });
  },
  approveOutgoingPayments(
    companyId: string,
    payload: OutgoingPaymentApproveBatchRequest,
  ): Promise<OutgoingPaymentApproveBatchResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/outgoing_payments/approve`,
      data: payload,
    });
  },
  approveSalary(
    companyId: string,
    paymentId: string,
    payload: OutgoingSalaryPaymentApproveRequest,
  ): Promise<OutgoingSalaryPaymentApproveResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/outgoing_payments/${paymentId}/approve_salary`,
      data: payload,
    });
  },
  cancelOutgoingPayment(
    companyId: string,
    paymentId: string,
    payload: OutgoingPaymentCancelRequest,
  ): Promise<OutgoingPaymentCancelResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/outgoing_payments/${paymentId}/cancel`,
      data: payload,
    });
  },
  getMetaPaymentsHistory(
    companyId: string,
    metaEntityType: PaymentEntityType,
    metaEntityId: string,
  ): Promise<PaymentHistoryResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/meta_payments/history`,
      params: { metaEntityType, metaEntityId },
    });
  },
  updatePaymentMeta(
    companyId: string,
    entityType: PaymentEntityType,
    paymentId: string,
    data: MetaPaymentCreateBody,
  ): Promise<PaymentHistoryResponse[]> {
    return api({
      method: 'put',
      url: `/companies/${companyId}/meta_payments/${entityType}/${paymentId}`,
      data,
    });
  },
  createPaymentMeta(
    companyId: string,
    entityType: PaymentEntityType,
    data: MetaPaymentCreateBody,
  ): Promise<PaymentHistoryResponse[]> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/meta_payments/${entityType}`,
      data,
    });
  },
  removePaymentMeta(
    companyId: string,
    entityType: PaymentHistoryResponse['relatedEntityType'],
    paymentId: string,
  ): Promise<void> {
    return api({
      method: 'delete',
      url: `/companies/${companyId}/meta_payments/${entityType}/${paymentId}`,
    });
  },
  getCountries(): Promise<CountryResponse[]> {
    return api({
      method: 'get',
      url: '/countries',
    });
  },
};
