import api from '@/utils/api';
export const ImportProductsService = {
  import(companyId, file) {
    return api({
      method: 'post',
      url: `companies/${companyId}/importproducts`,
      file,
    });
  },
  downloadTemplate() {
    return api({
      method: 'get',
      url: 'importproducts/template',
      responseType: 'arraybuffer',
      ignoreCache: true,
    });
  },
};
