<template>
  <v-badge inline color="transparent" :value="!disabled" class="help-button">
    <slot></slot>
    <template #badge>
      <v-btn small icon @click="open">
        <v-icon color="grey darken-3" size="17">mdi-information-variant</v-icon>
      </v-btn>
    </template>
    <BaseDialog v-model="dialog" width="700">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot name="content"></slot>
        <p>
          Hvis noe av teksten var dårlig forklart, du har tilbakemeldinger eller smarte ideer om nye løsninger,
          vennligst trykk "Gi tilbakemelding".
        </p>
        <div class="justify-end d-flex mt-4">
          <FeedbackButton text="GI TILBAKEMELDING"></FeedbackButton>
          <v-btn class="ml-2" depressed color="primary" @click="dialog = false">Lukk</v-btn>
        </div>
      </v-card-text>
    </BaseDialog>
  </v-badge>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseDialog from '@/components/BaseDialog.vue';
import FeedbackButton from '@/modules/FeedbackButton.vue';

export default defineComponent({
  components: {
    BaseDialog,
    FeedbackButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      if (!this.disabled) this.dialog = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.help-button::v-deep {
  margin-top: -2px;
  .v-badge__badge {
    display: flex;
    align-items: center;
    padding: 0;
    height: auto;
  }
}
</style>
