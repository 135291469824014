import { CompanyService } from '@/services/companyService';

const getDefaultState = () => ({
  getCompaniesPromise: null,
  isLoadingCompany: false,
});

const state = getDefaultState();

const actions = {
  async getCompanies({ commit }) {
    const companiesResponse = await CompanyService.getCompanies();
    commit('setCacheGetCompanies', companiesResponse);
    commit('set', { companies: companiesResponse || [] }, { root: true });
    return companiesResponse;
  },
  async getCompany({ dispatch, commit, rootState, rootGetters }, { companyId, ignoreCache }) {
    if (!companyId) {
      await dispatch('getCompanies');
      companyId = rootGetters.selectedCompany.id;
    }
    const companyResponse = await CompanyService.getCompany(companyId, ignoreCache);
    dispatch('updateCompanyState', companyResponse, { root: true });
    return companyResponse;
  },
  async createCompany({ commit, rootState }, data) {
    const companyResponse = await CompanyService.createCompany(data);
    commit('set', { companies: [...rootState.companies, companyResponse] }, { root: true });
    return companyResponse;
  },
  async updateCompany({ dispatch, commit, rootState }, { companyId, data }) {
    const companyResponse = await CompanyService.updateCompany(companyId, data);
    dispatch('updateCompanyState', companyResponse, { root: true });
    return companyResponse;
  },
  resetCompanies({ commit }) {
    commit('setCacheGetCompanies', null);
  },
  async fetchCompanyData({ dispatch, commit, rootGetters }, { companyId }) {
    commit('isLoadingCompany', true);

    try {
      await dispatch('subscription/fetchSubscription', { companyId }, { root: true });
    } catch {
      //
    }

    let actions = ['employee/getEmployeesList', 'project/fetchProjects', 'activity/fetchActivities'];

    if (!rootGetters['subscription/getIsTimesheetUser'](companyId)) {
      actions = actions.concat(
        'accounting/fetchVATList',
        'accounting/fetchAccounts',
        'accounting/fetchPaymentSources',
        'accounting/fetchCurrencies',
        'accounting/fetchPaymentCountries',
        'accountGroup/fetchGroups',
        'accountGroup/fetchSuggestions',
        'salary/fetchSalaryTypes',
        'companyContact/getContacts',
        'bankIntegration/getCompanyOnboardingStatus',
        'department/fetchDepartments',
      );
    }

    await Promise.all(actions.map((action) => dispatch(action, { companyId }, { root: true })));

    commit('isLoadingCompany', false);
  },
  setLockDate({ dispatch, rootGetters }, { companyId, date }) {
    const company = rootGetters['getCompanyById'](companyId);
    dispatch('updateCompanyState', { ...company, lockDate: date }, { root: true });
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  isLoadingCompany(state, payload) {
    state.isLoadingCompany = payload;
  },
  setCacheGetCompanies(state, payload) {
    state.getCompaniesPromise = payload;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
