const state = () => ({
  bulkActionFiles: [],
});

const mutations = {
  setBulkActionFiles(state, files) {
    state.bulkActionFiles = files;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
