import type { Middleware } from '../router-types';
import type { CompanyResponse } from '@/types/schema';

export const HasCompanyMiddleware: Middleware = async ({ to, store, redirect }) => {
  if (!store.getters.hasCompany) {
    await store.dispatch('getUserAndCompanies');
  }

  if (store.getters.hasCompany) {
    const toSlug = to.params.companyNameSlug;
    const toCompany = store.getters.getCompanyByNameSlug(toSlug) as CompanyResponse | undefined;

    if (toSlug && !store.getters.getCompanyIdByNameSlug(toSlug)) {
      return redirect({ name: 'SelectCompany' });
    }

    // update currentCompany if it's not the same as the one in the route
    if (toCompany && toCompany.id !== (store.state.currentCompany as CompanyResponse | null)?.id) {
      await store.dispatch('setCurrentCompany', toCompany);
    }

    const currentCompany = store.state.currentCompany as CompanyResponse | null;
    const subscription = currentCompany && (store.state as any).subscription.currentSubscription[currentCompany.id];

    if (!subscription && to.name !== 'Modules') {
      return redirect({
        name: 'Modules',
        params: { companyNameSlug: store.getters.getCompanyNameSlugById(currentCompany?.id) },
      });
    }
  } else {
    return redirect({ name: 'Register2', query: to.query });
  }
};
