import Vue from 'vue';
import VueGtm, { type GtmSupportOptions } from '@gtm-support/vue2-gtm';
import config from '@/utils/config';
import store from '@/store';
import type { CompanySubscriptionPlanResponse } from '@/types/schema';

enum GtmEvent {
  Register = 'register',
  Login = 'login',
  ChangeSubscription = 'change_subscription',
  Reference = 'reference',
}

export enum AuthMethod {
  Google = 'google',
  Facebook = 'facebook',
  Email = 'email',
}

const options: GtmSupportOptions = {
  enabled: import.meta.env.PROD,
  id: config.tagManagerId,
};

if (options.enabled) {
  Vue.use(VueGtm, options);
}

export function trackRegisterEvent(method: AuthMethod) {
  const user = store.getters.getUser;

  Vue.gtm?.trackEvent({
    event: GtmEvent.Register,
    user_id: user.id,
    method,
  });
}

export function trackLoginEvent(method?: AuthMethod) {
  const user = store.getters.getUser;

  Vue.gtm?.trackEvent({
    event: GtmEvent.Login,
    user_id: user.id,
    method,
  });
}

export function trackSubscriptionChangeEvent(
  companyId: string,
  previousSubscription: CompanySubscriptionPlanResponse | null,
  newSubscription: CompanySubscriptionPlanResponse | null,
) {
  const user = store.getters.getUser;

  Vue.gtm?.trackEvent({
    event: GtmEvent.ChangeSubscription,
    user_id: user.id,
    company_id: companyId,
    previous_subscription: previousSubscription?.subscriptionPlan.name ?? null,
    new_subscription: newSubscription?.subscriptionPlan.name ?? null,
    subscription_type: newSubscription?.period ?? null,
  });
}

export function trackReferenceEvent(referenceOption: string) {
  const user = store.getters.getUser;

  Vue.gtm?.trackEvent({
    event: GtmEvent.Reference,
    user_id: user.id,
    reference_option: referenceOption,
  });
}
