import api from '@/utils/api';
import type { DashboardTasksResponse, IncomeStatisticsResponse, InvoiceStatisticsResponse } from '@/types/schema';

export const DashboardService = {
  getTasks(companyId: string): Promise<DashboardTasksResponse> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/dashboard_tasks`,
    });
  },

  startTask(companyId: string, taskCode: string): Promise<void> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/dashboard_tasks/${taskCode}/start`,
    });
  },

  hideTask(companyId: string, taskCode: string, data: { remindLater: boolean }): Promise<void> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/dashboard_tasks/${taskCode}/hide`,
      data,
    });
  },

  incomeStats(companyId: string, params: { year: number }): Promise<IncomeStatisticsResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/dashboards/statistics/income`,
      params,
    });
  },

  invoiceStats(companyId: string): Promise<InvoiceStatisticsResponse> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/dashboards/statistics/invoices`,
    });
  },
};
