import {
  addWeeks,
  differenceInCalendarDays,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  format,
  getISOWeek,
  isSameDay,
  isSameYear,
  parseISO,
  startOfISOWeek,
  startOfISOWeekYear,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { isoFormat } from '../constants';
import type { DateInterval } from '../types';

export function getIntervalYear(year: number): DateInterval {
  const dateFrom = format(startOfYear(new Date(year, 0, 1)), isoFormat);
  const dateTo = format(endOfYear(new Date(year, 0, 1)), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

export function getIntervalPeriod(year: number, periodIndex: number): DateInterval {
  const dateFrom = format(startOfMonth(new Date(year, periodIndex * 2, 1)), isoFormat);
  const dateTo = format(endOfMonth(new Date(year, periodIndex * 2 + 1, 1)), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

export function getIntervalMonth(year: number, monthIndex: number): DateInterval {
  const dateFrom = format(startOfMonth(new Date(year, monthIndex, 1)), isoFormat);
  const dateTo = format(endOfMonth(new Date(year, monthIndex, 1)), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

export function getIntervalWeeksDouble(year: number, weeksIndex: number): DateInterval {
  const firstIsoDayOfYear = startOfISOWeekYear(new Date(year, 5, 5));

  const weekFrom = addWeeks(firstIsoDayOfYear, weeksIndex * 2);
  const weekTo = addWeeks(firstIsoDayOfYear, weeksIndex * 2 + 1);

  const dateFrom = format(startOfISOWeek(weekFrom), isoFormat);
  const dateTo = format(endOfISOWeek(weekTo), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

export function getIntervalSingleWeek(year: number, weekIndex: number): DateInterval {
  const firstIsoDayOfYear = startOfISOWeekYear(new Date(year, 5, 5));

  const week = addWeeks(firstIsoDayOfYear, weekIndex);

  const dateFrom = format(startOfISOWeek(week), isoFormat);
  const dateTo = format(endOfISOWeek(week), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

export function getIntervalToday(): DateInterval {
  const today = format(new Date(), isoFormat);

  return {
    dateFrom: today,
    dateTo: today,
  };
}

export function getIntervalCurrentWeek(): DateInterval {
  const now = new Date();
  const dateFrom = format(startOfISOWeek(now), isoFormat);
  const dateTo = format(endOfISOWeek(now), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

export function getIntervalCurrentPeriod(): DateInterval {
  const now = new Date();
  const year = now.getFullYear();
  const period = getPeriodIndex(now);
  const periodInterval = getIntervalPeriod(year, period);

  return periodInterval;
}

// 'So far this week'
export function getIntervalThisWeek(): DateInterval {
  const today = new Date();
  const dateFrom = format(startOfISOWeek(today), isoFormat);
  const dateTo = format(today, isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

// 'So far this month'
export function getIntervalThisMonth(): DateInterval {
  const today = new Date();
  const dateFrom = format(startOfMonth(today), isoFormat);
  const dateTo = format(today, isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

// 'So far this period'
export function getIntervalThisPeriod(): DateInterval {
  const today = new Date();
  const currentMonth = today.getMonth();
  const startMonth = currentMonth % 2 ? currentMonth - 1 : currentMonth;
  const startDate = new Date(today.getFullYear(), startMonth, 1);

  const dateFrom = format(startOfMonth(startDate), isoFormat);
  const dateTo = format(today, isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

// 'So far this year'
export function getIntervalThisYear(): DateInterval {
  const today = new Date();
  const dateFrom = format(startOfYear(today), isoFormat);
  const dateTo = format(today, isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Returns the period index for the given date
 */
export function getPeriodIndex(date: Date): number {
  const month = date.getMonth();

  return Math.floor(month / 2);
}

/**
 * Returns the week index for the given date
 */
export function getWeekIndex(date: Date): number {
  const week = getISOWeek(date);

  return week - 1;
}

export function isYearInterval(interval: DateInterval) {
  if (!interval.dateFrom || !interval.dateTo) return false;

  const dateFrom = parseISO(interval.dateFrom);
  const dateTo = parseISO(interval.dateTo);

  if (!isSameYear(dateFrom, dateTo)) return false;

  const year = dateFrom.getFullYear();
  const yearInterval = getIntervalYear(year);

  return interval.dateFrom === yearInterval.dateFrom && interval.dateTo === yearInterval.dateTo;
}

export function isPeriodInterval(interval: DateInterval) {
  if (!interval.dateFrom || !interval.dateTo) return false;

  const dateFrom = parseISO(interval.dateFrom);
  const dateTo = parseISO(interval.dateTo);

  if (!isSameYear(dateFrom, dateTo)) return false;

  const year = dateFrom.getFullYear();
  const period = getPeriodIndex(dateFrom);
  const periodInterval = getIntervalPeriod(year, period);

  return interval.dateFrom === periodInterval.dateFrom && interval.dateTo === periodInterval.dateTo;
}

export function isMonthInterval(interval: DateInterval) {
  if (!interval.dateFrom || !interval.dateTo) return false;

  const dateFrom = parseISO(interval.dateFrom);
  const dateTo = parseISO(interval.dateTo);

  if (!isSameYear(dateFrom, dateTo)) return false;

  const year = dateFrom.getFullYear();
  const month = dateFrom.getMonth();
  const monthInterval = getIntervalMonth(year, month);

  return interval.dateFrom === monthInterval.dateFrom && interval.dateTo === monthInterval.dateTo;
}

export function isDoubleWeekInterval(interval: DateInterval) {
  if (!interval.dateFrom || !interval.dateTo) return false;

  const dateFrom = parseISO(interval.dateFrom);
  const dateTo = parseISO(interval.dateTo);

  const firstDayOfWeek1 = startOfISOWeek(dateFrom);
  const lastDayOfWeek2 = endOfISOWeek(dateTo);
  const isTwoWeeksApart = differenceInCalendarDays(dateTo, dateFrom) === 13;

  return isSameDay(firstDayOfWeek1, dateFrom) && isSameDay(lastDayOfWeek2, dateTo) && isTwoWeeksApart;
}

export function isSingleWeekInterval(interval: DateInterval) {
  if (!interval.dateFrom || !interval.dateTo) return false;

  const dateFrom = parseISO(interval.dateFrom);
  const dateTo = parseISO(interval.dateTo);

  const firstDayOfWeek = startOfISOWeek(dateFrom);
  const lastDayOfWeek = endOfISOWeek(dateFrom);

  return isSameDay(firstDayOfWeek, dateFrom) && isSameDay(lastDayOfWeek, dateTo);
}
