export function saveWithTTL(data: any, key: string, ttl: number) {
  const now = new Date().getTime();
  const item = {
    data,
    expiry: now + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithTTL<T = any>(key: string, removeExpired = true): T | null {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date().getTime();
  if (now > item.expiry && removeExpired) {
    localStorage.removeItem(key);
    return null;
  }
  return item.data;
}
