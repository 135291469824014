import type { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import store from '@/store';
import { LAYOUT, SubscriptionModule } from '@/types';
import descriptions from './descriptions';
import { HasNoCompanyMiddleware, HasCompanyMiddleware, DevOnlyMiddleware } from './middleware';
import { loadComponentChunk } from './utils';
import { TermSettlementPeriodMiddleware } from './middleware/term-settlement-period.middleware';
import type { UserResponse } from '@/types/schema';

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'SelectCompany',
    meta: {
      pageTitle: 'Oversikt',
      pageDescription: descriptions.dashboard,
    },
    component: () => loadComponentChunk(() => import('../modules/company-selector/CompanySelector.vue')),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { public: true, layout: LAYOUT.unauthorized },
    component: () => loadComponentChunk(() => import('../modules/Login.vue')),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: { public: true, layout: LAYOUT.unauthorized },
    component: () => loadComponentChunk(() => import('../views/ResetPassword.vue')),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      pageTitle: 'Min profil',
      pageDescription: descriptions.profile,
    },
    component: () => loadComponentChunk(() => import('../modules/profile/views/Profile.vue')),
  },
  {
    path: '/register/1',
    name: 'Register1',
    meta: { public: true, layout: LAYOUT.unauthorized },
    component: () => loadComponentChunk(() => import('../modules/Register.vue')),
  },
  {
    path: '/register/2',
    name: 'Register2',
    meta: { middleware: [HasNoCompanyMiddleware], public: true, layout: LAYOUT.unauthorized },
    component: () => loadComponentChunk(() => import('../modules/Register.vue')),
  },
  {
    path: '/register/3',
    name: 'Register3',
    meta: { middleware: [], layout: LAYOUT.unauthorized },
    component: () => loadComponentChunk(() => import('../modules/Register.vue')),
  },
  {
    path: '/register/4',
    name: 'Register4',
    meta: { middleware: [], layout: LAYOUT.unauthorized },
    component: () => loadComponentChunk(() => import('../modules/Register.vue')),
  },
  {
    path: '/join-company',
    name: 'JoinCompany',
    component: () => loadComponentChunk(() => import('../views/JoinCompany.vue')),
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { public: true },
    component: () => loadComponentChunk(() => import('../views/Logout.vue')),
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    meta: { public: true },
    component: () => loadComponentChunk(() => import('../views/ConfirmEmail.vue')),
  },
  {
    path: '/idporten/login',
    name: 'IDPortenLoginSuccess',
    component: () => loadComponentChunk(() => import('../modules/IDPortenLogin/IDPortenLoginSuccess.vue')),
  },

  /**
   * Company routes
   */

  {
    path: '/:companyNameSlug/dashboard',
    name: 'Dashboard',
    meta: {
      middleware: [HasCompanyMiddleware],
      pageTitle: 'Oversikt',
      modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
      pageDescription: descriptions.dashboard,
    },
    component: () => loadComponentChunk(() => import('../views/Dashboard.vue')),
  },
  {
    path: '/:companyNameSlug/products',
    name: 'Products',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Produkter',
      pageDescription: descriptions.products,
    },
    component: () => loadComponentChunk(() => import('../views/Products.vue')),
  },
  {
    path: '/:companyNameSlug/products/:id',
    name: 'Product',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitleFromStore: true,
    },
    component: () => loadComponentChunk(() => import('../modules/products/views/CreateProduct.vue')),
  },
  {
    path: '/:companyNameSlug/contacts',
    name: 'Contacts',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Kontakter',
      pageDescription: descriptions.contacts,
    },
    component: () => loadComponentChunk(() => import('../views/Contacts.vue')),
  },
  {
    path: '/:companyNameSlug/contacts/:id',
    name: 'Contact',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Ny kontakt',
    },
    component: () => loadComponentChunk(() => import('../modules/contacts/views/CreateContact.vue')),
  },
  {
    path: '/:companyNameSlug/invoices',
    name: 'Invoices',
    redirect: { name: 'InvoicesOverview' },
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Fakturaoversikt',
      pageDescription: descriptions.invoices,
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/Invoices.vue')),
    children: [
      {
        path: 'overview',
        name: 'InvoicesOverview',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.INVOICE],
          pageTitle: 'Fakturaoversikt',
        },
        component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoicesOverview.vue')),
      },
      {
        path: 'repeating',
        name: 'InvoicesRepeating',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.INVOICE],
          pageTitle: 'Repeterende faktura',
        },
        component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoicesRepeating.vue')),
      },
    ],
  },
  {
    path: '/:companyNameSlug/invoices/create',
    name: 'InvoiceCreate',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Ny faktura',
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoiceCreate.vue')),
  },
  {
    path: '/:companyNameSlug/invoices/repeating/create',
    name: 'InvoiceCreateRepeating',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Ny repeterende faktura',
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoiceCreateRepeating.vue')),
  },
  {
    path: '/:companyNameSlug/invoices/repeating/:id',
    name: 'InvoiceRepeating',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Repeterende faktura',
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoiceRepeating.vue')),
  },
  {
    path: '/:companyNameSlug/invoices/draft/:id',
    name: 'InvoiceDraft',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoiceDraft.vue')),
  },
  {
    path: '/:companyNameSlug/invoices/:id/creditnote',
    name: 'CreditNote',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/CreditNoteCreate.vue')),
  },
  {
    path: '/:companyNameSlug/invoices/:id/:tab?',
    name: 'Invoice',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitleFromStore: true,
      pageDescription: descriptions.editInvoice,
    },
    beforeEnter: (to, _from, next) => {
      if (!to.params.tab) {
        next({
          name: 'Invoice',
          params: {
            id: to.params.id,
            companyNameSlug: to.params.companyNameSlug,
            tab: 'oppfølging',
          },
        });
      } else {
        next();
      }
    },
    component: () => loadComponentChunk(() => import('../modules/invoices/views/InvoiceEditor.vue')),
  },
  {
    path: '/:companyNameSlug/settings/company',
    name: 'Settings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
      pageTitle: 'Selskapsinnstillinger',
    },
    component: () => loadComponentChunk(() => import('../modules/settings/views/Settings.vue')),
  },
  {
    path: '/:companyNameSlug/departments',
    name: 'Departments',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
      pageTitle: 'Avdelinger',
      pageDescription: descriptions.departments,
    },
    component: () => loadComponentChunk(() => import('../modules/departments/views/Departments.vue')),
  },
  {
    path: '/:companyNameSlug/projects',
    name: 'Projects',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
      pageTitle: 'Prosjekter',
      pageDescription: descriptions.projects,
    },
    component: () => loadComponentChunk(() => import('../modules/projects/views/Projects.vue')),
  },
  {
    path: '/:companyNameSlug/modules/:subscriptionType?',
    name: 'Modules',
    meta: {
      middleware: [HasCompanyMiddleware],
      pageTitle: 'Moduler',
      pageDescription: descriptions.moduler,
    },
    component: () => loadComponentChunk(() => import('../modules/company-modules/views/Modules.vue')),
  },
  {
    path: '/:companyNameSlug/chart-of-accounts',
    name: 'ChartOfAccounts',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Kontoplan',
      pageDescription: descriptions.chartOfAccounts,
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/AccountsList.vue')),
  },
  {
    path: '/:companyNameSlug/bookkeeping/manual-transactions/:manualTransactionId?',
    name: 'ManualTransaction',
    meta: {
      middleware: [HasCompanyMiddleware],
      pageTitle: 'Bokføring fri',
      modules: [SubscriptionModule.ACCOUNTING],
      newAttachment: true,
      pageDescription: descriptions.manualTransaction,
      entityType: 'MANUAL',
      entityIdRef: 'manualTransactionId',
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/ManualTransaction.vue')),
  },
  {
    path: '/:companyNameSlug/file-inbox',
    name: 'FileInbox',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Filmottak',
    },
    component: () => loadComponentChunk(() => import('../modules/file-inbox/FileInbox.vue')),
  },
  {
    path: '/:companyNameSlug/trial-balance',
    name: 'TrialBalanceIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Saldobalanse',
      pageDescription: descriptions.trialBalance,
      download: { pdf: true, xls: true, csv: true },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/TrialBalance.vue')),
  },
  {
    path: '/:companyNameSlug/balance-sheet',
    name: 'BalanceSheetIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Balanserapport',
      pageDescription: descriptions.balanceSheet,
      download: { pdf: true, xls: true, csv: true },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/BalanceSheet.vue')),
  },
  {
    path: '/:companyNameSlug/payments',
    name: 'PaymentOverviewIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Betalinger',
    },
    component: () => loadComponentChunk(() => import('../modules/payments/views/PaymentOverview.vue')),
  },
  {
    path: '/:companyNameSlug/general-ledger',
    name: 'GeneralLedgerIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Hovedbok',
      pageDescription: descriptions.generalLedger,
      download: { pdf: true, xls: true, csv: true, saf: true },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/GeneralLedger.vue')),
  },
  {
    path: '/:companyNameSlug/income-statement',
    name: 'IncomeStatementIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Resultatrapport',
      pageDescription: descriptions.incomeStatement,
      download: { pdf: true, xls: true, csv: true },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/IncomeStatement.vue')),
  },
  {
    path: '/:companyNameSlug/compilation-report',
    name: 'CompilationReport',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Sammenstillingsrapport',
      pageDescription: descriptions.compilationReport,
      download: { pdf: true },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/CompilationReport.vue')),
  },
  {
    path: '/:companyNameSlug/settings/payments',
    name: 'PaymentSettingsIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
      pageTitle: 'Betalingsinnstillinger',
      pageDescription: descriptions.paymentSettings,
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/PaymentSettings.vue')),
  },
  {
    path: '/:companyNameSlug/settings/timesheets',
    name: 'TimesheetsSettings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Timeføringsinnstillinger',
      pageDescription: descriptions.timesheetsSettings,
    },
    component: () => loadComponentChunk(() => import('../modules/timesheets/Settings.vue')),
  },
  {
    path: '/:companyNameSlug/settings/vat',
    name: 'VatCodesSettings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Mva. innstillinger',
      pageDescription: descriptions.vatCodesSettings,
    },
    component: () => loadComponentChunk(() => import('../modules/vat-codes/Settings.vue')),
  },
  {
    path: '/:companyNameSlug/bookkeeping/purchase-bulk',
    name: 'PurchaseBulkCreate',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bokføring kjøp',
      newAttachment: true,
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/AddNewBulkPurchase.vue')),
  },
  {
    path: '/:companyNameSlug/bookkeeping/purchase',
    name: 'PurchaseCreate',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bokføring kjøp',
      pageDescription: descriptions.purchase,
      newAttachment: true,
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/AddNewPurchase.vue')),
  },
  {
    path: '/:companyNameSlug/bookkeeping/purchase/:purchaseId',
    name: 'PurchaseEdit',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bokføring kjøp',
      newAttachment: true,
      entityType: 'PURCHASE',
      entityIdRef: 'purchaseId',
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/EditPurchase.vue')),
  },
  {
    path: '/:companyNameSlug/bookkeeping/sale',
    name: 'SaleCreate',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bokføring salg',
      pageDescription: descriptions.sale,
      newAttachment: true,
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/AddNewSale.vue')),
  },
  {
    path: '/:companyNameSlug/bookkeeping/sale/:saleId',
    name: 'EditSale',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bokføring salg',
      newAttachment: true,
      entityType: 'INCOME',
      entityIdRef: 'saleId',
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/EditSale.vue')),
  },
  {
    path: '/:companyNameSlug/previewFile',
    name: 'FilePreviewWindowOpen',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      layout: LAYOUT.unauthorized,
    },
    component: () => loadComponentChunk(() => import('../components/FilePreviewNewWindow.vue')),
  },
  {
    path: '/:companyNameSlug/bank-reconciliation',
    name: 'BankReconciliationIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bankavstemningsoversikt',
      pageDescription: descriptions.bankReconciliationOverview,
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/BankReconciliation.vue')),
  },
  {
    path: '/:companyNameSlug/settings/bank-reconciliation',
    name: 'BankReconciliationSettings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Bankavstemminginnstillinger',
    },
    component: () =>
      loadComponentChunk(() => import('../modules/bank-reconciliation-settings/BankReconciliationSettings.vue')),
  },
  {
    path: '/:companyNameSlug/vat-report',
    name: 'MVAReportIndex',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Mva. rapport',
      download: { pdf: { title: 'Last ned kvittering .pdf' } },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/MVAReport.vue')),
  },
  {
    path: '/:companyNameSlug/bank-reconciliation/:bankAccountId',
    name: 'BankReconciliationShow',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitleFromStore: true,
      pageDescriptionFromStore: true,
      newAttachment: true,
      entityType: 'BANK_RECONCILIATION',
      entityIdRef: 'bankReconciliationId',
      entityIdSource: 'query',
      download: {
        pdf: { title: 'Last ned ikke avstemte banktransaksjoner .pdf' },
        xls: { title: 'Last ned ikke avstemte banktransaksjoner .xls (excel)' },
      },
    },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/BankReconciliationShow.vue')),
  },
  {
    path: '/cp',
    name: 'ControlPanel',
    redirect: { name: 'ControlPanelCustomers' },
    component: () => loadComponentChunk(() => import('../modules/control-panel/views/ControlPanel.vue')),
    children: [
      {
        path: 'customers',
        name: 'ControlPanelCustomers',
        meta: {
          pageTitle: 'Systima CP',
          pageDescription: descriptions.controlPanelCustomers,
        },
        component: () => loadComponentChunk(() => import('../modules/control-panel/views/ControlPanelCustomers.vue')),
      },
      {
        path: 'employees',
        name: 'ControlPanelEmployees',
        meta: {
          pageTitle: 'Systima CP',
          pageDescription: descriptions.controlPanelEmployees,
        },
        component: () => loadComponentChunk(() => import('../modules/control-panel/views/ControlPanelEmployees.vue')),
      },
    ],
  },
  {
    path: '/:companyNameSlug/employees',
    name: 'Employees',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Ansatte',
      pageDescription: descriptions.employees,
    },
    component: () => loadComponentChunk(() => import('../modules/employees/Employees.vue')),
  },
  {
    path: '/:companyNameSlug/timesheet',
    name: 'Timesheet',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
      pageTitle: 'Timeføring',
      pageDescription: descriptions.timesheet,
      download: { pdf: true, xls: true, csv: false },
    },
    component: () => loadComponentChunk(() => import('../modules/timesheets/Timesheet.vue')),
  },
  {
    path: '/:companyNameSlug/employees/create',
    name: 'NewEmployee',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Ny ansatt',
      pageDescription: descriptions.createEditEmployee,
    },
    component: () => loadComponentChunk(() => import('../modules/employees/EmployeeCreate.vue')),
  },
  {
    path: '/:companyNameSlug/employees/:id',
    name: 'EditEmployee',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitleFromStore: true,
      pageDescription: descriptions.createEditEmployee,
    },
    component: () => loadComponentChunk(() => import('../modules/employees/EmployeeUpdate.vue')),
  },
  {
    path: '/:companyNameSlug/settings/salary',
    name: 'SalarySettings',
    redirect: { name: 'SalarySettingsGeneral' },
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Lønnsinnstillinger',
    },
    component: () => loadComponentChunk(() => import('../modules/salary/views/settings/SalarySettings.vue')),
    children: [
      {
        path: 'general',
        name: 'SalarySettingsGeneral',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.SALARY],
          pageTitle: 'Innstillinger',
        },
        component: () => loadComponentChunk(() => import('../modules/salary/views/settings/SalarySettingsGeneral.vue')),
      },
      {
        path: 'salary-types',
        name: 'SalarySettingsSalaryTypes',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.SALARY],
          pageTitle: 'Lønnsarter',
        },
        component: () =>
          loadComponentChunk(() => import('../modules/salary/views/settings/SalarySettingsSalaryTypes.vue')),
      },
    ],
  },
  {
    path: '/:companyNameSlug/settings/lock-period',
    name: 'LockPeriodSettings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Låste perioder',
      pageDescription: descriptions.lockPeriod,
    },
    component: () => loadComponentChunk(() => import('../modules/settings/views/LockPeriod.vue')),
  },
  {
    path: '/:companyNameSlug/settings/integrations',
    name: 'IntegrationsSettings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING],
      pageTitle: 'Integrasjoner',
      pageDescription: descriptions.integrations,
    },
    component: () => loadComponentChunk(() => import('../modules/settings/views/Integrations.vue')),
  },
  {
    path: '/:companyNameSlug/settings/invoices',
    name: 'InvoiceSettings',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.INVOICE],
      pageTitle: 'Fakturainnstillinger',
      pageDescription: descriptions.invoiceSettings,
    },
    component: () => loadComponentChunk(() => import('../modules/settings/views/InvoiceSettings.vue')),
  },
  {
    path: '/:companyNameSlug/opening-balance',
    name: 'OpeningBalance',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Åpningsbalanse',
      pageDescription: descriptions.openingBalance,
      newAttachment: true,
    },
    component: () => loadComponentChunk(() => import('../modules/opening-balance/OpeningBalance.vue')),
  },
  {
    path: '/:companyNameSlug/holiday-pay',
    name: 'HolidayPay',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Feriepengeliste',
      pageDescription: descriptions.holidayPay,
    },
    component: () => loadComponentChunk(() => import('../modules/salary/views/HolidayPay.vue')),
  },
  {
    path: '/:companyNameSlug/salary/create',
    name: 'SalaryCreate',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Ny lønn',
    },
    component: () => loadComponentChunk(() => import('../modules/salary/views/SalaryCreate.vue')),
  },
  {
    path: '/:companyNameSlug/salary',
    name: 'Salary',
    redirect: { name: 'SalaryOverview' },
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Lønn',
    },
    component: () => loadComponentChunk(() => import('../modules/salary/views/Salary.vue')),
    children: [
      {
        path: 'overview',
        name: 'SalaryOverview',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.SALARY],
          pageTitle: 'Lønn',
        },
        component: () => loadComponentChunk(() => import('../modules/salary/views/SalaryOverview.vue')),
      },
      {
        path: 'term-settlement/:termSettlementId?',
        name: 'SalaryTermSettlement',
        meta: {
          middleware: [HasCompanyMiddleware, TermSettlementPeriodMiddleware],
          modules: [SubscriptionModule.SALARY],
          pageTitle: 'Lønn',
          download: { pdf: true, xls: false, csv: false },
        },
        component: () => loadComponentChunk(() => import('../modules/salary/views/SalaryTermSettlement.vue')),
      },
    ],
  },
  {
    path: '/:companyNameSlug/salary/:salaryId',
    name: 'SalaryEdit',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.SALARY],
      pageTitle: 'Rediger lønn',
      download: { pdf: { title: 'Last ned Visma Business fil' } },
    },
    component: () => loadComponentChunk(() => import('../modules/salary/views/SalaryEdit.vue')),
  },
  {
    path: '/:companyNameSlug/sub-accounts/:type(supplier|customer|employee)',
    name: 'SubAccounts',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Reskontro',
    },
    redirect: { name: 'SubAccountsTab' },
    component: () => loadComponentChunk(() => import('../modules/accounting/views/SubAccounts.vue')),
    children: [
      {
        path: '',
        name: 'SubAccountsTab',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
          pageTitle: 'Reskontro',
          download: { pdf: true, xls: true, csv: true },
        },
        component: () => loadComponentChunk(() => import('../modules/accounting/views/SubAccountsTab.vue')),
      },
      {
        path: 'aging',
        name: 'SubAccountsAgingTab',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.ACCOUNTING],
          pageTitle: 'Reskontro',
          download: { pdf: true, xls: true, csv: true },
        },
        component: () => loadComponentChunk(() => import('../modules/accounting/views/SubAccountsAgingTab.vue')),
      },
    ],
  },
  {
    path: '/:companyNameSlug/vouchers',
    name: 'Vouchers',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Bilagsjournal',
      pageDescription: descriptions.vouchers,
      download: { pdf: true, xls: true },
    },
    component: () => loadComponentChunk(() => import('../modules/vouchers/Vouchers.vue')),
  },
  {
    path: '/:companyNameSlug/vat-specification',
    name: 'VatSpecification',
    redirect: { name: 'VatSpecificationOverview' },
    component: () => loadComponentChunk(() => import('../modules/vat-specification/views/VatSpecification.vue')),
    children: [
      {
        path: 'overview',
        name: 'VatSpecificationOverview',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.ACCOUNTING],
          pageTitle: 'Mva. spesifikasjon',
          pageDescription: descriptions.vatSpecificationOverview,
          download: { pdf: true, xls: true },
        },
        component: () =>
          loadComponentChunk(() => import('../modules/vat-specification/views/VatSpecificationOverview.vue')),
      },
      {
        path: 'transactions',
        name: 'VatSpecificationTransactions',
        meta: {
          middleware: [HasCompanyMiddleware],
          modules: [SubscriptionModule.ACCOUNTING],
          pageTitle: 'Mva. spesifikasjon transaksjoner',
          pageDescription: descriptions.vatSpecificationTransactions,
          download: { pdf: true, xls: true },
          entityTypeRef: 'entityType',
          entityTypeSource: 'query',
          entityIdRef: 'entityId',
          entityIdSource: 'query',
        },
        component: () =>
          loadComponentChunk(() => import('../modules/vat-specification/views/VatSpecificationTransactions.vue')),
      },
    ],
  },
  {
    path: '/:companyNameSlug/balance',
    name: 'BalanceReport',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Balanse- og resultatrapport',
      pageDescription: descriptions.incomeBalance,
      download: { pdf: true, xls: true },
    },
    component: () => loadComponentChunk(() => import('../modules/income-balance/IncomeBalance.vue')),
  },
  {
    path: '/:companyNameSlug/income',
    name: 'IncomeReport',
    meta: {
      middleware: [HasCompanyMiddleware],
      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
      pageTitle: 'Balanse- og resultatrapport',
      pageDescription: descriptions.incomeBalance,
      download: { pdf: true, xls: true },
    },
    component: () => loadComponentChunk(() => import('../modules/income-balance/IncomeBalance.vue')),
  },

  {
    path: '/accounting-firm',
    name: 'AccountingFirm',
    meta: {
      pageTitle: 'Mitt regnskapsbyrå',
    },
    component: () => loadComponentChunk(() => import('../modules/accounting-firm/views/ControlPanel.vue')),
    redirect: { name: 'AccountingFirmCustomers' },
    children: [
      {
        path: 'customer',
        name: 'AccountingFirmCustomers',
        meta: {
          pageTitle: 'Mitt regnskapsbyrå',
        },
        component: () => loadComponentChunk(() => import('../modules/accounting-firm/views/CustomersTab.vue')),
      },
      {
        path: 'accountant',
        name: 'AccountingFirmEmployees',
        meta: {
          pageTitle: 'Mitt regnskapsbyrå',
          middleware: [
            ({ store, redirect }) => {
              const user = store.getters.getUser as UserResponse | null;
              const firmRole = user?.accountingFirmRole;
              const canAccess = firmRole === 'OWNER' || firmRole === 'ADMIN';
              if (!canAccess) {
                return redirect({ name: 'AccountingFirmCustomers' });
              }
            },
          ],
        },
        component: () => loadComponentChunk(() => import('../modules/accounting-firm/views/EmployeesTab.vue')),
      },
    ],
  },

  {
    path: '/accounting-firm/invite',
    name: 'AccountingFirmInvite',
    meta: {
      pageTitle: 'Legg til selskap(er)',
    },
    component: () => loadComponentChunk(() => import('../modules/accounting-firm/views/Invite.vue')),
  },

  // Dev screen
  {
    path: '/:companyNameSlug/dev-screen',
    name: 'DevScreen',
    meta: { middleware: [DevOnlyMiddleware], pageTitle: 'Developer Screen' },
    component: () => loadComponentChunk(() => import('../modules/dev-screen/index.vue')),
  },

  // Catch-all route
  {
    path: '*',
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (store.getters['auth/isLoggedIn']) {
        if (!store.getters.hasCompany) {
          await store.dispatch('getUserAndCompanies');
        }
        if (store.getters.hasCompany) {
          if (store.getters.companies.length === 1) {
            next({ name: 'Dashboard', params: { companyNameSlug: store.getters.companies[0].nameSlug } });
          } else {
            next({ name: 'SelectCompany', query: to.query });
          }
        } else {
          next({ name: 'Register2', query: to.query });
        }
      } else {
        if (to.query.invitationToken) {
          if (to.query.hasAccount === '1') {
            next({ path: '/login', query: { invitationToken: to.query.invitationToken } });
          } else {
            next({ name: 'Register1', query: to.query });
          }
        } else {
          next({ name: 'Login', query: { redirect: to.fullPath } });
        }
      }
    },
  },
];
