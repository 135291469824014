import api from '@/utils/api';
import type { PensionBookkeepingResponse } from '@/types/schema';

export const PensionService = {
  getBookkeepingPensions(
    companyId: string,
    paymentDate: string,
    employmentIds = '',
  ): Promise<PensionBookkeepingResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pension_bookkeepings`,
      params: {
        paymentDate,
        employmentIds,
      },
    });
  },
};
