import api from '@/utils/api';
import type { ProjectResponse, ProjectCreateBody, ProjectUpdateBody } from '@/types/schema';

export const ProjectService = {
  getProjects(companyId: string): Promise<ProjectResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/projects`,
    });
  },
  createProject(companyId: string, data: ProjectCreateBody): Promise<ProjectResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/projects`,
      data,
    });
  },
  updateProject(companyId: string, projectId: string, data: ProjectUpdateBody): Promise<ProjectResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/projects/${projectId}`,
      data,
    });
  },
};
