import store from '@/store';
import type { PipelineContext, Middleware, RouteRedirect } from './router-types';

export async function runMiddlewarePipeline(context: PipelineContext, middlewares: Middleware[]) {
  let redirected = false;

  const redirect: RouteRedirect = (route) => {
    if (route === undefined) return;

    context.next(route);
    redirected = true;
  };

  const middlewareContext = {
    to: context.to,
    from: context.from,
    redirect,
    store,
  };

  for (const middleware of middlewares) {
    await middleware(middlewareContext);
    if (redirected) break;
  }

  if (!redirected) {
    context.next();
  }
}
