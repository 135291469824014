import api from '@/utils/api';
import { getModuleInfo } from '@/utils/shared';

function extendModule(module) {
  const info = getModuleInfo(module.name);
  return { ...module, price: info?.price, title: info?.title, description: info?.description };
}

function mapResponse(response) {
  if (Array.isArray(response)) {
    const extended = response.map(extendModule);
    if (response.fromCache) extended.fromCache = true;
    return extended;
  } else {
    return extendModule(response);
  }
}

export const CompanyModuleService = {
  getModules(companyId, ignoreCache) {
    return api({
      method: 'get',
      url: `companies/${companyId}/modules`,
      ignoreCache,
    }).then(mapResponse);
  },
  activateModule(companyId, id) {
    return api({
      method: 'post',
      url: `companies/${companyId}/modules/${id}/enable`,
    });
  },
  deactivateModule(companyId, id) {
    return api({
      method: 'post',
      url: `companies/${companyId}/modules/${id}/disable`,
    });
  },
};
