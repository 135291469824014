import { CompanyOnboardStepService } from '@/services/companyOnboardStepService';

const state = {};
const actions = {
  getOnboardSteps(context, { companyId, ignoreCache }) {
    return CompanyOnboardStepService.getOnboardSteps(companyId, ignoreCache);
  },
  passOnboardStep(context, { companyId, stepName }) {
    return CompanyOnboardStepService.passOnboardStep(companyId, stepName);
  },
  saveAddBankAccountStep(context, { companyId }) {
    return CompanyOnboardStepService.passOnboardStep(companyId, 'add_bank_account');
  },
  saveInitInvoiceNumberStep(context, { companyId }) {
    return CompanyOnboardStepService.passOnboardStep(companyId, 'init_invoice_number');
  },
  saveCreateFirstInvoiceStep(context, { companyId }) {
    return CompanyOnboardStepService.passOnboardStep(companyId, 'create_first_invoice');
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
