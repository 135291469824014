import { CreditNoteService } from '@/services/creditNoteService';

const state = {};
const actions = {
  async getCreditNotes(context, { companyId, invoiceId, ignoreCache }) {
    const creditNotes = await CreditNoteService.getCreditNotes(companyId, invoiceId, ignoreCache);
    return creditNotes.map((creditNote) => ({
      ...creditNote,
      generatedAttachments:
        creditNote.creditNoteAttachments?.filter((attachment) => attachment.type === 'GENERATED') || [],
    }));
  },
  createCreditNote(context, { companyId, invoiceId, data }) {
    return CreditNoteService.createCreditNote(companyId, invoiceId, data);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
