import api from '@/utils/api';
import { getFileExtension, isImage, isPdf } from '@/utils/shared';
import type { FileResponse } from '@/types/schema';

type ExtendedFileResponse = FileResponse & { extension: string; isPdf: boolean; isImage: boolean };

function extendAttachment(a: FileResponse): ExtendedFileResponse {
  a = a || {};
  return {
    ...a,
    extension: getFileExtension(a.name),
    isPdf: isPdf(a.mediaType),
    isImage: isImage(a.mediaType),
  };
}

function mapResponse(response: FileResponse[] = []): ExtendedFileResponse[] {
  return response?.map(extendAttachment);
}

export const InvoiceAttachmentService = {
  async getInvoiceAttachments(
    companyId: string,
    invoiceId: string,
    ignoreCache = true,
  ): Promise<ExtendedFileResponse[]> {
    const response: FileResponse[] = await api({
      method: 'get',
      url: `companies/${companyId}/invoices/${invoiceId}/attachments`,
      ignoreCache,
    });

    return mapResponse(response);
  },
  createInvoiceAttachment(companyId: string, invoiceId: string, fileId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/invoices/${invoiceId}/attachments`,
      data: { fileId },
    });
  },
  deleteInvoiceAttachment(companyId: string, invoiceId: string, fileId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/invoices/${invoiceId}/attachments/${fileId}`,
    });
  },
};
