import { CreditNoteAttachmentService } from '@/services/creditNoteAttachmentService';

const state = {};
const actions = {
  getCreditNoteAttachments(context, { companyId, invoiceId, creditNoteId, ignoreCache }) {
    return CreditNoteAttachmentService.getCreditNoteAttachments(companyId, invoiceId, creditNoteId, ignoreCache);
  },
};
const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
