/**
 * Parses year input into a full 4 digit year.
 *
 * 4-digit input is returned as-is.
 *
 * If input is >10 years diff from current year, it is assumed to be a 1900 year.
 *
 * For example:
 * - 22 => 2022
 * - 00 => 1900
 * - 99 => 1999
 * - 2022 => 2022
 */
export function parseYear(year: string): number {
  // input must be exactly 2 or 4 digits
  if (!/^\d{2}|\d{4}$/.test(year)) {
    throw new Error(`Invalid year: ${year}`);
  }

  // 4-digit years are returned as-is
  if (year.length === 4) return parseInt(year);

  const today = new Date();
  const inputYear = parseInt(year);
  const lastTwoDigitsOfCurrentYear = parseInt(today.getFullYear().toString().slice(2, 4));

  if (inputYear - lastTwoDigitsOfCurrentYear > 10) {
    return 1900 + inputYear;
  } else {
    return 2000 + inputYear;
  }
}
