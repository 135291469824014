import api from '@/utils/api';
import type { KIDRegisterAgreementBody } from '@/types/schema';

export const KidService = {
  registerAgreement(companyId: string, data: KIDRegisterAgreementBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/kid`,
      data,
    });
  },
};
