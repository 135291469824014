import api from '@/utils/api';
import type { Entity } from '@/types';
import type { FileResponse, FileSuggestionResponse } from '@/types/schema';

export const AIFileSuggestionAbortKey = 'AIFileSuggestion';

export const FileService = {
  uploadFile(companyId: string, file: File, entity?: Entity): Promise<FileResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/files`,
      file,
      entity,
    });
  },
  /**
   *
   * @param companyId
   * @param files
   * @param params.entity - (optional) the id and type of the entity the files are associated with
   * @param params.fileId - (optional) the id of the file to merge and append the new files to
   * @returns
   */
  uploadFiles(
    companyId: string,
    files: File[],
    params?: {
      entity?: Entity;
      fileId?: string;
    },
  ): Promise<FileResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/files/batch`,
      files,
      entity: params?.entity,
      fileId: params?.fileId,
    });
  },
  deleteFile(
    companyId: string,
    fileId: string,
    params?: {
      entityType?: Entity['entityType'];
      entityId?: Entity['entityId'];
      moveToInbox?: boolean;
    },
  ): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/files/${fileId}`,
      params,
    });
  },
  getFile(companyId: string, fileId: string): Promise<FileResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/files/${fileId}`,
    });
  },
  downloadFile(companyId: string, fileId: string, raw = false): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `companies/${companyId}/files/${fileId}/download`,
      responseType: 'arraybuffer',
      raw,
    });
  },
  getFileSuggestion(companyId: string, fileId: string): Promise<FileSuggestionResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/files/${fileId}/suggestions`,
      abortKey: AIFileSuggestionAbortKey,
    });
  },
  mergeFiles(companyId: string, fileIds: string[], entity?: Entity): Promise<FileResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/files/merge`,
      data: {
        fileIds,
        entityId: entity?.entityId,
        entityType: entity?.entityType,
      },
    });
  },
};
