import * as Sentry from '@sentry/vue';
import { ProjectService } from '@/services/projectService';

const getDefaultState = () => ({
  projects: [],
  loading: false,
});

const state = getDefaultState();

const actions = {
  async fetchProjects({ commit }, { companyId }) {
    try {
      commit('SET_LOADING', true);
      const projects = await ProjectService.getProjects(companyId);
      commit('SET_PROJECTS', projects);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createProject({ commit }, { companyId, payload }) {
    try {
      const project = await ProjectService.createProject(companyId, payload);
      commit('CREATE_PROJECT', project);
      return project;
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  async updateProject({ commit }, { companyId, projectId, payload }) {
    try {
      const project = await ProjectService.updateProject(companyId, projectId, payload);
      commit('UPDATE_PROJECT', project);
      return project;
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_LOADING(state, bool) {
    state.loading = bool;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  CREATE_PROJECT(state, project) {
    state.projects.push(project);
  },
  UPDATE_PROJECT(state, project) {
    const index = state.projects.findIndex((p) => p.id === project.id);
    if (index !== -1) {
      state.projects.splice(index, 1, project);
    }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  allProjects: (state) => {
    return state.projects.map((project) => ({
      ...project,
      title: (project.number ? `${project.number} ` : '') + project.name,
    }));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
